const parking = {
  title: 'Parcs-relais',
  meta_description: 'Retrouvez ici toutes les informations liées aux parcs-relais du réseau TBM '
  + '(disponibilité en temps réel, plans, infos pratiques...).',
  the_parks: 'Parcs-relais',
  description: {
    location: 'Situés près des lignes de tram et de bus,',
    number: 'les 28 parkings ou "parcs-relais" du réseau TBM',
    explanation: 'vous permettent de combiner facilement voiture et transports en commun.',
  },
  search: {
    title: 'Rechercher',
    type_to_search: 'Commencez à écrire pour rechercher',
    placeholder: 'Chercher un parc-relais',
    example: '(nom ou commune)',
    loading: 'Chargement en cours...',
    no_result: 'Pas de résultat',
    find_nearby: 'Trouver un parc-relais près de moi',
  },
  select_line: 'Afficher tous les parcs-relais',
  hide_line: 'Masquer tous les parcs-relais',
  no_found: 'Aucun parc-relais trouvé',
  capacity: 'Capacité',
  address: 'Adresse',
  vlSpace_singular: 'Place',
  vlSpace_plural: 'Places',
  pmrSpace_singular: 'PMR',
  pmrSpace_plural: 'PMR',
  freeVlec_singular: 'Electrique',
  freeVlec_plural: 'Electriques',
  last_updated_at: 'Mise à jour le',
  updated_today_at: 'Mise à jour aujourd\'hui à',
  at: 'à',
  now: 'En ce moment',
  notAvailable: 'Ce parc-relais est actuellement indisponible.',
  no_realtime: 'Pas d\'information temps réel pour ce parc-relais.',
  plan_link: 'Voir le plan des parcs-relais',
  parkSelectedPlanLink: 'Voir le parc-relais sur le plan',
  show_all: 'Afficher tous les parcs-relais',
  tram_connection: 'Les parcs-relais en connexion avec le tram',
  parks_served_by_tram: 'Les parcs-relais desservis par le tram',
  route: 'Rechercher un itinéraire',
  click_indication: 'Cliquez sur un parc-relais pour accéder à ses disponibilités',
  go_to: 'Y aller',
  go_from: 'En partir',
  availability: 'Disponibilités',
  real_time: 'En ce moment',
  plan_title: 'Plan des parc-relais',
  predict: 'À venir',
  predict_soon: 'Information prédictive disponible prochainement',
  how_to: 'P+R: Comment ça marche ?',
  informations: {
    title: 'Infos pratiques',
    schedules: {
      title: 'Les horaires',
      description: 'Les parcs-relais TBM sont ouverts sur la même amplitude horaire que le réseau :',
      normal: 'Les lundis, mardis, mercredis, dimanches et jours fériés : de 5h à 1h du matin',
      party: 'Les jeudis, vendredis et samedis : de 5h à 2h du matin',
    },
    how: {
      title: 'Comment ça marche ?',
      come_in: 'Entrer dans un P+R',
      go_out: 'Sortir du parking',
    },
    prices: {
      title: 'Tarifs des parcs-relais',
      occasional: 'Tickets : tickets  P+R, 1 jour, pass soirée',
      adhere: 'Abonnements TBM : accès P+R inclus',
    },
    electric: {
      title_search: 'Parcs Relais équipés de bornes de recharge pour les véhicules électriques',
      title_detail: 'Bornes de recharge électrique',
      available: 'place disponible',
      available_plural: 'places disponibles',
    },
  },
  favorites: 'Mes parcs-relais favoris',
  fav_item: 'ce parc-relais',
  closed: 'Parc-relais fermé.',
};

export default parking;
