const plan = {
  HOME: {
    name: 'plan-page',
    title: 'plans.title',
    patterns: {
      fr: '/plans',
      en: '/plans',
      es: '/planes',
    },
    page: 'plans/list',
  },
  DYNAMIC: {
    name: 'map-dynamic',
    title: 'plans.dynamic.title',
    patterns: {
      fr: '/plans/plan-dynamique/:section?/:id?/:categoryId?/:detailId?',
      en: '/plans/dynamic-plan/:section?/:id?/:categoryId?/:detailId?',
      es: '/planes/plan-dinamico/:section?/:id?/:categoryId?/:detailId?',
    },
    page: 'plans/dynamic',
  },
};

module.exports = plan;
