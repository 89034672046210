import { BASE_URL, NEWS_URL } from '../config/config';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const REQUEST_STICKY_NEWS = 'REQUEST_STICKY_NEWS';
export const FETCHED_STICKY_NEWS = 'FETCHED_STICKY_NEWS';
export const REQUEST_LATEST_NEWS = 'REQUEST_LATEST_NEWS';
export const FETCHED_LATEST_NEWS = 'FETCHED_LATEST_NEWS';

export const fetchStickyNews = () => async (dispatch, getState) => {
  dispatch({
    type: REQUEST_STICKY_NEWS,
  });

  try {
    const langcode = getState().i18n.locale;
    const stickyQuery = await fetchWithTimeout(`${BASE_URL}/${langcode}${NEWS_URL}?sticky=1`);
    let stickyNews = await stickyQuery.json();

    if (stickyNews.length) {
      [stickyNews] = stickyNews;
    } else {
      stickyNews = null;
    }

    dispatch({
      type: FETCHED_STICKY_NEWS,
      payload: stickyNews,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Une erreur est survenue lors de la récupération de l'actu à la une : ${e}`);
  }
};

export const fetchLatestNews = (taxonomy = null) => async (dispatch, getState) => {
  dispatch({
    type: REQUEST_LATEST_NEWS,
  });

  try {
    const newsStore = getState().news;
    if (newsStore.latest.length > 0 && newsStore.latestTaxonomy === taxonomy) {
      return false;
    }

    const langcode = getState().i18n.locale;
    const url = `${BASE_URL}/${langcode}${NEWS_URL}${taxonomy ? `/${taxonomy}` : ''}`;

    const latestQuery = await fetchWithTimeout(url);
    const news = await latestQuery.json();

    dispatch({
      type: FETCHED_LATEST_NEWS,
      payload: { news, taxonomy },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Une erreur est survenue lors de la récupération des dernières actus : ${e}`);
  }
};
