import en from './en';
import fr from './fr';
import es from './es';

const translations = {
  en,
  fr,
  es,
};

export default translations;
