import { PARKS_PREDICT_URL, PARKS_URL } from '../config/config';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const FETCH_ALL_PARKS = 'FETCH_ALL_PARKS';
export const FETCH_PARKS_WITH_PREDICTIONS = 'FETCH_PARKS_WITH_PREDICTIONS';
export const SET_PREDICTION_TIME = 'SET_PREDICTION_TIME';
export const SET_PARK = 'SET_PARK';

export const fetchAllParks = (force = false) => async (dispatch, getState) => {
  try {
    if (!force && getState().parkings.parks.length > 0) {
      return false;
    }

    const parksQuery = await fetchWithTimeout(`${PARKS_URL}`);
    const parks = await parksQuery.json();

    return dispatch({
      type: FETCH_ALL_PARKS,
      payload: parks,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des parks : ${e}`);
  }
};

export const fetchAllParksWithPredictions = () => async (dispatch, getState) => {
  try {
    let parkings;

    if (getState().parkings.parks.length > 0) {
      parkings = getState().parkings.parks;
    } else {
      const parksQuery = await fetchWithTimeout(`${PARKS_URL}`);
      parkings = await parksQuery.json();
    }

    const predictionsQuery = await fetchWithTimeout(PARKS_PREDICT_URL);
    const response = await predictionsQuery.json();
    let predictions = [];

    if ('data' in response) {
      predictions = response.data;

      parkings = parkings.map((park) => {
        if (!park.parkingId) {
          return park;
        }

        const parkPredictions = predictions.find(
          parkPrediction => parkPrediction.parking_id === park.parkingId.toString(),
        );

        return ({
          ...park,
          predictions: parkPredictions !== undefined ? parkPredictions.predictions : [],
        });
      });
    }

    dispatch({
      type: FETCH_PARKS_WITH_PREDICTIONS,
      payload: { parkings, predictions },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des prédictions parking : ${e}`);
  }
};

export const setPredictionTime = time => dispatch => (
  dispatch({
    type: SET_PREDICTION_TIME,
    payload: time,
  })
);

export const setPark = (id, importedParks) => (dispatch, getState) => {
  let parks;
  if (!importedParks) {
    const { parks: reduxParks } = getState().parkings;
    parks = reduxParks;
  } else {
    parks = importedParks;
  }

  const park = parks.find(s => s.id?.toString() === id.toString() || s.parkingId?.toString() === id.toString());

  dispatch({ type: SET_PARK, payload: park });
};


export const resetPark = () => dispatch => (
  dispatch({ type: SET_PARK, payload: null })
);
