import moment from 'moment';
import { TRAFFIC_ALERT_LINE_URL, TRAFFIC_ALERT_URL } from '../config/config';
import { TRANSPORT_MODES } from '../config/line';
import { ALERT_TYPE, TRAFFIC_DATE_FORMAT } from '../config/traffic';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const PREVIEW_TRAFFIC_INFO = 'PREVIEW_TRAFFIC_INFO';
export const LAST_SYNC_TRAFFIC_INFO = 'LAST_SYNC_TRAFFIC_INFO';
export const HIGH_IMPACT_TRAFFIC_IMPACT = 'HIGH_IMPACT_TRAFFIC_IMPACT';
export const FAVORITE_TRAFFIC = 'FAVORITE_TRAFFIC';
export const TRAM_TRAFFIC = 'TRAM_TRAFFIC';
export const BUS_TRAFFIC = 'BUS_TRAFFIC';
export const VCUB_TRAFFIC = 'VCUB_TRAFFIC';
export const SCODI_TRAFFIC = 'SCODI_TRAFFIC';
export const BATCUB_TRAFFIC = 'BATCUB_TRAFFIC';
export const PARKING_TRAFFIC = 'PARKING_TRAFFIC';
export const TRAIN_TRAFFIC = 'TRAIN_TRAFFIC';
export const AUTOCAR_TRAFFIC = 'AUTOCAR_TRAFFIC';
export const OTHER_TRAFFIC = 'OTHER_TRAFFIC';
export const ALERT_DETAIL = 'ALERT_DETAIL';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_ARCHIVED = 'ALERT_ARCHIVED';
export const ALERT_LINE = 'ALERT_LINE';
export const SET_POPIN_ALERTS = 'SET_POPIN_ALERTS';
export const SET_POPIN_ALERTS_V2 = 'SET_POPIN_ALERTS_V2';

export const fetchPreviewTrafficInfo = () => async (dispatch) => {
  dispatch({
    type: LAST_SYNC_TRAFFIC_INFO,
    payload: moment().format('H[h]mm'),
  });

  try {
    const tramQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/?mode=1`);
    const trams = await tramQuery.json();

    const alertsQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/?type=${ALERT_TYPE.BIG_IMPACT},${ALERT_TYPE.SMALL_IMPACT},${ALERT_TYPE.HIGHLIGHTED}&order=priority`);
    const alerts = await alertsQuery.json();

    const inProgress = [];
    const future = [];

    const today = moment().format(TRAFFIC_DATE_FORMAT);

    alerts.items.forEach((alert) => {
      const disturbanceStartDate = moment(alert.disturbanceStartDate).format(TRAFFIC_DATE_FORMAT);
      const disturbanceEndDate = moment(alert.disturbanceEndDate).format(TRAFFIC_DATE_FORMAT);

      if (disturbanceStartDate > today) {
        future.push(alert);
      }

      if (disturbanceStartDate < today && disturbanceEndDate > today) {
        inProgress.push(alert);
      }
    });

    if (inProgress.length) {
      inProgress.sort((a, b) => Number(b.id) - Number(a.id));
    }

    if (future.length) {
      future.sort((a, b) => Number(b.id) - Number(a.id));
    }

    dispatch({
      type: PREVIEW_TRAFFIC_INFO,
      payload: {
        trams: trams.items,
        inProgress: inProgress.slice(0, 5),
        future: future.slice(0, 5),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes preview : ${e}`);
  }
};

export const fetchFavoritesLines = favoritesIds => async (dispatch) => {
  try {
    const responses = await Promise.all(
      favoritesIds.map(
        id => fetch(`${TRAFFIC_ALERT_URL}/?line=${id}`)
          .then(response => response.json()),
      ),
    );

    const alerts = responses.map(response => response.items);
    const flatAlerts = Array.prototype.concat.apply([], alerts);

    dispatch({
      type: FAVORITE_TRAFFIC,
      payload: flatAlerts,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes des lignes favorites : ${e}`);
  }
};

export const fetchHighTrafficImpactBanner = () => async (dispatch) => {
  try {
    // See #6241
    // Get the impact with the highest priority and which start the first.
    let trafficUrl = TRAFFIC_ALERT_URL;
    trafficUrl = `${trafficUrl}/?type=${ALERT_TYPE.BIG_IMPACT},${ALERT_TYPE.SMALL_IMPACT}`;
    trafficUrl = `${trafficUrl}&maxResults=1&order=priority,start_date`;
    const trafficQuery = await fetchWithTimeout(trafficUrl);
    const traffic = await trafficQuery.json();

    dispatch({
      type: HIGH_IMPACT_TRAFFIC_IMPACT,
      payload: traffic.items,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données de la bannière info traffic : ${e}`);
  }
};

export const fetchTramTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.trams.length > 0) {
      return false;
    }

    const tramQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.TRAM}&maxResults=1000`);
    const trams = await tramQuery.json();

    return dispatch({
      type: TRAM_TRAFFIC,
      payload: trams.items,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes trams : ${e}`);
  }
};

export const fetchBusTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.bus.length > 0) {
      return false;
    }

    const busQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.BUS}&maxResults=1000`);
    const bus = await busQuery.json();

    const impactedLinesIds = bus.items.map(alert => (
      alert.impacts.map(impact => (impact.line ? impact.line.id : null))
    ));

    let flatImpactedLines = Array.prototype.concat.apply([], impactedLinesIds);
    flatImpactedLines = flatImpactedLines.filter(impact => impact !== null);

    const busImpactedLines = getState().lines.lines.filter(obj => (
      flatImpactedLines.includes(obj.id)
    ));

    return dispatch({
      type: BUS_TRAFFIC,
      payload: {
        bus: bus.items,
        busImpactedLines,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes bus : ${e}`);
  }
};

export const fetchVcubTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.vcub.length > 0) {
      return false;
    }

    const vcubQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.VCUB}&maxResults=1000`);
    const vcub = await vcubQuery.json();

    return dispatch({
      type: VCUB_TRAFFIC,
      payload: vcub.items,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes vcub : ${e}`);
  }
};

export const fetchBatcubTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.batcub.length > 0) {
      return false;
    }

    const batcubQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.BATCUB}&maxResults=1000`);
    const batcub = await batcubQuery.json();

    return dispatch({
      type: BATCUB_TRAFFIC,
      payload: batcub.items,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes batcub : ${e}`);
  }
};

export const fetchParkingTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.parking.length > 0) {
      return false;
    }

    const parkingUrl = `${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.PARKING}&maxResults=1000`;
    const parkingQuery = await fetchWithTimeout(parkingUrl);
    const parking = await parkingQuery.json();

    return dispatch({
      type: PARKING_TRAFFIC,
      payload: parking.items,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données parking : ${e}`);
  }
};

export const fetchScodiTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.scodi.length > 0) {
      return false;
    }

    const scodiQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.SCODI}&maxResults=1000`);
    const scodi = await scodiQuery.json();

    const impactedLinesIds = scodi.items.map(alert => (
      alert.impacts.map(impact => (impact.line ? impact.line.id : null))
    ));

    let flatImpactedLines = Array.prototype.concat.apply([], impactedLinesIds);
    flatImpactedLines = flatImpactedLines.filter(impact => impact !== null);

    const scodiImpactedLines = getState().lines.lines.filter(obj => (
      flatImpactedLines.includes(obj.id)
    ));

    return dispatch({
      type: SCODI_TRAFFIC,
      payload: {
        scodi: scodi.items,
        scodiImpactedLines,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données scodi : ${e}`);
  }
};

export const fetchTrainTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.trains.length > 0) {
      return false;
    }

    const trainUrl = `${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.TRAIN_TER}&maxResults=1000`;
    const trainQuery = await fetchWithTimeout(trainUrl);
    const trains = await trainQuery.json();

    return dispatch({
      type: TRAIN_TRAFFIC,
      payload: trains.items,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes trains : ${e}`);
  }
};

export const fetchAutocarTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.autocars.length > 0) {
      return false;
    }

    const autocarsUrl = `${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.AUTOCAR}&maxResults=1000`;
    const autocarsQuery = await fetchWithTimeout(autocarsUrl);
    const autocars = await autocarsQuery.json();

    return dispatch({
      type: AUTOCAR_TRAFFIC,
      payload: autocars.items,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes autocar : ${e}`);
  }
};

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export default function mergeDeep(target, source) {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export const fetchOtherTraffic = () => async (dispatch, getState) => {
  try {
    if (getState().traffic.autocars.length > 0 && getState().traffic.trains.length > 0) {
      return false;
    }

    const othersUrl = `${TRAFFIC_ALERT_URL}/?mode=${TRANSPORT_MODES.PARKING},${TRANSPORT_MODES.AUTOCAR},${TRANSPORT_MODES.TRAIN_TER}&maxResults=1000`;
    const othersQuery = await fetchWithTimeout(othersUrl);
    const others = await othersQuery.json();

    return dispatch({
      type: OTHER_TRAFFIC,
      payload: others.items,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des alertes autocar et train : ${e}`);
  }
};

export const fetchAlert = id => async (dispatch, getState) => {
  try {
    const { alert } = getState().traffic;

    if (alert !== null && alert.id === id) {
      return false;
    }

    const alertQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/${id}`);

    if (alertQuery.status === 410) {
      return dispatch({ type: ALERT_ARCHIVED });
    }

    if (alertQuery.ok) {
      const data = await alertQuery.json();

      return dispatch({
        type: ALERT_DETAIL,
        payload: data,
      });
    }

    return dispatch({ type: ALERT_ERROR });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données de l'alerte : ${e}`);
  }
};

export const fetchPrivateAlert = (id, target, token) => async (dispatch) => {
  try {
    const alertQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_URL}/${id}?target=${target}&token=${token}`);

    if (alertQuery.ok) {
      const data = await alertQuery.json();

      return dispatch({
        type: ALERT_DETAIL,
        payload: data,
      });
    }

    return dispatch({ type: ALERT_ERROR });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données de l'alerte : ${e}`);
  }
};

export const fetchAlertByLine = id => async (dispatch, getState) => {
  try {
    const { line } = getState().traffic;

    if (line !== null && line.id === id) {
      return false;
    }

    const lineQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_LINE_URL}/${id}`);
    const data = await lineQuery.json();

    return dispatch({
      type: ALERT_LINE,
      payload: data,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données des alertes de la ligne : ${e}`);
  }
};
