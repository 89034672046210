const pointOfInterest = {
  title: 'Public places',
  show_all: 'Show all public places',
  hide_all: 'Hide all public places',
  back_to_list: 'Back to list',
  back_to_theme: 'Back to theme',
  back_to_category: 'Back to category',
  empty_category: 'No categories were found for this type of public place',
  empty_poi: 'No public places were found for this category',
  transport_title: 'Transport within 10 minutes:',
  search: {
    placeholder: 'Find a public place',
    no_result: 'No public places found',
  },
  summary: {
    name: 'Name',
    address: 'Address',
    category: 'Category',
  },
  fav_item: 'this place',
};

export default pointOfInterest;
