export const TRANSPORT_MODES = {
  TRAM: 1,
  BUS: 2,
  VCUB: 3,
  BATCUB: 4,
  PARKING: 5,
  TRAIN_TER: 6,
  AUTOCAR: 7,
  SCODI: 8,
  OTHER: 9,
};

export const LINES_TYPES = {
  TRAM: 'Tramway',
  BUS: 'Bus',
  BUS_NIGHT: 'Bus de Nuit',
  BATCUB: 'Ferry',
  TRAIN_TER: 'Train régional / TER',
  AUTOCAR: 'Autocar',
  BIKE: 'bike',
  BSS: 'bss',
  SCODI: 'Bus Scolaire',
};
