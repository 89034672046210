import { FAVORITES_TYPES_GET } from '../config/favorites';
import {
  EXTERNAL_FAVORITES_LINE_URL,
  EXTERNAL_FAVORITES_PARK_URL, EXTERNAL_FAVORITES_PLACE_URL,
  EXTERNAL_FAVORITES_ROUTE_URL, EXTERNAL_FAVORITES_STOP_URL,
  EXTERNAL_FAVORITES_VCUB_URL, LOGIN_URL, STOP_POINT_DETAILS_URL,
} from '../config/config';
import { LOCAL_STORAGE_KEY } from '../config/app';
import { LINES_TYPES } from '../config/line';
import { sortStopsByLine } from './LineHelper';

export const formatOldFavorites = async () => async (getState) => {
  const { isWebview } = getState().app;
  const referer = isWebview ? 'webview' : 'www';

  let localFavorites = localStorage.getItem(LOCAL_STORAGE_KEY);
  localFavorites = JSON.parse(localFavorites);

  if (localFavorites && 'favourites' in localFavorites) {
    const oldFavorites = JSON.parse(localFavorites.favourites);
    const newFavorites = [];

    // VCUBS
    oldFavorites.stations.forEach((stationId) => {
      newFavorites.push({
        favorite_type: 'vcub',
        vcubId: stationId,
      });
    });

    // PARKS
    oldFavorites.parkings.forEach((parkId) => {
      newFavorites.push({
        favorite_type: 'pr',
        parkAndRideId: parkId,
      });
    });

    // STOPS
    Promise.all(
      oldFavorites.stopPoints.map(
        stopPoint => fetch(`${STOP_POINT_DETAILS_URL}/${stopPoint.routeId}/${stopPoint.id}?referer=${referer}`)
          .then(response => response.json()),
      ),
    ).then((stopPointInformations) => {
      stopPointInformations.forEach((stopPoint) => {
        newFavorites.push({
          favorite_type: 'stop',
          lineId: stopPoint.route.line.id,
          routeId: stopPoint.route.id,
          stopPointId: stopPoint.id,
        });
      });
    });

    // PLACES
    oldFavorites.addresses.forEach((address) => {
      newFavorites.push({
        favorite_type: 'place',
        latitude: address.coord.lat,
        longitude: address.coord.lon,
        type: address.title,
        objectName: address.label,
        objectId: address.id,
        name: address.label,
      });
    });

    // ROUTES
    oldFavorites.routes.forEach((route) => {
      newFavorites.push({
        favorite_type: 'route',
        startLatitude: route.start.coord.lat,
        endLatitude: route.stop.coord.lon,
        startLongitude: route.start.coord.lat,
        endLongitude: route.stop.coord.lon,
        startObjectName: route.start.label,
        endObjectName: route.stop.label,
        startObjectId: route.start.id,
        endObjectId: route.stop.id,
        startType: route.start.title,
        endType: route.stop.title,
      });
    });

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newFavorites));
  }
};

export const getLinkByType = (type) => {
  switch (type) {
    case FAVORITES_TYPES_GET.VCUBS:
      return EXTERNAL_FAVORITES_VCUB_URL;
    case FAVORITES_TYPES_GET.PARKS:
      return EXTERNAL_FAVORITES_PARK_URL;
    case FAVORITES_TYPES_GET.ROUTES:
      return EXTERNAL_FAVORITES_ROUTE_URL;
    case FAVORITES_TYPES_GET.STOPS:
      return EXTERNAL_FAVORITES_STOP_URL;
    case FAVORITES_TYPES_GET.PLACES:
      return EXTERNAL_FAVORITES_PLACE_URL;
    case FAVORITES_TYPES_GET.LINES:
      return EXTERNAL_FAVORITES_LINE_URL;
    default:
      return '#';
  }
};

export const redirectToAccountLogin = () => {
  window.location = `${LOGIN_URL}?ref=${window.location.href}`;
};

export const filterObsoleteFavorites = (favorites) => {
  Object.keys(favorites).forEach((favoriteType) => {
    favorites[favoriteType] = favorites[favoriteType].filter(favorite => (
      !('obsolete' in favorite) || favorite.obsolete === false
    ));
  });

  return favorites;
};

/**
 * Stops favorites must be sorted as follows:
 *  - Trams
 *  - BatCub
 *  - Bus
 *  - Special bus
 *  All by A->Z, 1->99.
 */
export const sortStopsFavorites = (favorites) => {
  if (FAVORITES_TYPES_GET.STOPS in favorites
    && favorites[FAVORITES_TYPES_GET.STOPS].length > 0) {
    favorites[FAVORITES_TYPES_GET.STOPS] = sortStopsByLine(favorites[FAVORITES_TYPES_GET.STOPS]);
  }

  return favorites;
};

/**
 * Remove trams lines from favorites alerts lines to avoid see them twice.
 */
export const removeFavoritesTrams = (favorites) => {
  if (FAVORITES_TYPES_GET.LINES in favorites
    && favorites[FAVORITES_TYPES_GET.LINES].length > 0) {
    const favoritesAccountLines = favorites[FAVORITES_TYPES_GET.LINES];

    favorites[FAVORITES_TYPES_GET.LINES] = favoritesAccountLines.filter(favorite => (
      favorite.line && favorite.line.type !== LINES_TYPES.TRAM
    ));
  }

  return favorites;
};
