import { INFO_DETAIL, INFO_ERROR } from '../actions/InfoAction';

const initialState = {
  info: null,
  infoError: null,
  loading: true,
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case INFO_DETAIL:
      return {
        ...state,
        info: action.payload,
        infoError: null,
        loading: false,
      };
    case INFO_ERROR:
      return {
        ...state,
        infoError: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default infoReducer;
