const userFeedback = {
  userModalIntro: '¿La información mostrada es inconsistente? Usted nota un mal funcionamiento ?\n'
  + '¿Tienes una pista de mejora para enviarnos?\n'
  + 'Escribe tu comentario abajo !\n'
  + 'Para todas las demás solicitudes (información de la red, suscripciones, quejas, etc.),',
  userModalIntroCta: 'escríbenos aquí:',
  userModalIntroLink: 'https://www.infotbm.com/fr/tbm-votre-ecoute/contacter-tbm-formulaire.html',
  userModalTitle: 'Escriba su comentario',
  userInformationTitle: 'Sus datos si quiere que nos pongamos en contacto con usted (opcional)',
  userInformationDisclaimer: 'TBM se compromete a garantizar la confidencialidad de los datos que nos comunique.',
  userInformationDisclaimer2: 'Sus datos personales son confidenciales y no se utilizarán nunca con fines comerciales.',
  confirmation: 'Gracias por dejar un comentario. '
  + 'Todas las revisiones se estudian con el objetivo constante de mejorar el sitio web infotbm.com',
  error: 'Se ha producido un error al enviar el formulario. Vuelva a intentarlo más tarde.',
  form: {
    comment: 'Comentario',
    happy: 'Satisfecho',
    notHappy: 'No satisfecho',
    lastName: 'Nombre',
    firstName: 'Nombre',
    email: 'Dirección de e-mail',
    submit: 'Enviar',
    clear: 'Restablecer',
    cancel: 'Cancelar',
  },
  button: {
    info: '¿Qué te ha parecido esta página? \n Ayúdanos',
    cta: 'dejando un comentario',
  },
};

export default userFeedback;
