const favourites = {
  connection: 'Identificarse',
  places: {
    add_message: 'Se ha añadido "%{name}" a sus favoritos y aparece en la lista de direcciones',
    no_favorites: 'Ahorre tiempo agregando sus direcciones favoritas',
  },
  routes: {
    title: 'Mis itinerarios favoritos',
    start: 'Salida:',
    stop: 'Llegada:',
    show: 'Mostrar',
    expand: 'Mostrar mis itinerarios favoritos',
    contract: 'Ocultar mis itinerarios favoritos',
    add_message: 'El itinerario entre <strong>%{start}</strong> y <strong>%{stop}</strong> '
    + 'se ha añadido a sus favoritos. Aparece en su pantalla de inicio',
    no_favorites: 'Ahorre tiempo creando sus rutas favoritas',
  },
  parkings: {
    title: 'Mis aparcamientos disuasorios favoritos',
    expand: 'Mostrar mis aparcamientos disuasorios favoritos',
    contract: 'Ocultar mis aparcamientos disuasorios favoritos',
    add_message: 'Se ha añadido el aparcamiento disuasorio <strong>%{name}</strong> a sus favoritos. '
    + 'Aparece en su pantalla de inicio',
    no_favorites: 'Ahorre tiempo agregando sus parques de relevos favoritos',
  },
  stations: {
    title: 'Mis estaciones favoritas',
    expand: 'Mostrar mis estaciones favoritas',
    contract: 'Ocultar mis estaciones favoritas',
    add_message: 'Se ha añadido la estación <strong>%{name}</strong> a sus favoritos. Aparece en su pantalla de inicio',
    no_favorites: 'Ahorre tiempo agregando sus estaciones LE VÉLO favoritas',
  },
  stopPoints: {
    title: 'Mis paradas favoritas',
    realTime: 'Próximas llegadas',
    expand: 'Mostrar mis paradas favoritas',
    contract: 'Ocultar mis paradas favoritas',
    add_message: 'Se ha añadido la parada <strong>%{name}</strong> a sus favoritos. Aparece en su pantalla de inicio',
    no_favorites: 'Ahorre tiempo agregando sus paradas favoritas',
  },
  lines: {
    no_favorites: '¿Por qué añadir mi línea a la alerta?',
    perturbation_line: 'Estar informado de las interrupciones en mi línea',
    add_favorite: 'Añadir un favorito',
  },
  add: 'Agregar a mis favoritos',
  remove: 'Eliminar de mis favoritos',
  show_all: 'Ver todos mis favoritos',
  hide: 'Ocultar mis favoritos',
  error: {
    add: 'Se produjo un error al agregar marcadores a su cuenta',
    remove: 'Se produjo un error al eliminar marcadores de su cuenta',
    list: 'Se produjo un error al recuperar sus marcadores de su cuenta',
  },
  connect_to_start: 'Inicia sesión para agregar %{type} favorito',
  connect_to_retrieve: 'Inicia sesión para encontrar tus favoritos',
};

export default favourites;
