const route = {
  title: 'Itinerarios',
  meta_description: 'Calcule sus rutas aquí para viajar fácilmente en la red TBM.',
  route: 'Itinerario',
  types: {
    all: 'TODO',
    bus_and_tramway: 'Autobús y tranvía',
    ferry: 'BAT3',
    bike: 'LE VÉLO',
  },
  nearbyLines: {
    closerThanTenMinutes: 'La oferta a menos de 10 minutos:',
  },
  search: {
    title: 'Buscar',
    type_to_search: 'Escriba para buscar',
    departure: 'Salida',
    from: 'Desde : ',
    to: 'Hasta : ',
    arrival: 'Llegada',
    example: '(dirección, parada, municipio...)',
    loading: 'Cargando...',
    no_result: 'No hay resultados',
    error: 'Ha ocurrido un error',
    modify: 'Modificar la búsqueda',
    others: 'Lista de itinerarios:',
    datetime_represents: {
      departure: 'Salida',
      arrival: 'Llegada',
    },
    accessibility: 'Trayectos accessibles',
    submit: 'Se fue !',
  },
  result: {
    routes_found_plural: 'rutas encontradas',
    routes_found_singular: 'ruta encontrada',
    no_routes_found: 'No se encontraron rutas.',
    mode_not_selected: 'Este modo de transporte no forma parte de sus criterios de búsqueda.',
    add_mode: 'Busque rutas con este medio de transporte.',
    no_more_routes_for_today: 'No hay itinerario en las fechas y horarios solicitados.',
    walk: 'Caminar',
    personal_bike: 'Bicicleta',
    vcub: 'LE VÉLO',
    transports: 'Por transporte',
    soft_modes: 'Movilidad suave',
    tad: 'Transporte a la demanda',
  },
  isolated_types: {
    walking: 'peatonal',
    bike: 'bicicleta',
    car: 'coche',
  },
  section_types: {
    walking: 'a pie',
    vcub: 'v3',
    bike: 'bicicleta',
    ferry: 'BAT3',
    car: 'coche',
    pr: 'aparcamiento disuasorio',
    tramway: 'tranvía',
    bus: 'autobús',
    train: 'tren',
    autocar: 'autocar regional',
  },
  empty: 'No se ha encontrado ninguna solución',
  empty_vcub: 'No se puede hacer este itinerario en LE VÉLO',
  empty_batcub: 'No se puede hacer este itinerario en BAT3',
  empty_pr: 'No hay solución de aparcamiento disuasorio para este itinerario',
  empty_isolated: 'No se ha encontrado solución para este itinerario',
  prefs: {
    title: 'preferencias',
    for_phmr: 'Itinerario accesible para las personas con movilidad reducida',
    less_walk: 'Mínimo trayecto a pie',
    less_connections: 'Mínimas correspondencias',
    leave_now: 'salida inmediata',
    leave_at: 'salida de...',
    arrive_at: 'llegada a ...',
  },
  go_from: 'salir de allí',
  go_to: 'ir allí',
  leave_at: 'salir a la',
  arrive_at: 'llegar a la',
  unit_minutes_abbrev: 'minutos',
  walk_with: 'de los cuales',
  unit_total_hours_abbrev: '%{hours}h %{minutes} minutos',
  unit_total_minutes_abbrev: '%{minutes} minutos',
  unit_walk_hours_abbrev: 'de los cuales %{hours}h %{minutes} min a pie',
  unit_walk_minutes_abbrev: 'de los cuales %{minutes} min a pie',
  unit_minute: 'minuto',
  unit_minutes: 'minutos',
  vcub_duration: 'Pedal por',
  vcub_section_title_start: 'Coger una LE VÉLO en la estación',
  vcub_section_title_end: 'Dejar la LE VÉLO en la estación',
  parking_section_title: 'Dejar el coche en ',
  vcub_bike: 'bicicleta disponible',
  vcub_bike_plural: 'bicicletas disponibles',
  vcub_place: 'plaza disponible',
  vcub_place_plural: 'plazas disponibles',
  vcub_up: 'Información actualizada:',
  vcub_unavailable: 'información no disponible',
  around_meters: 'unos %{distance}m',
  departure: 'Salida',
  arrival: 'Llegada',
  see_details: 'Ver el desglose del itinerario',
  filter_by_type: 'Filtrar por tipo:',
  walking_speed: 'Velocidad a pie:',
  walking_speed_types: {
    slow: 'Lenta',
    medium: 'Media',
    fast: 'Rápida',
  },
  destination: 'Destino',
  stops_singular: 'parada',
  stops_plural: 'paradas',
  departure_label: 'Salida',
  arrival_label: 'Llegada',
  summary_dep: 'Salida:',
  summary_arr: 'Llegada:',
  departure_at: 'Salida el:',
  arrival_at: 'Llegada el:',
  the: 'El',
  at: 'a',
  co2: 'Balance de carbono',
  arena: 'Cada vez que hay un espectáculo en la sala Arena, circulan autobuses en intervalos de entre '
  + '3 y 10 minutos entre Porte de Bourgogne, Stalingrad y Arena. Primera salida 2 horas antes del comienzo '
  + 'del espectáculo; última salida 1 hora después del final del espectáculo',
  arena_link: '>> + información',
  distance: 'Distancia',
  mail_body: 'Su itinerario de %{departure} a %{arrival} con infotbm.com',
  mail_subject: 'Su itinerario con TBM',
  mail_title: 'Enviar este itinerario por correo electrónico',
  print_title: 'Imprimir este itinerario',
  full_walking_directions: 'Detalle',
  next_times: 'Deja más tarde',
  previous_times: 'Irse antes',
  different_day: 'No se puede hacer este itinerario en tranvía/autobús en las fechas/horas indicadas; '
  + 'se han modificado.',
  walking_directions: {
    neutral: 'Tomar:',
    left: 'Girar a la izquierda:',
    right: 'Girar a la derecha:',
    on: 'durante',
    meters: 'metros',
  },
  a11y: {
    section: 'Itinerario',
    pager: 'Ir al itinerario',
    search: {
      departure: 'Salida',
      arrival: 'Llegada',
    },
    geolocation: {
      departure: 'Geolocalización salida',
      arrival: 'Geolocalización llegada',
    },
    switch: 'Invertir la salida y la llegada',
    cancel: 'Restablecer el campo',
    zoom: 'Hacer zoom en el plano',
    stops: 'Mostrar las %{number} paradas',
    path: 'Mostrar el itinerario detallado',
    openPreferences: 'Mostrar las preferencias',
    closePreferences: 'Ocultar las preferencias',
    nextTime: 'Horario siguiente',
    previousTime: 'Horario anterior',
    with_accessibility: 'Itinerario accesible para usuarios de sillas de ruedas',
    accessible_solutions: 'Estos itinerarios son las soluciones que mejor corresponden a su búsqueda '
    + 'con paradas de subida y bajada accesibles en sillas de ruedas.',
    modal: {
      title: 'Su itinerario',
      content: 'El itinerario propuesto es una de las soluciones que mejor corresponden a su búsqueda '
      + 'con paradas de subida y bajada accesibles en sillas de ruedas.',
      button: 'Ver el trayecto',
    },
  },
  help: {
    title: 'Ayuda y consejo',
    night: {
      title: 'Moverse de noche',
      description: 'Disfrute de su velada sin el estrés de volver a casa',
    },
    rules: {
      title: 'Las reglas',
      description: 'Viajemos juntos, vivamos mejor juntos',
    },
    parking: {
      title: 'Usar parques de relevos (P + R)',
      description: 'Deje su coche y utilice el transporte público',
    },
    loyalty: {
      title: 'El programa de fidelización',
      description: 'Cuanto más valides, más regalos ganarás',
    },
    prices: {
      title: 'Las tarifas',
      description: 'Cualquiera que sea su necesidad, encuentre el precio que más le convenga',
    },
    handicapped: {
      title: 'Personas con discapacidad o movilidad reducida.',
      description: 'Todas nuestras soluciones para hacer su viaje más fácil',
    },
    m_ticket: {
      title: 'Compra mi m-ticket',
      description: 'La aplicación m-ticket permite comprar el billete de transporte desmaterializado y validarlo '
      + 'con el smartphone (Android e iOS) cuando se viaja en autobús, tranvía y bat3.',
    },
  },
  transport_modes: {
    title: 'Preferencias',
    title_accessible: 'Modos de transporte accessibles',
    tram: 'Tranvía',
    flex_aero: 'Flex Aero',
    bus: 'Autobús',
    vcub: 'LE VÉLO',
    batcub: 'BAT3',
    parking: 'P+R',
    train: 'Tren',
    autocar: 'Autocar regional',
  },
  edit: 'Editar',
  favorites: 'Mis rutas favoritas',
  add_favorites: 'Agregar a mis favoritos',
  remove_favorites: 'Eliminar de mis favoritos',
  errors: {
    transport_modes: 'Se debe seleccionar al menos un modo de transporte.',
    fetch: 'Se produjo un error al recuperar rutas. Intenta recargar la página.',
  },
  back_to_list: 'Volver a la lista de itinerarios.',
  path_price: 'Precio para un trayecto TBM (tranvía, autobús y Bat3)',
  path_price_v3: 'Precio e informaciones sobre la compra de billetes LE VÉLO',
  path_price_train: 'Precio e informaciones sobre la compra de billetes TER',
  path_price_autocar: 'Precio e informaciones sobre la compra de billetes autocar regional',
  path_cgu_autocar: 'Condiciones de viaje',
  path_cgu_train: 'Condiciones de viaje',
  path_price_link_here: 'aquí',
  where_to_buy: '¿Dónde comprar un billete?',
  info_tad: 'Debe reservar su viaje flex’ en la aplicación « TBM flex’ »',
  buy_tad: 'Ir a TBM flex’',
  offer_ter: 'Este trayecto TER está incluido en el abono <strong>"TBM + Trenes"</strong>',
  buy_offer_ter: 'Descubra la oferta de trenes',
  close: 'Cerrar',
  see_map: 'Ver en el mapa',
  see_roadmap: 'Ver en la hoja de ruta',
  less_than_a_minute: 'Menos de un minuto',
  fav_item: 'el itinerario',
  places: {
    indication: 'Haga clic en un lugar para ir directo a él',
  },
  train_in_route: 'Su abono TBM es válido en este viaje',
  co2_of: 'de',
  stay_in: 'Esperar',
};

export default route;
