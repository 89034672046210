const global = {
  from: 'From',
  to: 'to',
  from_the: 'From the',
  close: 'Close',
  more_informations: 'More Information',
  how_more: 'Learn more',
  no_geolocation_available: 'Location service is not enabled in preferences.',
};

export default global;
