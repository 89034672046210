import {
  STOP_AREAS_URL, STOP_POINT_REALTIME_URL, STOP_POINT_DETAILS_URL,
  VEHICLE_SCHEDULES_URL,
} from '../config/config';
import { LINES_TYPES } from '../config/line';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const FETCH_STOP_POINT = 'FETCH_STOP_POINT';
export const CLEAR_STOP_POINT = 'CLEAR_STOP_POINT';
export const FETCH_STOP_AREAS = 'FETCH_STOP_AREAS';
export const FETCH_REALTIME_STOP_POINT = 'FETCH_REALTIME_STOP_POINT';
export const FETCH_REALTIME_DISABLED = 'FETCH_REALTIME_DISABLED';
export const HANDLE_DESTINATIONS = 'HANDLE_DESTINATIONS';
export const FETCH_VEHICLE_SCHEDULES = 'FETCH_VEHICLE_SCHEDULES';
export const CLEAR_ACTIVE_SCHEDULE_DETAIL = 'CLEAR_ACTIVE_SCHEDULE_DETAIL';
export const CLEAR_REALTIME = 'CLEAR_REALTIME';
export const SET_STOP_AREA_LINES = 'SET_STOP_AREA_LINES';

export const fetchStopPoint = (stopPointId, routeId, timestamp = null) => (
  async (dispatch, getState) => {
    const { isWebview } = getState().app;
    const referer = isWebview ? 'webview' : 'www';
    try {
      const storedStopPoint = getState().stops.stopPoint;
      if (storedStopPoint && storedStopPoint.id === stopPointId) {
        if (timestamp === null || storedStopPoint.schedules.day === timestamp) {
          return false;
        }
      }

      let stopPointUrl = `${STOP_POINT_DETAILS_URL}/${routeId}/${stopPointId}${timestamp ? `/${timestamp}` : ''}`;
      stopPointUrl = `${stopPointUrl}?referer=${referer}`;

      const stopPointQuery = await fetchWithTimeout(stopPointUrl);
      const stopPoint = await stopPointQuery.json();

      dispatch({
        type: FETCH_STOP_POINT,
        payload: stopPoint,
      });
      dispatch({
        type: HANDLE_DESTINATIONS,
        payload: [...stopPoint.schedules.destinations],
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Une erreur est survenue lors de la récupération de l'arrêt : ${e}`);
    }
  }
);

export const clearStopPoint = () => (dispatch) => {
  dispatch({ type: CLEAR_STOP_POINT });
};

export const fetchAllStopAreas = () => async (dispatch, getState) => {
  try {
    if (getState().stops.stopAreas.length > 0) {
      return false;
    }

    const stopAreasQuery = await fetchWithTimeout(STOP_AREAS_URL);
    const stopAreas = await stopAreasQuery.json();

    dispatch({
      type: FETCH_STOP_AREAS,
      payload: stopAreas,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Une erreur est survenue lors de la récupération des zones d'arrêts : ${e}`);
  }
};

export const fetchRealtimeStopPoint = (stopPointId, line, routeId) => async (dispatch) => {
  try {
    let lineCode = line.externalCode;

    if (line.type === LINES_TYPES.TRAM || line.type === LINES_TYPES.SCODI) {
      lineCode = line.code;
    }

    const realtimeUrl = `${STOP_POINT_REALTIME_URL}/${line.id}/${stopPointId}/${lineCode}/${routeId}`;

    const realtimeQuery = await fetchWithTimeout(realtimeUrl);
    const realtime = await realtimeQuery.json();

    if ('destinations' in realtime) {
      dispatch({
        type: FETCH_REALTIME_STOP_POINT,
        payload: realtime.destinations,
      });
    } else if (realtime.origin === 'nextpass_disabled') {
      dispatch({
        type: FETCH_REALTIME_DISABLED,
        payload: realtime,
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Une erreur est survenue lors de la récupération des informations en temps réel de l'arrêt : ${e}`);
  }
};

export const clearRealtime = () => (dispatch) => {
  dispatch({ type: CLEAR_REALTIME });
};

export const handleDestinations = destinationName => (dispatch, getState) => {
  const { destinationsForSchedule } = getState().stops;

  const index = destinationsForSchedule.indexOf(destinationName);

  if (index > -1) {
    destinationsForSchedule.splice(index, 1);
  } else {
    destinationsForSchedule.push(destinationName);
  }

  dispatch({
    type: HANDLE_DESTINATIONS,
    payload: [...destinationsForSchedule],
  });
};

const formatScheduleDetail = (schedules, stopPointId) => {
  let stopPointHasPassed = (stopPointId === null);
  const stopPointsToShow = [];

  schedules.forEach((stopTime) => {
    if (stopTime.stop_point.id === stopPointId) {
      stopPointHasPassed = true;
    }

    if (stopPointHasPassed) {
      stopPointsToShow.push({
        name: stopTime.stop_point.label,
        datetime: stopTime.arrival_time,
        id: stopTime.stop_point.id,
      });
    }
  });

  return stopPointsToShow;
};

export const fetchVehicleSchedules = vehicleId => async (dispatch, getState) => {
  try {
    const { stopPoint, activeScheduleDetail } = getState().stops;
    const { isWebview } = getState().app;
    const referer = isWebview ? 'webview' : 'www';

    if (activeScheduleDetail === vehicleId) {
      return dispatch({ type: CLEAR_ACTIVE_SCHEDULE_DETAIL });
    }


    const vehicleSchedulesQuery = await fetchWithTimeout(`${VEHICLE_SCHEDULES_URL}/${vehicleId}?referer=${referer}`);
    const vehicleSchedules = await vehicleSchedulesQuery.json();

    const schedules = formatScheduleDetail(vehicleSchedules, stopPoint ? stopPoint.id : null);

    dispatch({
      type: FETCH_VEHICLE_SCHEDULES,
      payload: { schedules, vehicleId },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Une erreur est survenue lors de la récupération des horaires de passage de la ligne : ${e}`);
  }
};

export const fetchStopAreaLines = area => async (dispatch, getState) => {
  try {
    const { lines } = getState().lines;
    const stopAreaLines = [];

    // Get all lines at stop area.
    area.stopPoints.forEach((sp) => {
      sp.routes.forEach((route) => {
        const line = lines.find(l => l.id === route.line.id);

        // Associate stop-point ID to the line
        // Add this property => line.routes[index].stopPointId.
        if (line) {
          const existingLine = stopAreaLines.find(stopAreaLine => stopAreaLine.id === line.id);
          if (!existingLine) {
            line.routes = [{
              ...route,
              stopPointId: sp.id,
            }];
            stopAreaLines.push(line);
          } else if (!existingLine.routes.find(existingRoute => (existingRoute.id === route.id))) {
            existingLine.routes.push({
              ...route,
              stopPointId: sp.id,
            });
          }
        }
      });
    });

    dispatch({
      type: SET_STOP_AREA_LINES,
      payload: stopAreaLines,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while fetching stop area lines: ', e);
  }
};

export const clearStopAreaLines = () => dispatch => (
  dispatch({
    type: SET_STOP_AREA_LINES,
    payload: [],
  })
);
