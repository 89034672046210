import { LCMAP_DISPLAY } from '../config/lcmap';

export const SET_IS_LOADED = 'SET_IS_LOADED';
export const SET_EVENT = 'SET_EVENT';
export const SET_GEOLOCATED_POSITION = 'SET_GEOLOCATED_POSITION';
export const SET_IS_GEOLOC = 'SET_IS_GEOLOC';
export const CHANGE_VCUB_DISPLAY = 'CHANGE_VCUB_DISPLAY';

export const setIsLoaded = (isLoaded = true) => (dispatch) => {
  dispatch({ type: SET_IS_LOADED, payload: isLoaded });
};

export const setEvent = event => (dispatch) => {
  dispatch({ type: SET_EVENT, payload: event });
  dispatch({ type: SET_EVENT, payload: null });
};

export const setGeolocatedPosition = position => (dispatch) => {
  if (!position || !('latitude' in position) || !('longitude' in position)) {
    return dispatch({
      type: SET_GEOLOCATED_POSITION,
      payload: null,
    });
  }

  return dispatch({
    type: SET_GEOLOCATED_POSITION,
    payload: position,
  });
};

export const switchVcubMode = () => (dispatch, getState) => {
  const currentDisplay = getState().lcmap.vcubDisplay;

  dispatch({
    type: CHANGE_VCUB_DISPLAY,
    payload: currentDisplay === LCMAP_DISPLAY.BIKES
      ? LCMAP_DISPLAY.SLOTS
      : LCMAP_DISPLAY.BIKES,
  });
};

export const setIsGeoloc = isGeoloc => (dispatch) => {
  dispatch({
    type: SET_IS_GEOLOC,
    payload: isGeoloc,
  });
};
