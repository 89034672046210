import { BASE_URL, BG_URL } from '../config/config';
import { DEFAULT_BG } from '../config/home';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const REQUEST_BG = 'REQUEST_BG';
export const FETCHED_BG = 'FETCHED_BG';

export const fetchBackground = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_BG,
    });

    const langcode = getState().i18n.locale;
    const bgQuery = await fetchWithTimeout(`${BASE_URL}/${langcode}${BG_URL}`);
    let bg = await bgQuery.json();

    if (bg.length) {
      [bg] = bg;
    } else {
      bg = {
        desktop: DEFAULT_BG.desktop,
        mobile: DEFAULT_BG.mobile,
      };
    }

    dispatch({
      type: FETCHED_BG,
      payload: bg,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération du l'image de fond : ${e}`);
  }
};
