import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import { fetchWithTimeout } from '../helpers/RequestHelper';
import { INFORMATION_URL } from '../config/config';

export const INFO_DETAIL = 'INFO_DETAIL';
export const INFO_ERROR = 'INFO_ERROR';

export const fetchInfoDetail = id => async (dispatch) => {
  try {
    const informationQuery = await fetchWithTimeout(`${INFORMATION_URL}/${id}`);

    if (!informationQuery.ok) {
      throw new Error(Translate('info.error.fetch'));
    }

    const information = await informationQuery.json();
    dispatch({ type: INFO_DETAIL, payload: information });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération de l'info : ${error}`);
    dispatch({ type: INFO_ERROR, payload: error.message });
  }
};
