const app = {
  home_title: 'Transports Bordeaux Métropole - TBM',
  meta_desc: 'Facilitez vos déplacements au sein de Bordeaux Métropole. '
  + 'Retrouvez vos prochains passages, itinéraires, infos tarifs, disponibilités LE VÉLO et parcs-relais...',
  homepage: 'Accueil',
  errors: {
    button: 'Recharger la page.',
    default: 'Une erreur est survenue.',
    nearby_line: 'Une erreur est survenue.',
    route: 'Une erreur est survenue.',
    line: 'Une erreur est survenue.',
    parking: 'Une erreur est survenue.',
    vcub: 'Une erreur est survenue.',
    stop_area: 'Une erreur est survenue.',
    stop_point: 'Une erreur est survenue.',
  },
  a11y: {
    back: 'Retour',
    title: 'Accessibilité',
  },
  links: {
    transport_ticket: 'Titre de transport',
    mobibus_accessibility: 'Mobibus et accessibilité',
  },
  copyright: 'Keolis Bordeaux Métropole - Tous droits réservés - 2022',
};

export default app;
