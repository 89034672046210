const cities = {
  title: 'Town',
  show_all: 'Show all towns',
  hide_all: 'Hide all towns',
  not_found: 'Town not found',
  transport_title: 'Transport available at',
  search: {
    placeholder: 'Find a town',
    no_found: 'No towns found',
  },
};

export default cities;
