import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import routes from '../../../routes';
import { SET_POPIN_ALERTS } from '../../../actions/TrafficInfoActions';
import { pushRoute } from '../../../helpers/RouterHelper';
import { LOCAL_STORAGE_ACTIVE_LINE_ALERTS } from '../../../config/app';
import { taggingEventAlert } from '../../../tagging/alert/AlertTaggingActions';

const TrafficModal = () => {
  const lang = useSelector(state => state.i18n.locale);
  const alerts = useSelector(state => state.traffic.popinAlerts);
  const line = useSelector(state => state.traffic.line);
  const dispatch = useDispatch();

  if (typeof window !== 'undefined') {
    if (alerts.length > 0) {
      localStorage.setItem(LOCAL_STORAGE_ACTIVE_LINE_ALERTS, JSON.stringify(line));
    }
  }

  const handleCloseModalClick = () => {
    localStorage.removeItem(LOCAL_STORAGE_ACTIVE_LINE_ALERTS);
  };

  const handleLinkClick = (id) => {
    taggingEventAlert(line?.name);
    dispatch({ type: SET_POPIN_ALERTS, payload: [] });
    pushRoute(routes.TRAFFIC.DETAILS.name, lang, { id });
  };

  if (alerts.length > 0) {
    return (
      <div id="perturbationsModal" className="modal show-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header display-flex justify-space-between w-100">
              <div className="display-flex m-t-0-5 align-items-center">
                <svg width={26} height={26} className="m-l-0 m-r-0-5">
                  <use xlinkHref="#icon-alert-blur-pink" />
                </svg>
                <span className="text-fpink fs-h4">{Translate('traffic.line_title')}</span>
                <img src={line.picto} alt={line.code} style={{ height: '30px' }} className="m-l-0-25" />
              </div>
              <span
                className="close-button"
                onClick={() => { dispatch({ type: SET_POPIN_ALERTS, payload: [] }); handleCloseModalClick() }}
                onKeyPress={() => { dispatch({ type: SET_POPIN_ALERTS, payload: [] }); handleCloseModalClick()}}
                role="button"
                tabIndex={0}
              >
                &times;
              </span>
            </div>

            <div className="modal-body w-100">
              <ul>
                {
                  alerts.map((alert) => {
                    const alertToDisplay = 'alert' in alert ? alert.alert : alert;

                    return (
                      <li key={`alert_link_${alertToDisplay.id}`}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          href="#__next"
                          role="button"
                          onClick={() => handleLinkClick(alertToDisplay.id)}
                          onKeyPress={() => handleLinkClick(alertToDisplay.id)}
                          tabIndex={0}
                        >
                          {alertToDisplay.title}
                        </a>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default TrafficModal;
