const userFeedback = {
  userModalIntro: 'The information displayed is inconsistent? You notice a malfunction ?\n'
  + 'You have an improvement track to submit to us ?\n'
  + 'Write your comment below !\n'
  + 'For all other requests (network info, subscriptions, complaints, etc.),',
  userModalIntroCta: 'write us here:',
  userModalIntroLink: 'https://www.infotbm.com/fr/tbm-votre-ecoute/contacter-tbm-formulaire.html',
  userModalTitle: 'Type your comment',
  userInformationTitle: 'Your information if you wish to be contacted (optional)',
  userInformationDisclaimer: 'TBM is committed to ensuring the confidentiality of the information you provide.',
  userInformationDisclaimer2: 'Your personal data is confidential and will never be used for commercial purposes.',
  confirmation: 'Thanks for leaving a comment. '
  + 'All reviews are studied with the constant aim of improving the website infotbm.com',
  error: 'An error occurred while sending your feedback. Please try again later.',
  form: {
    comment: 'Comment',
    happy: 'Happy',
    notHappy: 'Not happy',
    lastName: 'Surname',
    firstName: 'First name',
    email: 'Email',
    submit: 'Send',
    clear: 'Clear',
    cancel: 'Cancel',
  },
  button: {
    info: 'What did you think about this page ? \n Help us to improve our website',
    cta: 'by leaving a comment',
  },
};

export default userFeedback;
