import moment from 'moment';
import InfotbmClient from '../clients/InfotbmClient';
import changeLocale from './I18nActions';
import { BASE_URL, NODES_URL, TERMS_URL } from '../config/config';
import { DRUPAL_NIDS, DRUPAL_TERMS_IDS } from '../config/links';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const HTML_LAYOUT = 'HTML_LAYOUT';
export const SAVE_HISTORY = 'SAVE_HISTORY';
export const SET_MOBILE = 'SET_MOBILE';
export const SET_SM_MOBILE = 'SET_SM_MOBILE';
export const REQUEST_DRUPAL_LINKS = 'REQUEST_DRUPAL_LINKS';
export const FETCHED_DRUPAL_LINKS = 'FETCHED_DRUPAL_LINKS';
export const FETCHED_DRUPAL_TERMS = 'FETCHED_DRUPAL_TERMS';
export const SET_IS_SERVER = 'SET_IS_SERVER';
export const SET_IS_WEBVIEW = 'SET_IS_WEBVIEW';
export const SET_IS_HOMEPAGE = 'SET_IS_HOMEPAGE';
export const SET_WEBVIEW_HEADERS = 'SET_WEBVIEW_HEADERS';
export const RESET_WEBVIEW_HEADERS = 'RESET_WEBVIEW_HEADERS';
export const SET_IS_DEBUG = 'SET_IS_DEBUG';
export const SET_PAGE = 'SET_PAGE';
export const SET_LANG = 'SET_LANG';

export const fetchHtmlLayout = () => async (dispatch, getState) => {
  try {
    const { app } = getState();

    // if (app.header !== null && app.footer !== null && app.svg !== null) {
    //   return;
    // }

    const lang = getState().i18n.locale;
    InfotbmClient.setLang(lang);

    let header = null;
    let footer = null;

    if (!app.isWebview) {
      const topRegion = await InfotbmClient.getRegion('top');
      const headerRegion = await InfotbmClient.getRegion('header');
      header = topRegion + headerRegion;

      const contentBottom = await InfotbmClient.getRegion('content_bottom');
      const footerRegion = await InfotbmClient.getRegion('footer');
      footer = contentBottom + footerRegion;
    }

    const svg = await InfotbmClient.getSpriteSvg();

    dispatch({
      type: HTML_LAYOUT,
      payload: {
        header, footer, svg,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération du layout : ${e}`);
  }
};

export const handleLang = query => async (dispatch) => {
  const lang = query.lang || 'fr';
  dispatch(changeLocale(lang));
};

export const saveHistory = pathName => (dispatch, getState) => {
  const { history } = getState().app;
  history.push(pathName);

  if (history.length > 2) {
    history.shift();
  }

  return dispatch({
    type: SAVE_HISTORY,
    payload: history,
  });
};

export const getHistory = () => (dispatch, getState) => {
  const { history } = getState().app;

  return history;
};

export const setMobile = isMobile => dispatch => (
  dispatch({
    type: SET_MOBILE,
    payload: isMobile,
  })
);

export const setSmMobile = isSmMobile => dispatch => (
  dispatch({
    type: SET_SM_MOBILE,
    payload: isSmMobile,
  })
);

export const setLang = lang => async (dispatch) => {
  dispatch({
    type: SET_LANG,
    payload: lang,
  });
  moment.locale(lang);
};

export const fetchDrupalLinks = () => async (dispatch, getState) => {
  try {
    const storedLinks = getState().app.drupalLinks;

    if (Object.keys(storedLinks).length > 0) {
      return false;
    }

    dispatch({ type: REQUEST_DRUPAL_LINKS });

    const langcode = getState().i18n.locale;
    const drupalLinks = {};

    const drupalNids = Object.values(DRUPAL_NIDS);
    const drupalNodeIds = drupalNids.join();

    const queryNodes = await fetchWithTimeout(`${BASE_URL}/${langcode}${NODES_URL}/${drupalNodeIds}`);
    const drupalNodes = await queryNodes.json();

    Object.keys(DRUPAL_NIDS).map(async (key) => {
      const node = drupalNodes.filter(dp => (parseInt(dp.nid, 10) === DRUPAL_NIDS[key]));
      drupalLinks[key] = node.length ? node[0] : null;
    });

    dispatch({
      type: FETCHED_DRUPAL_LINKS,
      payload: drupalLinks,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des liens vers Infotbm : ${e}`);
  }
};

export const fetchDrupalTermsLinks = () => async (dispatch, getState) => {
  try {
    const langcode = getState().i18n.locale;
    const drupalTermsLinks = {};

    const drupalTids = Object.values(DRUPAL_TERMS_IDS);
    const drupalTermsIds = drupalTids.join();

    const queryTerms = await fetchWithTimeout(`${BASE_URL}/${langcode}${TERMS_URL}/${drupalTermsIds}`);
    const drupalTerms = await queryTerms.json();

    Object.keys(DRUPAL_TERMS_IDS).map(async (key) => {
      const term = drupalTerms.filter(dt => (parseInt(dt.id, 10) === DRUPAL_TERMS_IDS[key]));
      drupalTermsLinks[key] = term.length ? term[0] : null;
    });

    dispatch({
      type: FETCHED_DRUPAL_TERMS,
      payload: drupalTermsLinks,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des liens de terms : ${e}`);
  }
};
