const favourites = {
  connection: 'Log in',
  places: {
    add_message: '"%{name}" has been added to your favourites and appears in your list of addresses ',
    no_favorites: 'Save time by adding your favorite addresses',
  },
  routes: {
    title: 'My favourite routes',
    start: 'Departure:',
    stop: 'Arrival:',
    show: 'Show',
    expand: 'Show my favourite routes',
    contract: 'Hide my favourite routes',
    add_message: 'The route from <strong>%{start}</strong> to <strong>%{stop}</strong>'
    + 'has been added to your favourites. It will appear on your home page',
    no_favorites: 'Save time by creating your favorite routes',
  },
  parkings: {
    title: 'My favourite park-and-rides',
    expand: 'Show my favourite park-and-rides',
    contract: 'Hide my favourite park-and-rides',
    add_message: '<strong>%{name}</strong> park-and-ride has been added to your favourites. '
    + 'It will appear on your home page',
    no_favorites: 'Save time by adding your favorite relay parks',
  },
  stations: {
    title: 'My favourite stations',
    expand: 'Show my favourite stations',
    contract: 'Hide my favourite stations',
    add_message: '<strong>%{name}</strong> station has been added to your favourites. It will appear on your home page',
    no_favorites: 'Save time by adding your favorite LE VÉLO stations',
  },
  stopPoints: {
    title: 'My favourite stops',
    realTime: 'Next services',
    expand: 'Show my favourite stops',
    contract: 'Hide my favourite stops',
    add_message: '<strong>%{name}</strong> stop has been added to your favourites. It will appear on your home page',
    no_favorites: 'Save time by adding your favorite stops',
  },
  lines: {
    no_favorites: 'Why add my line as alert',
    perturbation_line: 'To be informed of disruptions on my line',
    add_favorite: 'Add a favorite',
  },
  add: 'Add to my favorites',
  remove: 'Remove from my favorites',
  show_all: 'See all my favorites',
  hide: 'Hide my favorites',
  error: {
    add: 'An error occurred while adding bookmarks to your account',
    remove: 'An error occurred while removing bookmarks from your account',
    list: 'An error occurred while retrieving your bookmarks from your account',
  },
  connect_to_start: 'Login to add your %{type} favorite',
  connect_to_retrieve: 'Login to find your favorites',
};

export default favourites;
