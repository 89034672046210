import moment from 'moment';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';

export const formatRealtime = (passage) => {
  const stringTime = passage.waittime;
  const time = moment(stringTime, 'hh:mm:ss');

  if (time.format('m') < 1) {
    return Translate('schedules.near');
  }

  if (time.format('H') > 0) {
    const hour = moment(passage.departure);
    const formatHour = hour.format('H[h]mm');
    return `${Translate('schedules.at')} ${formatHour}`;
  }

  return time.format('m [min]');
};
