const stopPoint = {
  line: 'Ligne',
  destination: 'Destination',
  next_pass: 'Prochains passages dans',
  theoretical_time: 'ce symbole indique que le temps de passage à l\'arrêt est théorique',
  search: {
    scheduleDetail: 'Chercher un arrêt',
    empty: 'Aucun arrêt trouvé',
  },
  a11y: {
    date: 'Sélectionner la date',
    scheduleDetail: 'Afficher le détail de l\'horaire :',
    closeDetail: 'Fermer le détail',
  },
  map: {
    hide: 'Masquer la carte',
    show_bus: 'Voir mon bus sur la carte',
    show_batcub: 'Voir mon BAT3 sur la carte',
  },
};

export default stopPoint;
