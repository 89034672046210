const routes = {
  HOME: {
    name: 'route-page',
    title: 'route.title',
    patterns: {
      fr: '/itineraires',
      en: '/routes',
      es: '/rutas',
    },
    page: 'routes/search',
  },
  // Get parameters
  // * from_type: stop_area|address|administrative_region|poi|parks|vcub,
  // * from: departure id,
  // * to_type: stop_area|address|administrative_region|poi|parks|vcub,
  // * to: arrival id,
  // * departure: bool : is departure ?,
  // * date: date with YYYYMMDD[T]HHmmss format,
  // * transports: @see(NAVITIA_TRANSPORT_TYPES).join(','),
  // * wheelchair: bool : withAccessibility ?,
  RESULTS: {
    name: 'route-results',
    title: 'route.title',
    patterns: {
      fr: '/itineraires/resultats',
      en: '/routes/results',
      es: '/rutas/resultados',
    },
    page: 'routes/results',
  },
  ROADMAP: {
    name: 'route-roadmap',
    title: 'route.title',
    patterns: {
      fr: '/itineraires/resultat/:routeId',
      en: '/routes/result/:routeId',
      es: '/rutas/resultado/:routeId',
    },
    page: 'routes/roadmap',
  },
};

module.exports = routes;
