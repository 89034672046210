const plans = {
  title: 'Plans',
  title_index: 'Tous les plans',
  title_vcub: 'Plans des LE VÉLO',
  title_park: 'Plans des parcs-relais',
  meta_description_index: 'Retrouvez ici tous les plans du réseau TBM '
  + '(plan de lignes, du réseau de jour et de nuit, des stations LE VÉLO, des parcs-relais...).',
  meta_description_vcub: 'Retrouvez ici le plan des LE VÉLO du réseau TBM.',
  meta_description_park: 'Retrouvez ici le plan des parcs-relais du réseau TBM.',
  description: 'Pour vous accompagner dans vos déplacements, découvrez tous nos plans ! '
  + 'Plans du réseau, plans de lignes, plans des stations LE VÉLO, plans des parcs-relais, plan dynamique, etc...',
  lines: 'Plans de lignes',
  parks: 'Plan des parcs-relais',
  network: 'Plans du réseau',
  town: 'Plans par commune',
  touristic: 'Plan touristique',
  night: 'Plan du réseau de soirée',
  vcub: 'Plan des stations LE VÉLO',
  help_title: 'Bon à savoir',
  help: {
    title: 'Bon à savoir',
    save_print: 'Vous avez la possibilité d’enregistrer et imprimer vos plans',
    subscriptions: 'Pour vous déplacer, pensez aux ',
    tbm_subscriptions: 'tickets ou aux abonnements.',
    or: 'ou au ',
    night_pass: 'pass soirée !',
    infos: 'Découvrez toutes les infos pratiques pour ',
    move_night: 'déplacer la nuit avec TBM !',
    prepare_moves: 'préparer vos déplacements.',
  },
  dynamic: {
    title: 'Plan dynamique',
    meta_description: 'Visualisez sur le plan dynamique toute l\'offre de transport TBM '
    + '(lignes, communes, entreprises et établissements partenaires...).',
    around: 'Autour de moi',
    cities: 'Communes',
    public_places: 'Lieux publics',
    enterprises: 'Les entreprises et établissements partenaires de TBM',
    lines: 'Les lignes',
  },
  highlight: {
    title: 'Bon à savoir',
    description: 'Itinéraires théoriques donnés à titre indicatif.\nPensez à consulter les prochains passages de votre bus/tram en temps réel.',
  },
};

export default plans;
