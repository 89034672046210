const favourites = {
  connection: 'Me connecter',
  places: {
    add_message: '"%{name}" a été ajoutée à vos adresses favorites',
    no_favorites: 'Gagnez du temps en ajoutant vos adresses favorites',
  },
  routes: {
    title: 'Mes itinéraires favoris',
    start: 'Départ :',
    stop: 'Arrivée :',
    show: 'Afficher',
    expand: 'Afficher mes itinéraires favoris',
    contract: 'Cacher mes itinéraires favoris',
    add_message: 'L\'itinéraire suivant a bien été ajouté à vos favoris '
    + ':<br /><br /><strong>Départ :</strong> %{start}<br /><strong>Arrivée : </strong>%{stop}<br /><br />'
    + 'Il apparaît maintenant sur l\'écran d\'accueil de votre rubrique itinéraire.',
    no_favorites: 'Gagnez du temps en créant vos itinéraires favoris',
  },
  parkings: {
    title: 'Mes parcs-relais favoris',
    expand: 'Afficher mes parcs-relais favoris',
    contract: 'Cacher mes parcs-relais favoris',
    add_message: 'Le parc-relais suivant a été ajouté à vos favoris :'
    + '<br /><br /><strong>%{name}</strong><br /><br />'
    + 'Il apparaît maintenant sur l\'écran d\'accueil de votre rubrique parc-relais.',
    no_favorites: 'Gagnez du temps en ajoutant vos parcs-relais favoris',
  },
  stations: {
    title: 'Mes stations favorites',
    expand: 'Afficher mes stations favorites',
    contract: 'Cacher mes stations favorites',
    add_message: 'La station LE VÉLO suivante a été ajoutée à vos favoris :'
    + '<br /><br /><strong>%{name}</strong><br /><br />'
    + 'Elle apparaît maintenant sur l\'écran d\'accueil de votre rubrique LE VÉLO.',
    no_favorites: 'Gagnez du temps en ajoutant vos stations LE VÉLO favorites',
  },
  stopPoints: {
    title: 'Mes arrêts favoris',
    realTime: 'Prochains passages',
    expand: 'Afficher mes arrêts favoris',
    contract: 'Cacher mes arrêts favoris',
    add_message: 'L\'arrêt suivant a été ajouté à vos favoris :'
    + '<br /><br /><strong>%{name}</strong><br />-> %{destination}<br /><br />'
    + 'Il apparaît maintenant sur l\'écran d\'accueil de votre rubrique horaire.',
    no_favorites: 'Gagnez du temps en ajoutant vos arrêts favoris',
  },
  lines: {
    no_favorites: 'Pourquoi ajouter ma ligne en alerte ?',
    perturbation_line: 'Pour être informé des perturbations sur ma ligne',
    add_favorite: 'Ajouter une alerte',
  },
  add: 'Ajouter à mes favoris',
  added: 'Favori ajouté',
  remove: 'Retirer de mes favoris',
  removed: 'Favori supprimé',
  show_all: 'Voir tous mes favoris',
  hide: 'Cacher mes favoris',
  error: {
    add: 'Une erreur est survenue lors de l\'ajout du favoris à votre compte',
    remove: 'Une erreur est survenue lors de la suppression du favoris sur votre compte',
    list: 'Une erreur est survenue lors de la récupération de vos favoris depuis votre compte',
  },
  connect_to_start: 'Connectez vous à votre compte myTBM pour ajouter %{type} en favori',
  connect_to_retrieve: 'Connectez vous à votre compte myTBM pour retrouver vos favoris.',
};

export default favourites;
