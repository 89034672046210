import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import { FAVORITES_TYPES_GET, FAVORITES_TYPES_POST } from '../config/favorites';
import { FAVORITES_URL } from '../config/config';
import { LOCAL_STORAGE_KEY } from '../config/app';
import {
  filterObsoleteFavorites,
  formatOldFavorites,
  redirectToAccountLogin,
  sortStopsFavorites,
} from '../helpers/FavoritesHelper';
import { FAVORITES_ALERTS_SORT_ORDER } from '../config/traffic';

export const SET_MESSSAGE = 'SET_MESSAGE';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_CONNECTED = 'SET_CONNECTED';
export const SET_ALL_FAVORITES = 'SET_ALL_FAVORITES';
export const SET_FAVORITE_BY_TYPE = 'SET_FAVORITE_BY_TYPE';
export const SET_TMP_FAVORITE = 'SET_TMP_FAVORITE';

const syncLocalWithAccount = async () => {
  let localFavorites = localStorage.getItem(LOCAL_STORAGE_KEY);
  localFavorites = JSON.parse(localFavorites);

  if (localFavorites && Array.isArray(localFavorites)) {
    try {
      await Promise.all(
        localFavorites.map(
          favorite => fetch(FAVORITES_URL, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(favorite),
          }),
        ),
      );
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Une erreur est survenue durant la synchronisation des favoris vers l'espace client : ${e}`);
    }
  }
  return (localFavorites);
};

const getAccountFavorites = async (isWebview = false, headers = {}) => {
  let body;

  if (isWebview && headers) {
    body = {
      withCredentials: true,
      headers: {
        'X-KEOLIS-AUTH-TOKEN': headers['X-KEOLIS-AUTH-TOKEN'],
        'X-KEOLIS-AUTH-USER': headers['X-KEOLIS-AUTH-USER'],
      },
    };
  } else {
    body = {
      credentials: 'include',
      redirect: 'manual',
    };
  }

  const response = await fetch(`${FAVORITES_URL}`, body);

  if (response.ok) {
    const data = await response.json();
    return data;
  }

  return [];
};

/**
 * Favorites API return empty array, even if user isn't logged.
 * So we look for the 'ec_logged' cookie.
 */
const isLogged = () => {
  const cookies = `; ${document.cookie}`;
  const parts = cookies.split('; ec_logged=');
  if (parts.length === 2) {
    const value = parts.pop().split(';').shift();

    if (value.trim().length > 0) {
      return true;
    }
  }

  return false;
};

export const initFavorites = (isWebview, headers) => async (dispatch) => {
  await formatOldFavorites();

  if (!isLogged()) {
    if (isWebview) {
      try {
        let data = await getAccountFavorites(isWebview, headers);

        data = filterObsoleteFavorites(data);
        data = sortStopsFavorites(data);
        // data = removeFavoritesTrams(data);

        data.alert_lines = data.alert_lines.sort((a, b) => FAVORITES_ALERTS_SORT_ORDER.indexOf(a.line.type) - FAVORITES_ALERTS_SORT_ORDER.indexOf(b.line.type));

        dispatch({ type: SET_ALL_FAVORITES, payload: data });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Erreur lors de la récupération des favoris : ${e}`);
      }
    }
    dispatch({ type: SET_LOADING, payload: false });
    dispatch({ type: SET_CONNECTED, payload: false });
    return;
  }

  dispatch({ type: SET_CONNECTED, payload: true });

  try {
    let data = await getAccountFavorites();
    if (!Array.isArray(data)) {
      // await tmpRemoveFavorites(data);

      const needToRefreshData = await syncLocalWithAccount();

      if (needToRefreshData) {
        data = await getAccountFavorites();
      }

      data = filterObsoleteFavorites(data);
      data = sortStopsFavorites(data);
      // data = removeFavoritesTrams(data);

      if (data.alert_lines) {
        data.alert_lines = data.alert_lines.sort((a, b) => FAVORITES_ALERTS_SORT_ORDER.indexOf(a.line.type) - FAVORITES_ALERTS_SORT_ORDER.indexOf(b.line.type));
      } else {
        data.alerts_lines = [];
      }

      dispatch({ type: SET_ALL_FAVORITES, payload: data });
    } else {
      dispatch({ type: SET_LOADING, payload: false });
    }
  } catch (e) {
    dispatch({ type: SET_LOADING, payload: false });
    // eslint-disable-next-line no-console
    console.error(`Un problème est survenue lors de la récupération des favoris sur l'espace client: ${e}`);
  }
};

export const resetFavoriteLoading = () => dispatch => (
  dispatch({ type: SET_LOADING, payload: true })
);

const postAccountFavorites = async (data, isWebview = false, headers = {}) => {
  try {
    let body;
    if (headers && isWebview) {
      body = {
        method: 'POST',
        withCredentials: true,
        headers: {
          'X-KEOLIS-AUTH-TOKEN': headers['X-KEOLIS-AUTH-TOKEN'],
          'X-KEOLIS-AUTH-USER': headers['X-KEOLIS-AUTH-USER'],
        },
        body: JSON.stringify(data),
      };
    } else {
      body = {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
      };
    }

    const response = await fetch(FAVORITES_URL, body);
    const responseData = await response.json();

    return response.ok && !('errors' in responseData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Un problème est survenu lors de l'ajout du favori sur l'espace client: ${e}`);
    return false;
  }
};

/**
 * Delete favorite on user account.
 *
 * @param favorite
 *   Favorite object.
 * @param isWebview
 *   On Webview domain.
 * @param headers
 *   Headers from webview.
 */
const deleteAccountFavorite = async (favorite, isWebview = false, headers = {}) => {
  try {
    if (!favorite || !('id' in favorite)) {
      return false;
    }

    let body;
    if (headers && isWebview) {
      body = {
        method: 'DELETE',
        withCredentials: true,
        headers: {
          'X-KEOLIS-AUTH-TOKEN': headers['X-KEOLIS-AUTH-TOKEN'],
          'X-KEOLIS-AUTH-USER': headers['X-KEOLIS-AUTH-USER'],
        },
      };
    } else {
      body = {
        method: 'DELETE',
        credentials: 'include',
      };
    }

    await fetch(`${FAVORITES_URL}/${favorite.id}`, body);

    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Un problème est survenu lors de la suppression du favori sur l'espace client: ${e}`);
    return false;
  }
};

const setFlashMessage = (dispatch, message) => {
  dispatch({ type: SET_MESSSAGE, payload: message });
  setTimeout(() => { dispatch({ type: SET_MESSSAGE, payload: '' }); }, 5000);
};

const addToAccount = async (dispatch, data, isWebview = false, headers = {}) => {
  const success = await postAccountFavorites(data, isWebview, headers);
  if (!success) {
    if (!isWebview) {
      dispatch({ type: SET_ERROR, payload: Translate('favourites.error.add') });
      setFlashMessage(dispatch, Translate('favourites.error.add'));
    }
    return false;
  }

  const favorites = await getAccountFavorites(isWebview, headers);
  if (favorites) {
    dispatch({ type: SET_ALL_FAVORITES, payload: favorites });
    return true;
  }
};

const removeToAccount = async (dispatch, favorite, isWebview = false, headers = {}) => {
  const success = await deleteAccountFavorite(favorite, isWebview, headers);
  if (!success) {
    dispatch({ type: SET_ERROR, payload: Translate('favourites.error.remove') });
    setFlashMessage(dispatch, Translate('favourites.error.remove'));
  }

  return success;
};

export const redirectToAddFavorite = () => (dispatch, getState) => {
  const { tmpFavorite } = getState().favorites;

  if (tmpFavorite) {
    let localFavorites = localStorage.getItem(LOCAL_STORAGE_KEY);
    localFavorites = JSON.parse(localFavorites);

    if (localFavorites && Array.isArray(localFavorites)) {
      localFavorites.push(tmpFavorite);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localFavorites));
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify([tmpFavorite]));
    }
  }

  redirectToAccountLogin();
};

// VCUBS
export const addVcub = (vcubId, name, isWebview, headers = {}) => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING, payload: true });
  const { isConnected } = getState().favorites;
  const newFavorite = {
    favorite_type: FAVORITES_TYPES_POST.VCUB,
    vcubId: vcubId.toString(),
  };

  try {
    window.ReactNativeWebView.postMessage(JSON.stringify({
      favorite_type: newFavorite.favorite_type,
      favorite_id: newFavorite.vcubId,
    }));

    dispatch({ type: SET_TMP_FAVORITE, payload: newFavorite });

    if (headers && isWebview) {
      await addToAccount(dispatch, newFavorite, isWebview, headers);
    }

    if (document.getElementsByClassName('tbm-starempty')[0].style.display === 'block') {
      document.getElementsByClassName('tbm-starempty')[0].style.display = 'none';
      document.getElementsByClassName('tbm-star')[0].style.display = 'block';
    } else {
      document.getElementsByClassName('tbm-starempty')[0].style.display = 'block';
      document.getElementsByClassName('tbm-star')[0].style.display = 'none';
    }

    dispatch({ type: SET_LOADING, payload: false });
  } catch (e) {
    if (isWebview) {
      dispatch({ type: SET_LOADING, payload: false });
      return;
    }
    // We are in the web context, check if user is connected
    if (isConnected) {
      const success = await addToAccount(dispatch, newFavorite);
      if (success) {
        setFlashMessage(dispatch, Translate('favourites.stations.add_message', { name }));
      }
      return;
    }

    dispatch({ type: SET_MESSSAGE, payload: Translate('favourites.connect_to_start', { type: Translate('vcub.fav_item') }) });
    dispatch({ type: SET_TMP_FAVORITE, payload: newFavorite });
  }
};

export const removeVcub = (vcubId, isWebview, headers = {}) => async (dispatch, getState) => {
  const { vcubs, isConnected } = getState().favorites;
  const favorite = vcubs.find(vcub => vcub.vcubId === vcubId.toString());

  try {
    window.ReactNativeWebView.postMessage(JSON.stringify({
      favorite_type: favorite.favorite_type,
      favorite_id: favorite.vcubId,
    }));

    if (headers && isWebview) {
      await removeToAccount(dispatch, favorite, isWebview, headers);
    }

    dispatch({
      type: SET_FAVORITE_BY_TYPE,
      payload: {
        type: FAVORITES_TYPES_GET.VCUBS,
        data: vcubs.filter(vcub => vcub.vcubId !== vcubId.toString()),
      },
    });
    document.getElementsByClassName('tbm-starempty')[0].style.display = 'block';
    document.getElementsByClassName('tbm-star')[0].style.display = 'none';
    dispatch({ type: SET_LOADING, payload: false });
  } catch (e) {
    if (isWebview) {
      dispatch({ type: SET_LOADING, payload: false });
      return;
    }

    if (isConnected) {
      const success = await removeToAccount(dispatch, favorite);
      // We are in the web context, post favorite action
      if (success) {
        dispatch({
          type: SET_FAVORITE_BY_TYPE,
          payload: {
            type: FAVORITES_TYPES_GET.VCUBS,
            data: vcubs.filter(vcub => vcub.vcubId !== vcubId.toString()),
          },
        });
      }
    }
  }
};

// PARKS
export const addPark = (parkAndRideId, name, isWebview, headers = {}) => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING, payload: true });

  const { isConnected } = getState().favorites;
  const newFavorite = {
    favorite_type: FAVORITES_TYPES_POST.PARK,
    parkAndRideId: parkAndRideId.toString(),
  };
  try {
    window.ReactNativeWebView.postMessage(JSON.stringify({
      favorite_type: newFavorite.favorite_type,
      favorite_id: newFavorite.parkAndRideId,
    }));

    dispatch({ type: SET_TMP_FAVORITE, payload: newFavorite });

    if (headers && isWebview) {
      await addToAccount(dispatch, newFavorite, isWebview, headers);
    }

    if (document.getElementsByClassName('tbm-starempty')[0].style.display === 'block') {
      document.getElementsByClassName('tbm-starempty')[0].style.display = 'none';
      document.getElementsByClassName('tbm-star')[0].style.display = 'block';
    } else {
      document.getElementsByClassName('tbm-starempty')[0].style.display = 'block';
      document.getElementsByClassName('tbm-star')[0].style.display = 'none';
    }

    dispatch({ type: SET_LOADING, payload: false });
  } catch (e) {
    if (isWebview) {
      dispatch({ type: SET_LOADING, payload: false });
      return;
    }
    // We are in the web context, check if user is connected
    if (isConnected) {
      const success = await addToAccount(dispatch, newFavorite);
      if (success) {
        setFlashMessage(dispatch, Translate('favourites.parkings.add_message', { name }));
      }
      return;
    }

    dispatch({
      type: SET_MESSSAGE,
      payload: Translate('favourites.connect_to_start', { type: Translate('parking.fav_item') }),
    });
    dispatch({ type: SET_TMP_FAVORITE, payload: newFavorite });
  }
};

export const removePark = (parkAndRideId, isWebview, headers = {}) => async (dispatch, getState) => {
  const { parks, isConnected } = getState().favorites;
  const favorite = parks.find(park => park.parkAndRideId === parkAndRideId.toString());

  try {
    window.ReactNativeWebView.postMessage(JSON.stringify({
      favorite_type: favorite.favorite_type,
      favorite_id: favorite.parkAndRideId,
    }));

    if (headers && isWebview) {
      await removeToAccount(dispatch, favorite, isWebview, headers);
    }

    dispatch({
      type: SET_FAVORITE_BY_TYPE,
      payload: {
        type: FAVORITES_TYPES_GET.PARKS,
        data: parks.filter(park => park.parkAndRideId !== parkAndRideId.toString()),
      },
    });

    document.getElementsByClassName('tbm-starempty')[0].style.display = 'block';
    document.getElementsByClassName('tbm-star')[0].style.display = 'none';

    dispatch({ type: SET_LOADING, payload: false });
  } catch (e) {
    if (isWebview) {
      dispatch({ type: SET_LOADING, payload: false });
      return;
    }

    if (isConnected) {
      const success = await removeToAccount(dispatch, favorite);
      // We are in the web context, post favorite action
      if (success) {
        dispatch({
          type: SET_FAVORITE_BY_TYPE,
          payload: {
            type: FAVORITES_TYPES_GET.PARKS,
            data: parks.filter(park => park.parkAndRideId !== parkAndRideId.toString()),
          },
        });
      }
    }
  }
};

// STOPS
export const addCurrentStop = () => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING, payload: true });

  const { isConnected } = getState().favorites;
  const { stopPoint } = getState().stops;

  if (!stopPoint) {
    return dispatch({ type: SET_ERROR, payload: Translate('favourites.error.add') });
  }

  const newFavorite = {
    favorite_type: FAVORITES_TYPES_POST.STOP,
    lineId: stopPoint.route.line.id,
    routeId: stopPoint.route.id,
    stopPointId: stopPoint.id,
  };

  if (isConnected) {
    const success = await addToAccount(dispatch, newFavorite);
    if (success) {
      setFlashMessage(dispatch, Translate('favourites.stopPoints.add_message', {
        name: stopPoint.name,
        destination: stopPoint.route.name,
      }));
    }
    return;
  }

  dispatch({ type: SET_MESSSAGE, payload: Translate('favourites.connect_to_start', { type: Translate('line.fav_item') }) });
  dispatch({ type: SET_TMP_FAVORITE, payload: newFavorite });
};

export const removeCurrentStop = () => async (dispatch, getState) => {
  const { isConnected, stops } = getState().favorites;
  const { stopPoint } = getState().stops;

  if (isConnected) {
    const favorite = stops.find(favoriteStop => (
      favoriteStop.stopPointId === stopPoint.id
      && favoriteStop.routeId === stopPoint.route.id
      && favoriteStop.lineId === stopPoint.route.line.id
    ));
    const success = await removeToAccount(dispatch, favorite);

    if (success) {
      dispatch({
        type: SET_FAVORITE_BY_TYPE,
        payload: {
          type: FAVORITES_TYPES_GET.STOPS,
          data: stops.filter(favoriteStop => (
            favoriteStop.stopPointId !== stopPoint.id
            && favoriteStop.routeId !== stopPoint.route.id
            && favoriteStop.lineId !== stopPoint.route.line.id
          )),
        },
      });
    }
  }
};

// ROUTES
export const addCurrentRoute = () => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING, payload: true });

  const { isConnected } = getState().favorites;
  const { departure, arrival } = getState().routes;

  if (!departure || !arrival) {
    return dispatch({ type: SET_ERROR, payload: Translate('favourites.error.add') });
  }

  const newFavorite = {
    favorite_type: FAVORITES_TYPES_POST.ROUTE,
    startLatitude: departure.coord.lat,
    endLatitude: arrival.coord.lat,
    startLongitude: departure.coord.lon,
    endLongitude: arrival.coord.lon,
    startObjectName: departure.label,
    endObjectName: arrival.label,
    startObjectId: departure.id,
    endObjectId: arrival.id,
    startType: departure.type,
    endType: arrival.type,
  };

  if (isConnected) {
    const success = await addToAccount(dispatch, newFavorite);
    if (success) {
      setFlashMessage(
        dispatch,
        Translate('favourites.routes.add_message', { start: departure.label, stop: arrival.label }),
      );
    }
    return;
  }

  dispatch({ type: SET_MESSSAGE, payload: Translate('favourites.connect_to_start', { type: Translate('route.fav_item') }) });
  dispatch({ type: SET_TMP_FAVORITE, payload: newFavorite });
};

export const removeCurrentRoute = () => async (dispatch, getState) => {
  const { isConnected, routes } = getState().favorites;
  const { departure, arrival } = getState().routes;

  if (isConnected) {
    const favorite = routes.find(favoriteRoute => (
      favoriteRoute.start.id === departure.id
      && favoriteRoute.end.id === arrival.id
    ));
    const success = await removeToAccount(dispatch, favorite);

    if (success) {
      dispatch({
        type: SET_FAVORITE_BY_TYPE,
        payload: {
          type: FAVORITES_TYPES_GET.ROUTES,
          data: routes.filter(favoriteRoute => (
            favoriteRoute.start.id !== departure.id
            && favoriteRoute.end.id !== arrival.id
          )),
        },
      });
    }
  }
};

// PLACES
export const addPlace = place => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING, payload: true });

  const { isConnected } = getState().favorites;
  const isFavoriteFormat = !('coord' in place);

  const newFavorite = {
    favorite_type: FAVORITES_TYPES_POST.PLACE,
    latitude: isFavoriteFormat ? place.latitude : place.coord.lat,
    longitude: isFavoriteFormat ? place.longitude : place.coord.lon,
    type: place.type,
    objectName: place.label,
    objectId: isFavoriteFormat ? place.objectId : place.id,
    name: place.name,
  };

  if (isConnected) {
    const success = await addToAccount(dispatch, newFavorite);
    if (success) {
      setFlashMessage(dispatch, Translate('favourites.places.add_message', { name: place.label }));
    }
    return;
  }

  dispatch({
    type: SET_MESSSAGE,
    payload: Translate('favourites.connect_to_start', { type: Translate('pointOfInterest.fav_item') }),
  });
  dispatch({ type: SET_TMP_FAVORITE, payload: newFavorite });
};

export const removePlace = place => async (dispatch, getState) => {
  const { places, isConnected } = getState().favorites;

  // When we get favorites, id is the favorite id, and objectId is the place id.
  // Here, we set objectId key to copy the same behavior.
  if (!('objectId' in place)) {
    place.objectId = place.id;
  }

  if (isConnected) {
    const favorite = places.find(favoritePlace => favoritePlace.objectId === place.objectId);
    const success = await removeToAccount(dispatch, favorite);

    if (success) {
      dispatch({
        type: SET_FAVORITE_BY_TYPE,
        payload: {
          type: FAVORITES_TYPES_GET.PLACES,
          data: places.filter(favoritePlace => favoritePlace.objectId !== place.objectId),
        },
      });
    }
  }
};

export const removeConfirmBox = () => (dispatch) => {
  dispatch({ type: SET_MESSSAGE, payload: '' });
};
