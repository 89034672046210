const line = {
  title_infos: 'Toda la información de la línea',
  meta_description: 'Horarios de la línea de %{name} en Burdeos Métropole: paradas, frecuencias, horarios y rutas',
  meta_description_autocar: 'Encuentre aquí la información útil de la línea %{name} de la red de Nouvelle Aquitaine, que pasa por Bordeaux Métropole',
  meta_description_ter: 'Encuentre aquí la información útil de la línea %{name} de la red de SNCF, que pasa por Bordeaux Métropole',
  stopPointList: {
    empty: 'No hay paradas disponibles en esta ruta',
  },
  transport: 'transporte',
  types: {
    tramway: 'Tranvía',
    ferry: 'BAT3',
    specialBus: 'Autobuses especiales',
    bus: 'Autobús',
    busNight: 'Autobús nocturno',
    train: 'Tren (TER)',
    autocar: 'Autocar',
    scodi: 'Escuela directa',
  },
  direction: 'Dirección',
  select_direction: 'Elegir una dirección',
  destination: 'Destino',
  destinations: 'Destinos',
  stop_point: 'Parada',
  amplitude: 'Amplitud',
  info_line: 'Información de la línea',
  center_line: 'volver a centrar',
  every_x_minutes: 'cada %{frequency} minutos',
  next_schedules: 'Próximas llegadas en',
  next_refresh: 'Actualización en',
  next_schedules_empty: 'No hay resultados para la hora actual',
  nearby_stop_points: 'Paradas cercanas',
  remove_nearby_stop_points: 'Ver todas las paradas de la línea',
  schedules_on_x_day: 'Horarios del %{day}',
  schedules_other: 'Ver otro día',
  schedules: {
    title: 'Horario de la linea',
    description: 'Accede al horario y los próximos pasajes de tu línea',
    link: 'Ver horarios',
    download: 'Descarga los horarios en pdf',
    unavailable: 'Las fichas horarias no están disponibles. '
    + 'Por favor, haga clic sobre su parada par ver los horarios de pasaje de las líneas',
    choose_stop: 'Elegir una parada',
  },
  plans: {
    title: 'Plano de la linea',
    description: 'Accede a los planos de tu línea',
    link: 'Ver la mapa interactivo',
    download: 'Descarga la mapa en pdf',
  },
  search: {
    line: 'Buscar una linea',
    stop_point: 'Buscar una parada, una línea',
    loading: 'Cargando...',
    no_result: 'No hay resultados',
    error: 'Ha ocurrido un error',
    title: 'Horarios y próximas llegadas',
  },
  select_line: 'Mostrar todas las líneas',
  hide_line: 'Ocultar todas las líneas',
  no_datetimes: 'No hay horarios disponibles para esta fecha',
  scodi_not_available: 'Horarios disponibles a partir del 4 de septiembre, fecha de entrada en servicio de estas líneas. Seleccione la fecha que busca en el calendario "Ver otro día"',
  new_network_not_available: "Si busca los horarios a partir del 4 de septiembre, <a href='%{link}' target='_blank'>haga clic aquí</a>.",
  a11y: {
    cancel: 'Restablecer el campo',
    scheduleSection: 'Horarios y próximas llegadas',
    changeDirection: 'Cambiar dirección',
  },
  line_link: 'Plano y horarios',
  informations: 'Informacion practica',
  frequency: 'Frecuencia',
  amplitude_circulation: 'Amplitud de circulacion',
  services: 'Servicios a bordo',
  frequentation: 'Afluencia de viajeros prevista',
  frequentationInfo: 'Para que pueda anticipar sus viajes e moverse en las mejores '
    + 'condiciones, le proponemos de consultar la afluencia en su linea.',
  frequentationInfoHelp: 'Estimación del número de personas presentes el mes pasado excepto'
    + ' los dias de vacaciones escolares y fiestas.',
  frequentation_details_base: 'Afluencia en la línea',
  frequentation_details_morning: 'Zoom durante las horas pico de la mañana (6h30 - 9h00)',
  frequentation_details_evening: 'Zoom durante las horas pico de la noche (16h - 19h)',
  line_life: 'La vida de la linea',
  line_life_description: 'El recorrido de esta línea se adapta a los eventos de la ciudad.',
  lines: 'Ver otras lineas',
  fav_item: 'la linea',
};

export default line;
