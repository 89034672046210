import { combineReducers } from 'redux';
import { i18nReducer } from '@vernouf/upgraded-react-redux-i18n';
import appReducer from './AppReducer';
import homepageReducer from './HomepageReducer';
import trafficInfoReducer from './TrafficInfoReducer';
import newsReducer from './NewsReducer';
import lineReducer from './LineReducer';
import vcubReducer from './VcubReducer';
import favoritesReducer from './FavoritesReducer';
import parkingReducer from './ParkingReducer';
import placesReducer from './PlacesReducer';
import planReducer from './PlanReducer';
import lcMapReducer from './LCMapReducer';
import stopReducer from './StopReducer';
import routeReducer from './RouteReducer';
import infoReducer from './InfoReducer';

const reducers = combineReducers({
  app: appReducer,
  favorites: favoritesReducer,
  home: homepageReducer,
  i18n: i18nReducer,
  info: infoReducer,
  lines: lineReducer,
  lcmap: lcMapReducer,
  news: newsReducer,
  parkings: parkingReducer,
  places: placesReducer,
  plans: planReducer,
  routes: routeReducer,
  stops: stopReducer,
  traffic: trafficInfoReducer,
  vcubs: vcubReducer,
});

export default reducers;
