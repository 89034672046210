const homepage = {
  trip: {
    schedule: {
      title: 'Schedules',
    },
  },
  discover_app: {
    title: 'Discover the TBM app',
    subtitle: 'Your mobility companion to simplify your daily travels',
  },
};

export default homepage;
