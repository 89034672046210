const traffic = {
  title: 'Traffic updates',
  title_mobile: 'Traffic conditions at %{time} :',
  meta_description: 'Find all the traffic information for the tram, bus and BAT3 lines of the TBM network here.',
  detail: 'Detail',
  home: 'To travel peacefully check out the network\'s traffic updates and the details of your line',
  no_disruption_on_this_line: 'No disruption currently on this line',
  labels: {
    in_progress: 'Now',
    future: 'Future',
    favorites: 'My alerts',
    sticky: 'In the news',
    all_informations: 'All network traffic information',
  },
  types: {
    tram: 'Tram',
    bus: 'Bus',
    scodi: 'SCoDi',
    vcub: 'LE VÉLO',
    batcub: 'Bat3',
    parking: 'P+R',
    train: 'Train',
    autocar: 'Coach',
  },
  informations: {
    line: 'Disturbances on the following lines :',
    vcub: 'Information about the following stations :',
    batcub: 'Disturbances on the BAT3 :',
    parking: 'Disturbances on the following relay park :',
  },
  impacted_line: 'Line concerned',
  impacted_lines: 'Relevant lines',
  impacted_stations: 'Stations impacted',
  impacted_pr: 'P+R impacted',
  link: 'See traffic status',
  line_title: 'Delays on the line : ',
  perturbation_line: 'Delays on the line',
  pr_title: 'Delays on the park-and-ride',
  pr_working: 'All park-and-rides are operating normally',
  vcub_title: 'Informations about this station LE VÉLO',
  working: 'The network runs normally',
  twitter: 'Follow all traffic info on X (ex-twitter)',
  in_progress: 'In progress',
  future: 'Future',
  more: 'more traffic infos',
  last_sync: 'Last updated at',
  draft: 'Draft',
  template: 'Template',
  archived: 'This alert is no longer active.',
  see_all: 'See all traffic info',
  updates: 'Updates',
  today: 'Today',
  at: 'at',
  reason: 'Reason : ',
  estimated_recovery_date: 'Estimated recovery date : ',
};

export default traffic;
