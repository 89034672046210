const global = {
  from: 'De',
  to: 'a',
  from_the: 'A partir del',
  close: 'Cerca',
  more_informations: 'More Information',
  how_more: 'Más información',
  no_geolocation_available: 'El servicio de ubicación no está habilitado en las preferencias.',
};

export default global;
