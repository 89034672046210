import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import $ from 'jquery';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import routes from '../../routes';
import HtmlToReact from '../../helpers/HtmlToReact';
import TrafficBanners from '../blocks/traffic/TrafficBanners';
import Header from './Header';
import Footer from './Footer';
import TrafficModal from '../elements/traffic/TrafficModal';
import FavoriteMessage from '../blocks/popins/FavoriteMessage';
import { assetsVersion } from '../../parameters';
import { fetchWithTimeout } from '../../helpers/RequestHelper';
import { API_URL, BASE_URL } from '../../config/config';

const HtmlLayout = (props) => {
  const { children, header, footer, svg } = props;
  const lang = useSelector(state => state.i18n.locale);
  const [isHomepage, setIsHomepage] = useState(false);
  const isWebview = useSelector(state => state.app.isWebview);
  const [headerContent, setHeaderContent] = useState('');
  const [footerContent, setFooterContent] = useState('');
  const [canonical, setCanonical] = useState('');

  useEffect(() => {
    let { pathname } = window.location;
    pathname = pathname.replace('/', '');
    setIsHomepage(Object.keys(routes.HOMEPAGE.HOME.patterns).includes(pathname));
  }, [children]);

  useEffect(() => {
    async function fetchHeaderData() {
      const topData = await fetchWithTimeout(`${BASE_URL}/${lang}${API_URL}/top`);
      const headerData = await fetchWithTimeout(`${BASE_URL}/${lang}${API_URL}/header`);
      if (topData.ok && headerData.ok) {
        setHeaderContent(await topData.text() + await headerData.text());
      } else {
        fetchHeaderData();
      }
    }
    if (!isWebview) {
      fetchHeaderData();
    }
  }, [lang, isWebview]);

  useEffect(() => {
    async function fetchFooterData() {
      const contentBottomData = await fetchWithTimeout(`${BASE_URL}/${lang}${API_URL}/content_bottom`);
      const footerData = await fetchWithTimeout(`${BASE_URL}/${lang}${API_URL}/footer`);
      if (contentBottomData.ok && footerData.ok) {
        setFooterContent(await contentBottomData.text() + await footerData.text());
      } else {
        fetchFooterData();
      }
    }
    if (!isWebview) {
      fetchFooterData();
    }
  }, [lang, isWebview]);

  useEffect(() => {
    setCanonical(BASE_URL + window.location.pathname);
  });

  return (
    // Need to set the move menu bold for pages handled by NextJS, except the homepage.
    <div className={isHomepage ? '' : 'move-menu'}>
      {
        HtmlToReact.parse(svg)
      }
      {
        header !== null && typeof window !== 'undefined' && headerContent.length > 0 && footerContent.length > 0
          ? (
            <Head>
              {
                window.jQuery = $
              }
              <script src="/themes/custom/infotbm/libs/browser.js" defer />
              <script src="/themes/custom/infotbm/libs/js.cookie.js" defer />
              <script src="/themes/custom/infotbm/libs/jquery.sticky.js" defer />
              <script src="/themes/custom/infotbm/libs/jquery.hoverIntent.js" defer />
              <script src="/themes/custom/infotbm/js/infotbm.newsletter.form.submit.js" defer />

              <script src={`/themes/custom/infotbm/js/scripts.js?${assetsVersion}`} />
              <script src={`/themes/custom/infotbm/js/gtmCmsHelper.js?${assetsVersion}`} />
              <link rel="canonical" href={`${canonical}`} />
            </Head>
          )
          : null
      }

      {
        !isWebview ? (
          <div className={`${!isWebview ? 'safe-height' : ''}`}>
            <Header headerContent={headerContent.length > 0 ? headerContent : header} />
          </div>
        ) : null
      }

      <div id="main" role="main">
        <TrafficBanners />
        { children }
      </div>

      <FavoriteMessage />
      <TrafficModal />

      {
        !isWebview ? (
          <Footer footerContent={footerContent.length > 0 ? footerContent : footer} />
        ) : null
      }
    </div>
  );
};

HtmlLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  svg: PropTypes.string.isRequired,
  header: PropTypes.string,
};

HtmlLayout.defaultProps = {
  header: null,
};

const mapStateToProps = state => ({
  header: state.app.header,
  svg: state.app.svg,
});

export default connect(mapStateToProps)(HtmlLayout);
