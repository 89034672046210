const vcub = {
  title: 'LE VÉLO',
  title_home: 'LE VÉLO',
  title_list: 'Service LE VÉLO',
  meta_description_list: 'Retrouvez ici toutes les informations liées au service LE VÉLO'
  + '(stations, disponibilité en temps réel, plans, infos pratiques...).',
  meta_description: 'Les disponibilités de la station %{name} à Bordeaux Métropole : '
  + 'Vélos et places disponibles en temps réel et prédictif de la station %{name}',
  description: 'LE VÉLO : le vélo libre service de Bordeaux.',
  description_number: 'Le réseau TBM compte 186 stations et plus de 2000 vélos dont 1000 électriques.',
  availability: 'Disponibilités',
  switch: {
    title: 'Disponibilité:',
    bike: 'Vélos',
    spots: 'Places',
  },
  predict: {
    title: 'À venir',
    description: 'Prévision des disponibilités dans ',
    times: {
      now: 'En ce moment',
      15: 'Prévision dans 15 min',
      30: 'Prévision dans 30 min',
      60: 'Prévision dans 1h',
      120: 'Prévision dans 2h',
      180: 'Prévision dans 3h',
      360: 'Prévision dans 6h',
      720: 'Prévision dans 12h',
    },
    in: 'Dans',
  },
  errors: {
    refresh: 'La rafraîchissement des données n\'est pas disponible pour le moment.',
    predict: 'La prédiction n\'est pas disponible pour le moment.',
  },
  markerInfo: {
    now: 'En ce moment',
    bike: 'vélo',
    bikes: 'vélos',
    elec_bike: 'vélo élec.',
    elec_bikes: 'vélos élec.',
    spot: 'place',
    spots: 'places',
    notAvailable: 'Cette station est actuellement indisponible.',
    updatedAt: 'Mise à jour à ',
  },
  no_realtime: 'Information momentanément indisponible.',
  search: {
    title: 'Rechercher',
    placeholder: 'Chercher une station LE VÉLO',
    station: 'Chercher une station LE VÉLO ',
    example: '(nom ou n° station)',
    loading: 'Chargement en cours...',
    no_found: 'Aucune station LE VÉLO trouvée',
    no_result: 'Pas de résultat',
    find_nearby: 'Trouver une station près de moi',
  },
  last_updated_at: 'Mise à jour le',
  updated_today_at: 'Mise à jour aujourd\'hui à',
  at: 'à',
  bikes_singular: 'Vélo',
  bikes_plural: 'Vélos',
  places_singular: 'Place',
  places_plural: 'Places',
  maintenance: 'Station actuellement fermée',
  show_all: 'Afficher toutes les stations LE VÉLO',
  hide_line: 'Masquer toutes les stations LE VÉLO',
  nearby_title: 'Stations LE VÉLO',
  station: 'Station LE VÉLO',
  plan_link: 'Voir le plan des stations LE VÉLO',
  plan_station_link: 'Voir la station sur le plan',
  most_requested: 'Stations les plus demandées',
  route: 'Rechercher un itinéraire',
  plan_title: 'Plan des stations LE VÉLO',
  how_to: 'LE VÉLO: Comment ça marche ?',
  subscribe: "S'abonner au service Le Vélo",
  informations: {
    title: 'Infos pratiques',
    how: {
      title: 'Comment ça marche ?',
      get_a_bike: 'Décrocher un vélo',
      drop_a_bike: 'Raccrocher un vélo',
      vcub: 'LE VÉLO',
      elec_vcub: 'LE VÉLO électrique',
      more: 'En savoir plus',
    },
    service: {
      title: 'Tarifs LE VÉLO',
      occasional: 'Abonnements 24 heures, 7 jours, 1 mois ou 1 an',
    },
    app: {
      title: 'LE VÉLO sur votre mobile',
      vcub: 'Découvrir les apps LE VÉLO',
    },
    park: {
      title: 'Stationnement Vélo',
      bikes: 'Les abris vélos',
      train_station: 'Les parcs vélos Saint Jean',
      pr: 'Garez votre vélo dans un parc-relais',
    },
  },
  favourites: 'Mes stations favorites',
  fav_item: 'cette station LE VÉLO',
};

export default vcub;
