const mode = {
  all: 'Tout',
  bustram: 'Tram & Bus',
  vcub: 'LE VÉLO',
  batcub: 'BAT3',
  parc: 'Parcs-relais',
  plan: 'Plan dynamique',
};

export default mode;
