/**
 * Set first letter of string to uppercase and others to lowercase.
 *
 * @param str
 * @returns {string|*}
 */
export const initCap = (str) => {
  if (typeof str !== 'string') {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * Remove duplicate values of a simple array.
 *
 * @param arr
 * @returns array
 */
export const arrayUnique = arr => (
  arr.reduce((unique, item) => (
    unique.includes(item) ? unique : [...unique, item]),
  [])
);

/**
 * Remove duplicate values of an array of objects, specifying the property to compare.
 *
 * @param arrayOfObject
 * @param prop
 *   The object property to use to compare and find duplicated values.
 * @returns {*}
 */
export const arrayObjectUnique = (arrayOfObject, prop) => (
  arrayOfObject.filter((obj, index, self) => (
    index === self.findIndex(o => (
      o[prop] === obj[prop]
    ))
  ))
);

/**
 * Remove string accents.
 *
 * @param str
 *   The string to remove accents.
 * @returns string
 */
export const removeAccents = (str) => {
  const accent = [
    /[\300-\306]/g, /[\340-\346]/g, // A, a
    /[\310-\313]/g, /[\350-\353]/g, // E, e
    /[\314-\317]/g, /[\354-\357]/g, // I, i
    /[\322-\330]/g, /[\362-\370]/g, // O, o
    /[\331-\334]/g, /[\371-\374]/g, // U, u
    /[\321]/g, /[\361]/g, // N, n
    /[\307]/g, /[\347]/g, // C, c
  ];
  const noAccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

  for (let i = 0; i < accent.length; i += 1) {
    str = str.replace(accent[i], noAccent[i]);
  }

  return str;
};

/**
 * Convert file size in bytes inhuman readable unit (ko / mo).
 * @param sizeInBytes
 */
export const getFileSize = (sizeInBytes) => {
  const sizeInKilobytes = sizeInBytes / 1000;

  if (sizeInKilobytes >= 1000) {
    return `${(sizeInKilobytes / 1000).toFixed(2)} Mo`;
  }

  return `${sizeInKilobytes.toFixed(2)} Ko`;
};

/**
 * Get the integer value of a numeric string.
 * Return a default integer value if the string can't be cast into integer.
 * @param stringValue
 */
export const getIntValue = (stringValue, defaultValue = 0) => {
  if (typeof stringValue === 'string') {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(stringValue)) {
      return parseInt(stringValue, 10);
    }
  }

  return defaultValue;
};

export const checkIsWebview = () => {
  if (typeof window !== 'undefined') {
    const hostname = window.location.host;
    return hostname.includes('webviews');
  }
  return false;
};
