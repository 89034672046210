export const DEFAULT_BG = {
  desktop: '/themes/custom/infotbm/images/bg/bg-home.svg',
  mobile: '',
};

export const HOME_TAGGING_ACTIONS = {
  TRAFIC_PREVIEW: 'more traffic info',
};

export const HOME_PRIMARY_SECTION_BLOCK = {
  BLOCK_SCHEDULES: 'schedules',
  BLOCK_JOURNEYS: 'journeys',
};
