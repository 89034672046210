import {
  REQUEST_STICKY_NEWS, FETCHED_STICKY_NEWS, REQUEST_LATEST_NEWS, FETCHED_LATEST_NEWS,
} from '../actions/NewsActions';

const initialState = {
  loading: false,
  sticky: null,
  latest: [],
  latestTaxonomy: null,
};


export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_STICKY_NEWS:
      return {
        ...state,
        loading: true,
      };

    case FETCHED_STICKY_NEWS:
      return {
        ...state,
        loading: false,
        sticky: action.payload,
      };

    case REQUEST_LATEST_NEWS:
      return {
        ...state,
        loading: true,
      };

    case FETCHED_LATEST_NEWS:
      return {
        ...state,
        loading: false,
        latest: action.payload.news,
        latestTaxonomy: action.payload.taxonomy,
      };

    default:
      return state;
  }
}
