const error = {
  cities: 'Une erreur est survenue lors de la récupération des villes',
  city: 'Une erreur est survenue lors de la récupération de la ville et de ses informations',
  near: 'Une erreur est survenue lors de la récupération des lignes, stations et parc-relais autour de vous',
  enterprise: 'Une erreur est survenue lors de la récupération d\'entreprise',
  pois: 'Une erreur est survenue lors de la récupération des points d\'intérêts',
  lines: 'Une erreur est survenue lors de la récupération d\'une ligne et de ses arrêts',
  pageNotFound: 'Cette page n\'est plus en ligne',
  pageNotFoundSubtext: 'Elle est rentrée au dépôt',
  goBackToHome: 'Retourner à l\'accueil',
  errorOnThisPage: 'Cette page est en erreur',
};

export default error;
