import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { loadTranslations, syncTranslationWithStore } from '@vernouf/upgraded-react-redux-i18n';
import reducers from '../reducers';
import translations from '../public/static/locales';

export default function initializeStore(initialState = {}) {
  const store = createStore(
    reducers,
    initialState,
    applyMiddleware(thunk),
  );

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translations));

  return store;
}
