const mode = {
  all: 'All',
  bustram: 'Tram & Bus',
  vcub: 'LE VÉLO',
  batcub: 'BAT3',
  parc: 'Parcs-relais',
  plan: 'Dynamic plan',
};

export default mode;
