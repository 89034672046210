const app = {
  home_title: 'Transports Bordeaux Métropole - TBM',
  meta_desc: 'Haga sus viajes más fáciles dentro de Bordeaux Métropole. '
  + 'Encuentre sus próximos pasajes, rutas, información de precios, disponibilidad de LE VÉLO y parques de relevos ...',
  homepage: 'Bienvenida',
  errors: {
    button: 'Actualizar la página.',
    default: 'Ha ocurrido un error.',
    nearby_line: 'Ha ocurrido un error.',
    route: 'Ha ocurrido un error.',
    line: 'Ha ocurrido un error.',
    parking: 'Ha ocurrido un error.',
    vcub: 'Ha ocurrido un error.',
    stop_area: 'Ha ocurrido un error.',
    stop_point: 'Ha ocurrido un error.',
  },
  a11y: {
    back: 'Volver',
    title: 'Accesibilidad',
  },
  links: {
    transport_ticket: 'Boleto',
    mobibus_accessibility: 'Mobibus y accesibilidad',
  },
  copyright: 'Keolis Bordeaux Métropole - Todos los derechos reservados - 2022',
};

export default app;
