const global = {
  from: 'Du',
  to: 'au',
  from_the: 'A partir du',
  close: 'Fermer',
  more_informations: 'Plus d\'informations',
  how_more: 'En savoir plus',
  no_geolocation_available: 'Le service de localisation n\'est pas activé dans les préférences.',
};

export default global;
