const line = {
  HOME: {
    name: 'line-page',
    title: 'lines.title',
    patterns: {
      fr: '/lignes',
      en: '/lines',
      es: '/lineas',
    },
    page: 'lines/search',
  },
  DETAILS: {
    name: 'line-details',
    title: null,
    patterns: {
      fr: '/lignes/:line',
      en: '/lines/:line',
      es: '/lineas/:line',
    },
    page: 'lines/details',
  },
};

module.exports = line;
