export const baseUrl = 'https://www.preprod.infotbm.com';
export const trafficUrl = 'https://ws.preprod.infotbm.com/ws/1.0';
//export const trafficUrl = 'https://ws.infotbm.com/ws/1.0';
export const customerAreaUrl = 'https://mytbm.preprod.infotbm.com';
export const helpUrl = 'https://aide.preprod.infotbm.com';
//export const lcmapUrl = 'https://carto.infotbm.com/lcmap.js';
//export const lcmapUrl = 'https://bordeaux-tbm.preprod.latitude-cartagene.com/lcmap.js';
export const lcmapUrl = 'https://preprod-carto.infotbm.com/lcmap.js';
//export const googleMapKey = 'AIzaSyBdtuPJzBSMMM4psQ70Ftv1fdEJB_g4Z18';
export const googleMapKey = 'AIzaSyBG6IYlwnsP04w8tNSJLTyca-fOKr2608o';
export const GTM = 'GTM-TD44Q9Q';


export const DRUPAL_TAXONOMIES = {
  SCHEDULES: 135,
  ROUTES: 136,
  VCUB: 138,
  BATCUB: 134,
  PARKING: 137,
};
export const pathPrice = '1,80€';
export const assetsVersion = '20240520144751';

export const smartContextUrl = 'https://assets.app.smart-tribune.com/smart-tribune/FAQContext/faqcontext.main.js';
