import moment from 'moment';

export const pushGtmEvent = (event, params) => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({ event, ...params });
  }
};

export const generateDateLabel = (date) => {
  const now = moment();

  return (date > now) ? date.format('HH:MM') : '0:00';
};
