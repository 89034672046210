import moment from 'moment';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import {
  NAVITIA_PLAYGROUND_URL, ROUTES_CALCUL_URL, ROUTES_ITEM_URL,
} from '../config/config';
import {
  DEFAULT_TRANSPORT_TYPES,
  ISOLATED_ROUTE,
  NAVITIA_DATE_FORMAT,
  NAVITIA_TRANSPORT_TYPES,
  NON_ACCESSIBLE_TRANSPORT_TYPES,
  TAD_ROUTE,
} from '../config/routes';
import {
  getRouteId, setRouteModes, flatIsolatedRoutes, filterIsolatedRoutes, flatTadRoutes,
} from '../helpers/RoutesHelper';
import { fetchWithTimeout } from '../helpers/RequestHelper';
import {
  taggingEventRouteRoadmapResultList,
  taggingSwitchDepartureArrivalEvent,
} from '../tagging/routes/RoutesTaggingActions';

export const SET_ROUTE_ARRIVAL = 'SET_ROUTE_ARRIVAL';
export const SET_ROUTE_DEPARTURE = 'SET_ROUTE_DEPARTURE';
export const REVERSE_ROUTE_DIRECTIONS = 'REVERSE_ROUTE_DIRECTIONS';
export const SET_IS_DEPARTURE_DATE = 'SET_IS_DEPARTURE_DATE';
export const SET_ROUTE_DATE = 'SET_ROUTE_DATE';
export const SWITCH_ROUTE_TRANSPORTS_MODES = 'SWITCH_ROUTE_TRANSPORTS_MODES';
export const SET_WITH_ACCESSIBILITY = 'SET_WITH_ACCESSIBILITY';
export const SET_POPIN_ACCESSIBILITY = 'SET_POPIN_ACCESSIBILITY';
export const SET_SHOW_TIME_BANNER = 'SET_SHOW_TIME_BANNER';
export const POST_ROADMAP_DATA = 'POST_ROADMAP_DATA';

export const SYNC_ROUTES_STORE = 'SYNC_ROUTES_STORE';
export const CALCULATE_ROUTE = 'CALCULATE_ROUTE';
export const ROUTE_LOADING = 'ROUTE_LOADING';
export const SET_TICKET_PRICE = 'SET_TICKET_PRICE';
export const SET_GOOD_TO_KNOW_BLOCK = 'SET_GOOD_TO_KNOW_BLOCK';
export const SET_ROUTE_PATHS = 'SET_ROUTE_PATHS';
export const SET_ROUTE_MARKERS = 'SET_ROUTE_MARKERS';
export const SET_ACTIVE_ROUTE = 'SET_ACTIVE_ROUTE';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_ERROR_TRANSPORT_MODES = 'SET_ERROR_TRANSPORT_MODES';
export const SET_ERROR_FETCH = 'SET_ERROR_FETCH';

export const SET_NAVITIA_DEBUG_URL = 'SET_NAVITIA_DEBUG_URL';

export const SET_TAGGING_STARTING_POINT = 'SET_TAGGING_STARTING_POINT';
export const SET_TAGGING_ARRIVAL_POINT = 'SET_TAGGING_ARRIVAL_POINT';
export const SET_TAGGING_PREFERENCES_OPENED = 'SET_TAGGING_PREFERENCES_OPENED';

export const formatRouteStopArea = stopArea => ({
  title: 'stop_area',
  type: 'stop_area',
  coord: {
    lat: stopArea.latitude,
    lon: stopArea.longitude,
  },
  name: stopArea.name,
  label: `${stopArea.name} (${stopArea.city})`,
  id: stopArea.id,
});

export const setRouteDeparture = route => (dispatch) => {
  if (!route) {
    return dispatch({ type: SET_ROUTE_DEPARTURE, payload: route });
  }
  // From address favorite
  if ('objectId' in route) {
    route.coord = {
      lat: route.latitude,
      lon: route.longitude,
    };
    route.id = route.objectId;
  }
  dispatch({ type: SET_ROUTE_DEPARTURE, payload: route });
};

export const setRouteArrival = route => (dispatch) => {
  if (!route) {
    return dispatch({ type: SET_ROUTE_ARRIVAL, payload: route });
  }
  // From address favorite
  if ('objectId' in route) {
    route.coord = {
      lat: route.latitude,
      lon: route.longitude,
    };
    route.id = route.objectId;
  }
  dispatch({ type: SET_ROUTE_ARRIVAL, payload: route });
};

export const reverseRouteDirections = () => (dispatch, getState) => {
  const routeStore = getState().routes;
  const { departure, arrival } = routeStore;

  taggingSwitchDepartureArrivalEvent();

  dispatch({
    type: REVERSE_ROUTE_DIRECTIONS,
    payload: { departure, arrival },
  });
};

export const setIsDepartureDate = (isDeparture = true) => (dispatch) => {
  dispatch({ type: SET_IS_DEPARTURE_DATE, payload: isDeparture });
};

export const setRouteDate = date => (dispatch) => {
  dispatch({ type: SET_ROUTE_DATE, payload: date });
};

export const switchRouteTransport = transportMode => (dispatch, getState) => {
  if (!Object.values(NAVITIA_TRANSPORT_TYPES).includes(transportMode)) {
    return false;
  }

  dispatch({ type: SET_ERROR_TRANSPORT_MODES, payload: null });
  const currentTransportsModes = [...getState().routes.transportModes];

  if (!currentTransportsModes.includes(transportMode)) {
    currentTransportsModes.push(transportMode);
  } else {
    const index = currentTransportsModes.indexOf(transportMode);
    currentTransportsModes.splice(index, 1);
  }

  if (currentTransportsModes.length === 0) {
    dispatch({ type: SET_ERROR_TRANSPORT_MODES, payload: Translate('route.errors.transport_modes') });
    setTimeout(() => {
      dispatch({ type: SET_ERROR_TRANSPORT_MODES, payload: null });
    }, 5000);
    return false;
  }

  dispatch({ type: SWITCH_ROUTE_TRANSPORTS_MODES, payload: currentTransportsModes });
};

export const syncStoreFromRequest = (query, forceUpdate = false) => async (dispatch, getState) => {
  try {
    let {
      departure, arrival, date, isDepartureDate,
    } = getState().routes;
    const {
      transportModes, withAccessibility,
    } = getState().routes;
    const { isWebview } = getState().app;
    const referer = isWebview ? 'webview' : 'www';

    if (query.from && query.from_type
      && (departure === null || forceUpdate || departure.id !== query.from)) {
      const fromType = query.from_type !== 'undefined' ? query.from_type : query.from.split(/(?::|%3A)+/)[0];
      const departureQuery = await fetchWithTimeout(
        `${ROUTES_ITEM_URL}/${fromType}/${query.from}?referer=${referer}`,
      );
      departure = await departureQuery.json();
    }
    if (query.to && query.to_type
      && (arrival === null || forceUpdate || arrival.id !== query.to)) {
      const arrivalQuery = await fetchWithTimeout(`${ROUTES_ITEM_URL}/${query.to_type}/${query.to}?referer=${referer}`);
      arrival = await arrivalQuery.json();
    }

    const now = moment();
    if ('date' in query) {
      date = moment(query.date);
    }

    // Can't calcul route with a passed date.
    if (now.diff(date) > 0) {
      date = now;
    }

    if ('departure' in query && query.departure !== true && query.departure !== 'true') {
      isDepartureDate = false;
    }

    const routesStore = {
      departure,
      arrival,
      date,
      isDepartureDate,
      transportModes: 'transports' in query ? query.transports.split(',') : transportModes,
      withAccessibility: ('wheelchair' in query && query.wheelchair === 'true') || withAccessibility,
    };

    dispatch({
      type: SYNC_ROUTES_STORE,
      payload: routesStore,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Une erreur est survenue lors de la récupération des points d'itinéraire : ${e}`);
  }
};

export const calculateRoute = (sendTag = true) => async (dispatch, getState) => {
  try {
    const routesStore = getState().routes;
    const { isDebug, isWebview, history } = getState().app;

    if (!routesStore.departure || !routesStore.arrival) {
      // TODO: Throw error
      return false;
    }

    dispatch({ type: SET_ERROR_FETCH, payload: null });
    dispatch({ type: ROUTE_LOADING, payload: true });
    dispatch({ type: SET_ERROR_MESSAGE, payload: null });

    // Build URL
    // Default and required parameters
    let departureCoords;
    if (routesStore.departure.type === 'address' || (routesStore.departure.type === 'poi' && routesStore.departure.source === 'kbm')) {
      departureCoords = `${routesStore.departure.coord.lon};${routesStore.departure.coord.lat}`;
    } else {
      departureCoords = routesStore.departure.id;
    }

    let arrivalCoords;
    if (routesStore.arrival.type === 'address' || (routesStore.arrival.type === 'poi' && routesStore.arrival.source === 'kbm')) {
      arrivalCoords = `${routesStore.arrival.coord.lon};${routesStore.arrival.coord.lat}`;
    } else {
      arrivalCoords = routesStore.arrival.id;
    }

    const now = moment();
    let date = moment(routesStore.date).format(NAVITIA_DATE_FORMAT);
    if (now.diff(date) > 0) {
      date = moment().format(NAVITIA_DATE_FORMAT);
    }
    const datetimeRepresents = routesStore.isDepartureDate ? 'departure' : 'arrival';
    const referer = isWebview ? 'webview' : 'www';

    let url = `${ROUTES_CALCUL_URL}/${departureCoords}/${arrivalCoords}`;
    url = `${url}?datetime=${date}&datetime_represents=${datetimeRepresents}&referer=${referer}`;

    // Preferences
    if (routesStore.withAccessibility) {
      url = `${url}&wheelchair=true`;
    }

    // Transports filter
    const defaultTransports = [
      NAVITIA_TRANSPORT_TYPES.TRAM,
      NAVITIA_TRANSPORT_TYPES.FLEX_AREO,
      NAVITIA_TRANSPORT_TYPES.BUS,
      NAVITIA_TRANSPORT_TYPES.BATCUB,
      NAVITIA_TRANSPORT_TYPES.TRAIN_TER,
      NAVITIA_TRANSPORT_TYPES.AUTOCAR,
    ];

    const forbiddenTransports = defaultTransports.filter(
      transport => !routesStore.transportModes.includes(transport),
    );

    const additionalTransports = routesStore.transportModes.filter(
      transport => !defaultTransports.includes(transport),
    );

    forbiddenTransports.forEach((transport) => {
      url = `${url}&forbidden_uris[]=${transport}`;
    });

    additionalTransports.forEach((transport) => {
      url = `${url}&first_section_mode[]=${transport}&last_section_mode[]=${transport}`;
    });

    // refs #7562
    // Always add "walking" section to avoid missing results in some journeys
    url = `${url}&first_section_mode[]=walking&last_section_mode[]=walking`;

    if (isDebug) url = `${url}&debug`;

    // Calcul routes
    const routesQuery = await fetchWithTimeout(url);

    if (isDebug && routesQuery.headers.has('X-Debug-External-Calls')) {
      let debugUrl = NAVITIA_PLAYGROUND_URL;
      debugUrl = `${debugUrl}?request=${encodeURIComponent(routesQuery.headers.get('X-Debug-External-Calls'))}`;

      dispatch({
        type: SET_NAVITIA_DEBUG_URL,
        payload: debugUrl,
      });
    }

    const results = await routesQuery.json();
    const transportRoutes = results.journeys.length > 0 ? results.journeys : [];

    let isolatedRoutes = flatIsolatedRoutes(results, routesStore.withAccessibility);
    isolatedRoutes = filterIsolatedRoutes(isolatedRoutes);

    const tadRoutes = flatTadRoutes(results);

    // Remove routes of tomorrow (A work day finish at 3AM)
    // routes = routes.filter((route) => {
    //   const searchedDate = moment(routesStore.date);
    //   const departure = moment(route.departure_date_time);
    //
    //   return !((departure.isAfter(searchedDate, 'day') && departure.hours() >= 3)
    //     || (searchedDate.hours() < 3 && departure.isSame(searchedDate, 'day') && departure.hours() >= 3));
    // });

    if (transportRoutes.length <= 0 && isolatedRoutes.length <= 0 && tadRoutes.length <= 0) {
      dispatch({ type: SET_ERROR_MESSAGE, payload: Translate('route.result.no_more_routes_for_today') });
    }

    let routes = [...transportRoutes, ...isolatedRoutes, ...tadRoutes];
    routes = setRouteModes(routes);

    if (sendTag === true) {
      if (history[0] && history[0].pathname !== '/routes/roadmap') {
        taggingEventRouteRoadmapResultList(routes.length);
      }
    }

    // Sort routes results to display them by arrival time
    routes = routes.sort((a, b) => ((a.arrival_date_time > b.arrival_date_time) ? 1 : -1));

    let finalRoutes = [];
    routes.forEach((route) => {
      if (route.type !== ISOLATED_ROUTE && route.type !== TAD_ROUTE) {
        finalRoutes.push(route);
      }
    });

    routes.forEach((route) => {
      if (route.type === ISOLATED_ROUTE) {
        finalRoutes.push(route);
      }
    });

    routes.forEach((route) => {
      if (route.type === TAD_ROUTE) {
        finalRoutes.push(route);
      }
    });

    finalRoutes = finalRoutes.map((route, index) => {
      route.id = getRouteId(index);
      return route;
    });

    dispatch({ type: CALCULATE_ROUTE, payload: finalRoutes });
    dispatch({ type: ROUTE_LOADING, payload: false });
    dispatch({ type: SET_TICKET_PRICE, payload: results?.options?.ticket_price });
    dispatch({ type: SET_GOOD_TO_KNOW_BLOCK, payload: results?.options?.network_event });
  } catch (e) {
    dispatch({ type: SET_ERROR_FETCH, payload: Translate('route.errors.fetch') });
    // eslint-disable-next-line no-console
    console.error(`Une erreur est survenue lors de la récupération de l'itinéraire : ${e}`);
  }
};

export const setWithAccessibility = withAccessibility => (dispatch, getState) => {
  const transportModes = withAccessibility
    ? getState().routes.transportModes.filter(t => (
      !NON_ACCESSIBLE_TRANSPORT_TYPES.includes(t)
    ))
    : DEFAULT_TRANSPORT_TYPES;

  dispatch({
    type: SET_WITH_ACCESSIBILITY,
    payload: {
      withAccessibility,
      transportModes,
    },
  });
};

export const setTaggingStartingPoint = taggingValue => dispatch => (
  dispatch({ type: SET_TAGGING_STARTING_POINT, payload: taggingValue })
);

export const setTaggingArrivalPoint = taggingValue => dispatch => (
  dispatch({ type: SET_TAGGING_ARRIVAL_POINT, payload: taggingValue })
);

export const setTaggingPrefereneces = taggingValue => dispatch => (
  dispatch({ type: SET_TAGGING_PREFERENCES_OPENED, payload: taggingValue })
);
