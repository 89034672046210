const stopPoint = {
  line: 'Line',
  destination: 'Destination',
  next_pass: 'Next service in',
  theoretical_time: 'This symbol means that the arrival times displayed are theoretical',
  search: {
    scheduleDetail: 'Find a stop',
    empty: 'No stop found',
  },
  a11y: {
    date: 'Select date',
    scheduleDetail: 'Display schedule details:',
    closeDetail: 'Close details',
  },
  map: {
    hide: 'Hide the map',
    show_bus: 'View my bus on the map',
    show_batcub: 'View my Bat3 on the map',
  },
};

export default stopPoint;
