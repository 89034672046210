import React from 'react';
import PropTypes from 'prop-types';
import shapes from '../../../config/shapes';
import { formatRealtime } from '../../../helpers/TimeHelper';

const NextDestinationPass = ({ passage }) => (
  <div className="display-flex justify-space-between align-content-stretch align-items-end">
    <span className="lh-normal m-r-0-5">{passage.destination_name}</span>
    <div className="display-flex align-items-end no-mh mw-40 waittime">
      {
        <div className="ws-nw">
          {
            passage.realtime === '1'
              ? <i className="icon icon-realtime-mini" />
              : null
          }
          <span className="font-bold fs-md-h4 lh-h4" key={passage.schedule_id}>
            { formatRealtime(passage) }
          </span>
        </div>
      }
    </div>
  </div>
);

NextDestinationPass.propTypes = {
  passage: PropTypes.shape(shapes.realtime),
};

NextDestinationPass.defaultProps = {
  passage: [],
};

export default NextDestinationPass;
