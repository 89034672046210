const homepage = {
  trip: {
    schedule: {
      title: 'Horaires',
    },
  },
  discover_app: {
    title: 'Découvrez l\'app TBM',
    subtitle: 'Votre compagnon de mobilité pour simplifier vos voyages au quotidien',
  },
};

export default homepage;
