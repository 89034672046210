const error = {
  cities: 'An error occurred while retrieving cities',
  city: 'An error occurred while retrieving the city and its information',
  near: 'An error occurred while retrieving the lines, stations and relay park around you',
  enterprise: 'An error occurred while retrieving a business',
  pois: 'An error occurred while retrieving points of interest',
  lines: 'An error occurred while retrieving a line and its stops',
  pageNotFound: 'This page is not online anymore',
  pageNotFoundSubtext: 'It went back to the depot',
  goBackToHome: 'Go back to the home page',
  errorOnThisPage: 'An error occured on this page',
};

export default error;
