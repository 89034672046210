const route = {
  title: 'Itinéraires',
  meta_description: 'Calculez ici vos itinéraires pour voyager simplement sur le réseau TBM.',
  route: 'Itinéraire',
  types: {
    all: 'TOUT',
    bus_and_tramway: 'Bus & Tramway',
    ferry: 'BAT3',
    bike: 'LE VÉLO',
  },
  nearbyLines: {
    closerThanTenMinutes: 'L\'offre à moins de 10 minutes :',
  },
  search: {
    title: 'Rechercher',
    type_to_search: 'Commencez à écrire pour rechercher',
    departure: 'Départ',
    from: 'De : ',
    arrival: 'Arrivée',
    to: 'À : ',
    example: '(Adresse, Arrêt, Commune...)',
    loading: 'Chargement en cours...',
    no_result: 'Pas de résultat',
    error: 'Une erreur est survenue',
    modify: 'Modifier la recherche',
    others: 'Liste des itinéraires :',
    datetime_represents: {
      departure: 'Départ',
      arrival: 'Arrivée',
    },
    accessibility: 'Itinéraires accessibles',
    submit: 'C\'est parti !',
  },
  result: {
    routes_found_plural: 'itinéraires trouvés',
    routes_found_singular: 'itinéraire trouvé',
    no_routes_found: 'Aucun itinéraire trouvé.',
    mode_not_selected: 'Ce mode de transport ne fait pas partie de vos critères de recherche. ',
    add_mode: 'Rechercher les itinéraires avec ce mode de transport.',
    no_more_routes_for_today: 'Il n\'y a pas d\'itinéraire aux dates et heures demandées.',
    walk: 'Marche',
    personal_bike: 'Vélo',
    vcub: 'LE VÉLO',
    transports: 'En transports',
    soft_modes: 'Mobilités douces',
    tad: 'Transport à la demande',
  },
  isolated_types: {
    walking: 'piéton',
    bike: 'vélo',
    car: 'voiture',
  },
  section_types: {
    walking: 'marche',
    vcub: 'LE VÉLO',
    bike: 'vélo',
    ferry: 'BAT3',
    car: 'voiture',
    pr: 'parking relais',
    tramway: 'tram',
    bus: 'bus',
    train: 'train',
    autocar: 'car régional',
  },
  empty: 'Aucune solution trouvée',
  empty_vcub: 'Le LE VÉLO ne permet pas de faire cet itinéraire',
  empty_batcub: 'Le BAT3 ne permet pas de faire cet itinéraire',
  empty_pr: 'Pas de solution P+R pour cet itinéraire',
  empty_isolated: 'Pas de solution trouvée pour cet itinéraire',
  prefs: {
    title: 'préférences',
    for_phmr: 'Itinéraire accessible aux personnes à mobilité réduite',
    less_walk: 'Le moins de marche',
    less_connections: 'Le moins de correspondances',
    leave_now: 'départ immédiat',
    leave_at: 'départ à ...',
    arrive_at: 'arrivée à ...',
  },
  go_from: 'en partir',
  go_to: 'y aller',
  leave_at: 'partir à',
  arrive_at: 'arriver à',
  unit_minutes_abbrev: 'mn',
  walk_with: 'dont',
  unit_total_hours_abbrev: '%{hours}h %{minutes} mn',
  unit_total_minutes_abbrev: '%{minutes} mn',
  unit_walk_hours_abbrev: 'dont %{hours}h %{minutes} mn à pied',
  unit_walk_minutes_abbrev: 'dont %{minutes} mn à pied',
  unit_minute: 'minute',
  unit_minutes: 'minutes',
  vcub_duration: 'Pédaler pendant',
  vcub_section_title_start: 'Prendre un LE VÉLO à la station',
  vcub_section_title_end: 'Déposer LE VÉLO à la station',
  parking_section_title: 'Déposer la voiture au',
  vcub_bike: 'vélo disponible',
  vcub_bike_plural: 'vélos disponibles',
  vcub_place: 'place disponible',
  vcub_place_plural: 'places disponibles',
  vcub_up: 'Information mise à jour :',
  vcub_unavailable: 'information non disponible',
  around_meters: 'environ %{distance}m',
  departure: 'Départ',
  arrival: 'Arrivée',
  see_details: 'Voir le détail de l\'itinéraire',
  filter_by_type: 'Filtrer par type :',
  walking_speed: 'Vitesse de marche :',
  walking_speed_types: {
    slow: 'Lent',
    medium: 'Moyen',
    fast: 'Rapide',
  },
  destination: 'Destination',
  stops_singular: 'arrêt',
  stops_plural: 'arrêts',
  departure_label: 'Départ',
  arrival_label: 'Arrivée',
  summary_dep: 'Départ :',
  summary_arr: 'Arrivée :',
  departure_at: 'Départ le :',
  arrival_at: 'Arrivée le :',
  the: 'Le',
  at: 'à ',
  co2: 'Bilan carbone',
  arena: 'A chaque spectacle, la salle ARENA est desservie systématiquement par des navettes circulant toutes les '
  + '3 à 10 mn entre Porte de Bourgogne, Stalingrad et Arena. Premier départ 2h avant le début du spectacle, '
  + 'dernier départ 1h après la fin du spectacle',
  arena_link: '>> + infos',
  distance: 'Distance',
  mail_body: 'Votre itinéraire de %{departure} à %{arrival} avec infotbm.com',
  mail_subject: 'Votre itinéraire avec TBM',
  mail_title: 'Envoyer cet itinéraire par email',
  print_title: 'Imprimer cet itinéraire',
  full_walking_directions: 'Détail',
  next_times: 'Plus tard',
  previous_times: 'Plus tôt',
  different_day: 'Le Tram/Bus ne permet pas de faire cet itinéraire aux dates/heures indiquées, '
  + 'elles ont été modifiées.',
  walking_directions: {
    neutral: 'Prendre :',
    left: 'Tourner à gauche :',
    right: 'Tourner à droite :',
    on: 'sur',
    meters: 'mètres',
  },
  a11y: {
    section: 'Block itinéraire',
    pager: 'Aller à l\'itinéraire',
    search: {
      departure: 'Départ',
      arrival: 'Arrivée',
    },
    geolocation: {
      departure: 'Géolocalisation départ',
      arrival: 'Géolocalisation arrivée',
    },
    switch: 'Inverser le départ et l\'arrivée',
    cancel: 'Réinitialiser le champs',
    zoom: 'Zoomer sur la carte',
    stops: 'Afficher les %{number} arrêts',
    path: 'Afficher l\'itinéraire détaillé',
    openPreferences: 'Afficher les préférences',
    closePreferences: 'Cacher les préférences',
    nextTime: 'Horaire suivant',
    previousTime: 'Horaire précédent',
    with_accessibility: 'Itinéraires accessibles aux personnes en fauteuil roulant',
    accessible_solutions: 'Ces itinéraires sont les solutions les plus proches de votre recherche avec des '
    + 'arrêts de montée et de descente accessibles aux personnes en fauteuil roulant.',
    modal: {
      title: 'Votre itinéraire',
      content: 'L\'itinéraire proposé est une des solutions les plus proches de votre recherche avec des '
      + 'arrêts de montée et de descente accessibles aux personnes en fauteuil roulant.',
      button: 'Voir le trajet',
    },
  },
  help: {
    title: 'Aide et conseils',
    night: {
      title: 'Se déplacer la nuit',
      description: 'Profitez de votre soirée sans le stress du retour !',
    },
    rules: {
      title: 'Les règles pour mieux voyager',
      description: 'Voyageons ensemble, vivons mieux ensemble',
    },
    parking: {
      title: 'Utiliser les parcs-relais (P+R)',
      description: 'Laissez-y votre voiture et prenez les transports en commun',
    },
    loyalty: {
      title: 'Le programme de fidélité',
      description: 'Plus vous validez, plus vous gagnez de cadeaux',
    },
    prices: {
      title: 'Les tarifs',
      description: 'Quel que soit votre besoin, trouvez le tarif qui vous convient',
    },
    handicapped: {
      title: 'Personnes handicapées ou à mobilité réduite',
      description: 'Toutes nos solutions pour faciliter votre voyage',
    },
    m_ticket: {
      title: 'Acheter mon m-ticket',
      description: 'L\'application m-ticket vous permet d\'acheter votre titre de transport dématérialisé '
      + 'et de le valider avec votre smartphone (Android et iOS) lors de vos déplacements en bus, tram et bat3.',
    },
  },
  transport_modes: {
    title: 'Préférences',
    title_accessible: 'Modes de transport accessibles',
    tram: 'Tram',
    flex_aero: 'Flex Aero',
    bus: 'Bus',
    vcub: 'LE VÉLO',
    batcub: 'BAT3',
    parking: 'Parc Relais',
    train: 'Train (TER)',
    autocar: 'Car',
  },
  edit: 'Modifier',
  favorites: 'Mes itinéraires favoris',
  add_favorites: 'Ajouter à mes favoris',
  remove_favorites: 'Retirer de mes favoris',
  errors: {
    transport_modes: 'Au moins un mode de transport doit être sélectionné.',
    fetch: 'Une erreur est survenue lors de la récupération des itinéraires. Essayez de recharger la page.',
  },
  back_to_list: 'Retour à la liste d\'itinéraires',
  path_price: 'Prix d\'un trajet TBM (Tram, Bus & Bat3)',
  path_price_V3: 'Conditions tarifaires et achat de titres LE VÉLO',
  path_price_train: 'Conditions tarifaires et achat de titres TER\n',
  path_price_autocar: 'Conditions tarifaires et achat de titres Car\n',
  path_cgu_autocar: 'Consultez les conditions générales d\'utilisation Car',
  path_cgu_train: 'Consultez les conditions générales d\'utilisation TER',
  path_price_link_here: 'ici',
  where_to_buy: 'Acheter mon titre',
  info_tad: 'Vous devez réserver votre trajet flex’ sur l\'application « TBM flex’ »',
  buy_tad: 'Accéder à TBM flex’',
  offer_ter: 'Ce trajet TER est inclus dans l\'abonnement <strong>"TBM + Trains"</strong>',
  buy_offer_ter: 'Découvrir l\'offre trains',
  close: 'Fermer',
  see_map: 'Voir sur la carte',
  see_roadmap: 'Voir la feuille de route',
  less_than_a_minute: 'A moins d\'une minute',
  fav_item: 'cet itinéraire',
  places: {
    indication: 'Cliquez sur un lieu pour vous y rendre directement',
  },
  train_in_route: 'Votre abonnement TBM est valable sur ce trajet',
  co2_of: 'de',
  stay_in: 'Attendre',
};

export default route;
