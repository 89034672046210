const vcub = {
  title: 'LE VÉLO',
  title_home: 'LE VÉLO',
  title_list: 'Servicio LE VÉLO',
  meta_description_list: 'Encuentre aquí toda la información relacionada con el servicio LE VÉLO '
  + '(estaciones, disponibilidad en tiempo real, mapas, información práctica ...).',
  meta_description: 'La disponibilidad de la estación %{name} en Bordeaux Métropole: '
  + 'bicicletas y lugares disponibles en tiempo real y predictivos de la estación %{name}',
  description: 'LE VÉLO: la bicicleta de autoservicio en Burdeos.',
  description_number: 'La red de TBM cuenta con 186 estaciones y más de 2000 bicicletas, '
  + 'incluyendo 1000 bicicletas eléctricas.',
  availability: 'Disponibilidad',
  switch: {
    title: 'Disponibilidad:',
    bike: 'Bicicletas',
    spots: 'Plazas',
  },
  predict: {
    title: 'Próximamente',
    description: 'Previsión de disponibilidad en ',
    times: {
      now: 'En este momento',
      15: 'Previsión dentro de 15 minutos',
      30: 'Previsión en 30 minutos',
      60: 'Previsión dentro de 1 h',
      120: 'Previsión en 2 h',
      180: 'Previsión en 3 h',
      360: 'Previsión en 6 h',
      720: 'Previsión en 12h',
    },
    in: 'En',
  },
  errors: {
    refresh: 'Los datos actualizados no están disponibles por el momento.',
    predict: 'La predicción no está disponible por el momento.',
  },
  markerInfo: {
    now: 'En este momento',
    bike: 'bicicleta',
    bikes: 'bicicletas',
    elec_bike: 'bicicleta elec.',
    elec_bikes: 'bicicletas elec.',
    spot: 'plaza',
    spots: 'plazas',
    notAvailable: 'Esta estación no está disponible actualmente.',
    updatedAt: 'Actualizado a las ',
  },
  no_realtime: 'Información no disponible temporalmente.',
  search: {
    placeholder: 'Encuentra una estación LE VÉLO',
    title: 'Buscar',
    station: 'Buscar una estación LE VÉLO',
    example: '(nombre o n° estación)',
    loading: 'Cargando...',
    no_found: 'No se han encontrado estaciones de LE VÉLO',
    no_result: 'No hay resultados',
    find_nearby: 'Find a Vcub Station nearby',
  },
  last_updated_at: 'Actualizado el',
  updated_today_at: 'Actualizado hoy a las',
  at: 'a',
  bikes_singular: 'Bicicleta',
  bikes_plural: 'Bicicletas',
  places_singular: 'Plaza',
  places_plural: 'Plazas',
  maintenance: 'Estación actualmente cerrada',
  show_all: 'Mostrar todas las estaciones LE VÉLO',
  hide_line: 'Ocultar todas las estaciones LE VÉLO',
  nearby_title: 'Estaciones LE VÉLO',
  station: 'Estación LE VÉLO',
  plan_link: 'Ver el mapa de estaciones LE VÉLO',
  plan_station_link: 'Ver la estación en el mapa',
  most_requested: 'estaciones mas solicitadas',
  route: 'Encontrar un itinerario',
  plan_title: 'Mapa de estaciones LE VÉLO',
  how_to: 'LE VÉLO: ¿Cómo funciona? ',
  subscribe: 'Suscribirse al servicio Le Vélo',
  informations: {
    title: 'Informacion practica',
    how: {
      title: 'Como funciona ?',
      get_a_bike: 'Conseguir una bicicleta',
      drop_a_bike: 'Colgar una bicicleta',
      vcub: 'LE VÉLO',
      elec_vcub: 'LE VÉLO eléctrico',
      more: 'Más información',
    },
    service: {
      title: 'Tarifas LE VÉLO',
      occasional: 'Suscripción boleto 24 horas, boleto 7 días, 1 mes o 1 año',
    },
    app: {
      title: 'LE VÉLO en tu móvil',
      vcub: 'Descubre las aplicaciones móvil LE VÉLO',
    },
    park: {
      title: 'Estacionamiento de bicicletas',
      bikes: 'Refugios para bicicletas',
      train_station: 'Parques de bicicletas Saint Jean',
      pr: 'Estaciona tu bicicleta en un parque',
    },
  },
  favourites: 'Mis estaciones favoritas',
  fav_item: 'la estación LE VÉLO',
};

export default vcub;
