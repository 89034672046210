import PropTypes from 'prop-types';

const Header = (props) => {
  const { headerContent } = props;
  return (
    // eslint-disable-next-line react/react-in-jsx-scope,react/no-danger
    <div className="header-wrapper" dangerouslySetInnerHTML={{ __html: headerContent }} />
  );
};

Header.propTypes = {
  headerContent: PropTypes.string.isRequired,
};

export default Header;
