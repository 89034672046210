const cities = {
  title: 'Municipios',
  show_all: 'Mostrar todos los municipios',
  hide_all: 'Ocultar todos los municipios',
  not_found: 'No se ha encontrado el municipio',
  transport_title: 'Ofertas de transporte en',
  search: {
    placeholder: 'Buscar un municipio',
    no_found: 'No se han encontrado municipios',
  },
};

export default cities;
