export const DRUPAL_NIDS = {
  // HOME
  card: 302, // Titre de transport
  phmr: 992, // Mobibus et accessibilité
  news: 175,
  ourValues: 903,
  reportViolenceAndHarassment: 860,

  // TRAFFIC INFO
  twitter: 43,

  // LINES
  agencies: 131,

  // PLANS
  planDynamic: 120,
  planNetwork: 121,
  planLines: 122,
  planNight: 592,
  planTown: 126,
  planTouristic: 848,
  planParks: 133,
  subscriptions: 178,
  nightPass: 52,
  moveNight: 118,

  lineBus: 1036,

  // VCUBS
  bikePark: 116,
  v3Mobile: 158,
  v3HowTo: 1214,

  // PARC RELAIS
  ParkR: 169,
  ParkPrice: 607,

  // TARIF
  tarif: 585,
  employeePass: 590,

  // FOOTER
  personalData: 501,
  legalNotice: 500,
  legalMentions: 329,

  // HELP AND ADVICES
  routeNight: 1050,
  routeRules: 135,
  routeParkR: 169,
  routeLoyalty: 20,
  routePrices: 1,
  routeHandicapped: 992,

  scheduleMap: 11,

  // NEWS
  mTicketNews: 448,

  // SNCF
  tbmSubscriptionSncf: 816,
};

export const DRUPAL_TERMS_IDS = {
  transportTickets: 150,
};

export const INFOTBM_HELP_WEBSITE = 'https://aide.infotbm.com/?thematic=preparer-trajet';

export const AUTOCAR_CGU_URL = 'https://transports.nouvelle-aquitaine.fr/cars-regionaux/reglement';

export const TRAIN_CGU_URL = 'https://www.ter.sncf.com/nouvelle-aquitaine/conditions-de-vente';

export const PATH_PRICE_URLS = {
  fr: {
    PATH_PRICE: 'https://boutique.infotbm.com/',
    PATH_PRICE_V3: 'https://portail.cykleo.fr/V3/souscrire/choix_duree_d_abonnement',
    PATH_PRICE_TRAIN: 'https://m.ter.sncf.com/nouvelle-aquitaine/abonnements/tous-les-abonnements/abonnements-reseaux-urbains/pass-ter-plus-tbm/pass-ter-plus-tbm-annuel',
    PATH_PRICE_AUTOCAR: 'https://boutique33.transports.nouvelle-aquitaine.fr/boutique33/',
  },
  en: {
    PATH_PRICE: 'https://boutique.infotbm.com/',
    PATH_PRICE_V3: 'https://portail.cykleo.fr/V3/souscrire/choix_duree_d_abonnement?l=en',
    PATH_PRICE_TRAIN: 'https://m.ter.sncf.com/nouvelle-aquitaine/abonnements/tous-les-abonnements/abonnements-reseaux-urbains/pass-ter-plus-tbm/pass-ter-plus-tbm-annuel',
    PATH_PRICE_AUTOCAR: 'https://boutique33.transports.nouvelle-aquitaine.fr/boutique33/',
  },
  es: {
    PATH_PRICE: 'https://boutique.infotbm.com/',
    PATH_PRICE_V3: 'https://portail.cykleo.fr/V3/souscrire/choix_duree_d_abonnement?l=en',
    PATH_PRICE_TRAIN: 'https://m.ter.sncf.com/nouvelle-aquitaine/abonnements/tous-les-abonnements/abonnements-reseaux-urbains/pass-ter-plus-tbm/pass-ter-plus-tbm-annuel',
    PATH_PRICE_AUTOCAR: 'https://boutique33.transports.nouvelle-aquitaine.fr/boutique33/',
  },
};
