const nearbyMe = {
  title: 'Cerca de casa',
  btn_geoloc: 'Ubícame',
  search: {
    type_to_search: 'Escriba para buscar',
    placeholder: 'Dirección, parada, municipio...',
    loading: 'Cargando...',
    no_result: 'No hay resultados',
    error: 'Ha ocurrido un error',
  },
};

export default nearbyMe;
