const others = {
  title: 'Other Services',
  title_home: 'Prepare your trip<span style="opacity: 0;">&nbsp;</span>!',
  home: 'Check out our additional services to plan your journey using the TBM network',
  home_report_violence_harassment: 'Reporting violence or harassment',
  home_accessibility_mobibus: 'Accessibility and Mobibus',
  title_mobile: 'The TBM offer :',
  air_quality: 'Air quality :',
  air_quality_infos: 'More information on the',
  air_quality_website: 'Atmo website',
};

export default others;
