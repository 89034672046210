const traffic = {
  HOME: {
    name: 'traffic-page',
    title: 'traffic.title',
    patterns: {
      fr: '/perturbations',
      en: '/traffic-info',
      es: '/perturbaciones',
    },
    page: 'traffic/list',
  },
  DETAILS: {
    name: 'traffic-details',
    title: 'traffic.detail',
    patterns: {
      fr: '/perturbations/:id',
      en: '/traffic-info/:id',
      es: '/perturbaciones/:id',
    },
    page: 'traffic/details',
  },
  PRIVATE: {
    name: 'traffic-private',
    title: 'traffic.detail',
    patterns: {
      fr: '/perturbations/:target(brouillon|modele)/:id',
      en: '/traffic-info/:target(brouillon|modele)/:id',
      es: '/perturbaciones/:target(brouillon|modele)/:id',
    },
    page: 'traffic/private',
  },
};

module.exports = traffic;
