import {
  FETCHED_DRUPAL_LINKS, FETCHED_DRUPAL_TERMS,
  HTML_LAYOUT,
  REQUEST_DRUPAL_LINKS, RESET_WEBVIEW_HEADERS,
  SAVE_HISTORY, SET_IS_DEBUG, SET_IS_SERVER, SET_IS_WEBVIEW,
  SET_MOBILE, SET_SM_MOBILE, SET_PAGE, SET_WEBVIEW_HEADERS, SET_LANG, SET_IS_HOMEPAGE,
} from '../actions/AppActions';

const initialState = {
  isServer: false,
  isWebview: false,
  isHomepage: false,
  isDebug: false,
  page: null,
  header: null,
  headers: {},
  footer: null,
  svg: null,
  history: [],
  isMobile: false,
  isConnected: false,
  loading: false,
  drupalLinks: {},
  drupalTerms: {},
  language: 'fr',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HTML_LAYOUT:
      return {
        ...state,
        header: action.payload.header,
        footer: action.payload.footer,
        svg: action.payload.svg,
      };
    case SAVE_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case SET_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case SET_SM_MOBILE:
      return {
        ...state,
        isSmMobile: action.payload,
      };
    case SET_IS_HOMEPAGE:
      return {
        ...state,
        isHomepage: action.payload,
      };
    case REQUEST_DRUPAL_LINKS:
      return {
        ...state,
        loading: true,
      };
    case FETCHED_DRUPAL_LINKS:
      return {
        ...state,
        loading: false,
        drupalLinks: action.payload,
      };
    case FETCHED_DRUPAL_TERMS:
      return {
        ...state,
        loading: false,
        drupalTerms: action.payload,
      };
    case SET_IS_SERVER:
      return {
        ...state,
        isServer: action.payload,
      };
    case SET_IS_WEBVIEW:
      return {
        ...state,
        isWebview: action.payload,
      };
    case SET_WEBVIEW_HEADERS:
      return {
        ...state,
        headers: {
          'X-KEOLIS-AUTH-TOKEN': action?.payload['x-keolis-auth-token'],
          'X-KEOLIS-AUTH-USER': action?.payload['x-keolis-auth-user'],
          'X-KEOLIS-APP-VERSION': action?.payload['x-keolis-app-version'],
        },
      };
    case RESET_WEBVIEW_HEADERS:
      return {
        ...state,
        headers: {},
      };
    case SET_IS_DEBUG:
      return {
        ...state,
        isDebug: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
};
