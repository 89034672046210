const traffic = {
  title: 'Infos trafic',
  title_mobile: 'L\'état du trafic à %{time} :',
  meta_description: 'Retrouvez ici toutes les infos trafic des lignes de tram, bus et BAT3 du réseau TBM.',
  detail: 'Détail',
  home: 'Pour voyager en toute sérénité, consultez l\'info trafic du réseau et le détail de votre ligne !',
  no_disruption_on_this_line: 'Aucune perturbation actuellement sur cette ligne',
  labels: {
    in_progress: 'En cours',
    future: 'A venir',
    favorites: 'Mes lignes en alerte',
    sticky: 'À la une',
    all_informations: 'Toute l\'info trafic du réseau',
  },
  types: {
    tram: 'Tram',
    bus: 'Bus',
    scodi: 'SCoDi',
    vcub: 'Vélo',
    batcub: 'Bat3',
    parking: 'P+R',
    train: 'Train',
    autocar: 'Car',
    other: 'Autres',
  },
  informations: {
    line: 'Perturbations sur les lignes suivantes :',
    vcub: 'Informations sur les stations suivantes :',
    batcub: 'Perturbations sur le BAT3 :',
    parking: 'Perturbations sur les parc-relais suivants :',
  },
  impacted_line: 'Ligne concernée',
  impacted_lines: 'Lignes concernées',
  impacted_stations: 'Stations impactées',
  impacted_pr: 'P+R impactés',
  link: 'Voir l\'état du réseau',
  line_title: 'Perturbations sur la ligne : ',
  perturbation_line: 'Perturbations sur cette ligne',
  pr_title: 'Perturbations sur ce parc-relais',
  pr_working: 'L\'ensemble des parcs-relais fonctionne normalement',
  vcub_title: 'Informations sur cette station LE VÉLO',
  working: 'Le réseau circule normalement',
  twitter: 'Suivez toute l\'info trafic sur X (ex-twitter)',
  more: '+ d\'infos trafic',
  last_sync: 'Dernière mise à jour à',
  draft: 'Brouillon',
  template: 'Modèle',
  archived: 'Cette alerte n\'est plus active.',
  see_all: 'Voir toute l\'info trafic',
  updates: 'Mises à jour',
  today: 'Aujourd\'hui',
  at: 'à',
  reason: 'Motif : ',
  estimated_recovery_date: 'Reprise estimée : ',
};

export default traffic;
