const line = {
  title_infos: 'All info about line',
  meta_description: '%{type} %{name} timetables at Bordeaux Métropole: stops, frequencies, timetables, and routes',
  meta_description_autocar: 'Find here useful information regarding the line %{name} of the New Aquitaine network, going through Bordeaux Métropole',
  meta_description_ter: ' Find here useful information regarding the line %{name} of the SNCF network, going through Bordeaux Metropole',
  stopPointList: {
    empty: 'No stops were found for this route',
  },
  transport: 'transport',
  types: {
    tramway: 'Tram',
    ferry: 'BAT3',
    specialBus: 'Special bus',
    bus: 'Bus',
    busNight: 'Night bus',
    train: 'Train (TER)',
    autocar: 'Regional line',
    scodi: 'Direct to School',
  },
  direction: 'Direction',
  select_direction: 'Choose a direction',
  destination: 'Destination',
  destinations: 'Destinations',
  stop_point: 'Stop',
  amplitude: 'Running times',
  info_line: 'line info',
  center_line: 'recentre',
  every_x_minutes: 'every %{frequency} minutes',
  next_schedules: 'Next services in',
  next_refresh: 'Next update in',
  next_schedules_empty: 'No result at this moment',
  nearby_stop_points: 'Nearby stops',
  remove_nearby_stop_points: 'Show all stops on the line',
  schedules_on_x_day: 'Timetable for %{day}',
  schedules_other: 'See another day',
  schedules: {
    title: 'Schedules of the line',
    description: 'Go to the schedules and the next passages of your line',
    link: 'See schedules',
    download: 'Download the schedules in pdf',
    unavailable: 'Time sheets not available. Please select your stop to find out the schedule',
    choose_stop: 'Select your stop',
  },
  plans: {
    title: 'Line map',
    description: 'Access to the plans of your line',
    link: 'See the interactive map',
    download: 'Download the plan in pdf',
  },
  search: {
    line: 'Search a line',
    stop_point: 'Find a stop, a line',
    loading: 'Loading...',
    no_result: 'No result found',
    error: 'An error has occurred',
    title: 'Timetable and next services',
  },
  select_line: 'Display all lines',
  hide_line: 'Hide all lines',
  no_datetimes: 'No timetable available for this date',
  scodi_not_available: 'Schedules available from September 4, when these lines go into service. Please select the date you are looking for from the "See another day" calendar',
  new_network_not_available: "If you're looking for a timetable from 4 September, <a href='%{link}' target='_blank'>click here.</a>",
  line_link: 'Plan & timetables',
  informations: 'Practical information',
  frequency: 'Frequency',
  amplitude_circulation: 'Amplitude of circulation',
  services: 'On board services',
  frequentation: 'Estimated occupancy level',
  frequentationInfo: 'In order to anticipate your journey and travel in the best conditions, you can '
      + 'check the estimated occupancy level of your bus or tram line.',
  frequentationInfoSoon: 'Soon, find here the estimated occupancy level of your line, hour by hour.',
  frequentationInfoHelp: 'The on board occupancy level\'s estimation is based on '
    + 'the previous month, outside of school and bank holidays.',
  frequentation_details_base: 'Occupancy level on the line',
  frequentation_details_morning: 'Focus on the morning peak time (from 6:30 am to 9 am)',
  frequentation_details_evening: 'Focus on the evening peak time (from 4:30 pm to 7 pm)',
  line_life: 'The life of the line',
  line_life_description: 'The route of this line adapts to the events of the city',
  lines: 'See other lines',
  fav_item: 'this stop',
};

export default line;
