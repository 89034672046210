import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToAddFavorite, removeConfirmBox } from '../../../actions/FavoritesActions';

const FavoriteMessage = () => {
  const message = useSelector(state => state.favorites.message);
  const isConnected = useSelector(state => state.favorites.isConnected);
  const dispatch = useDispatch();

  if (message.trim().length > 0) {
    return (
      <div
        className="modal show-modal"
        onClick={() => dispatch(removeConfirmBox())}
        onKeyPress={() => dispatch(removeConfirmBox())}
        role="button"
        tabIndex={0}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span
                className="close-button"
                onClick={() => dispatch(removeConfirmBox())}
                onKeyPress={() => dispatch(removeConfirmBox())}
                role="button"
                tabIndex={0}
              >
                      &times;
              </span>
            </div>
            <div className="modal-body">
              <p className="fs-normal textalign-center" dangerouslySetInnerHTML={{ __html: message }} />
            </div>
            <div className="modal-footer">
              {
                !isConnected
                  ? (
                    <button type="button" onClick={() => dispatch(redirectToAddFavorite())}>
                      {Translate('favourites.connection')}
                    </button>
                  )
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default FavoriteMessage;
