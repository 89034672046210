export const ALERT_TYPE = {
  CLASSIC: 1,
  HIGHLIGHTED: 2,
  BIG_IMPACT: 3,
  SMALL_IMPACT: 4,
};

export const ALERT_CAUSE = {
  STOPPAGE: 1,
  DEVIATION_BUS: 2,
  INTERRUPTION_TRAM: 3,
  INFORMATION: 5,
  RESUMPTION_OF_TRAFFIC: 6,
  EVENTS: 7,
  MAINTENANCE_TRAM: 8,
};

export const TRAFFIC_DATE_FORMAT = 'YYYYMMDD[T]HHmmss';

export const FAVORITES_ALERTS_SORT_ORDER = [
  'Tramway',
  'Bus',
  'Ferry',
  'Train régional / TER',
  'Autocar',
];

// Map url target with api target.
export const TRAFFIC_PRIVATE_PARAMS = {
  brouillon: 'draft',
  modele: 'template',
};
