const enterprise = {
  title: 'Les entreprises et établissements partenaires TBM',
  text: 'Vous êtes 10 salariés de 28 ans et + au sein d\'une entreprise partenaire de TBM ? '
  + 'Profitez de <b>20% à 30% de réduction</b> avec l\'abonnement annuel Pass Salarié !',
  text_2: 'En savoir + sur le Pass Salarié',
  search: {
    title: 'Recherchez votre entreprise',
    type_to_search: 'Commencez à écrire pour rechercher',
    placeholder: 'Nom de l\'entreprise',
    placeholder_indication: '(3 caractères min)',
    loading: 'Chargement en cours...',
    no_result: 'Pas de résultat',
    error: 'Une erreur est survenue',
  },
  summary: {
    name: 'Nom',
    address: 'Adresse',
    discount: 'Remise',
    contact: 'Contact',
  },
};

export default enterprise;
