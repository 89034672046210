import { BASE_URL, PLACES_URL } from '../config/config';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const FETCH_PLACES = 'FETCH_PLACES';

export const fetchAllPlaces = (taxonomy = null) => async (dispatch, getState) => {
  try {
    const placesStore = getState().places;
    if (placesStore.places.length > 0 && placesStore.latestPlaces === taxonomy) {
      return false;
    }

    const langcode = getState().i18n.locale;
    const placesQuery = await fetchWithTimeout(`${BASE_URL}/${langcode}${PLACES_URL}${taxonomy ? `/${taxonomy}` : ''}`);
    const places = await placesQuery.json();

    dispatch({
      type: FETCH_PLACES,
      payload: { places, taxonomy },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des lieux : ${e}`);
  }
};
