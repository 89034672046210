const map = {
  hide: 'Cacher la carte',
  a11y: {
    shrink: 'Rétrécir la carte',
    expand: 'Agrandir la carte',
    show: 'Afficher la carte',
    geolocate: 'Localiser ma position',
    mapSection: 'Autour de moi',
  },
};

export default map;
