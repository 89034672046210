import {
  FETCH_ALL_LINES,
  FETCH_BATCUB,
  FETCH_BUS,
  SET_LINE,
  FETCH_LINES,
  FETCH_TRAMS,
  SET_LINE_ROUTE,
  FETCH_TRAINS,
  FETCH_AUTOCARS,
  FETCH_SCODIS, FETCH_BUS_NIGHT,
} from '../actions/LineActions';

const initialState = {
  allLines: [],
  lines: [],
  trams: [],
  bus: [],
  busNight: [],
  scodi: [],
  batcub: [],
  trains: [],
  autocars: [],
  line: null,
  lineRoute: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_LINES:
      return {
        ...state,
        allLines: action.payload,
      };
    case FETCH_LINES:
      return {
        ...state,
        lines: action.payload,
      };
    case FETCH_TRAMS:
      return {
        ...state,
        trams: action.payload,
      };
    case FETCH_BUS:
      return {
        ...state,
        bus: action.payload,
      };
    case FETCH_BUS_NIGHT:
      return {
        ...state,
        busNight: action.payload,
      };
    case FETCH_SCODIS:
      return {
        ...state,
        scodi: action.payload,
      };
    case FETCH_BATCUB:
      return {
        ...state,
        batcub: action.payload,
      };
    case FETCH_TRAINS:
      return {
        ...state,
        trains: action.payload,
      };
    case FETCH_AUTOCARS:
      return {
        ...state,
        autocars: action.payload,
      };
    case SET_LINE:
      return {
        ...state,
        line: action.payload,
      };
    case SET_LINE_ROUTE:
      return {
        ...state,
        lineRoute: action.payload,
      };
    default:
      return state;
  }
};
