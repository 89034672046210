const plans = {
  title: 'Planes',
  title_index: 'Todos los planes',
  title_vcub: 'Planes LE VÉLO',
  title_park: 'Planes de parque de relevos',
  meta_description_index: 'Encuentre aquí todos los planes de la red TBM '
  + '(plan de línea, red diurna y nocturna, estaciones LE VÉLO, parques de relevos ...).',
  meta_description_vcub: 'Encuentre aquí el plan LE VÉLO de la red TBM.',
  meta_description_park: 'Encuentre aquí el plan de parque de retransmisión de red TBM.',
  description: 'Para acompañarte en tus viajes, descubre todos nuestros planes! '
  + 'Planes de red, planes de línea, planes de estación LE VÉLO, planes de parque de relevos, mapa dinámico, etc.',
  lines: 'Plan de línea',
  parks: 'Planes de parque de relevos',
  network: 'Planes de red',
  town: 'Planes por municipio',
  touristic: 'Mapa turistico',
  night: 'Planos de la red nocturna.',
  vcub: 'Disparos de estaciones LE VÉLO',
  help: {
    title: 'Bueno saber',
    save_print: 'Usted tiene la posibilidad de guardar e imprimir sus planos',
    subscriptions: 'Para moverse, piense en ',
    tbm_subscriptions: 'las entradas o en los abonos.',
    or: 'o en el ',
    night_pass: 'pase nocturno.',
    infos: 'Descubra toda la información práctica para ',
    move_night: 'moverse de noche con TBM!',
    prepare_moves: 'preparar sus viajes.',
  },
  dynamic: {
    title: 'Plan dinámico',
    meta_description: 'Visualice en un nivel dinámico toda la oferta de transporte TBM '
    + '(líneas, municipios, empresas y establecimientos asociados ...).',
    around: 'A mi alrededor',
    cities: 'Comunas',
    public_places: 'Lugares públicos',
    enterprises: 'Empresas TBM e instituciones asociadas',
    lines: 'Las lineas',
  },
  highlight: {
    title: 'Es bueno saberlo',
    description: 'Itinerarios teóricos dados a título indicativo.\nRecuerde consultar en tiempo real las próximas circulaciones de su autobús/tranvía.',
  },
};

export default plans;
