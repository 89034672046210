const line = {
  title_infos: 'Toutes les infos sur la ligne',
  meta_description: 'Retrouvez ici toutes les informations utiles sur la ligne %{name} du réseau TBM '
  + '(horaires, plan, fréquence, amplitude de circulation...)',
  meta_description_autocar: 'Retrouvez ici les informations utiles de la ligne %{name} du réseau Nouvelle Aquitaine, passant par Bordeaux Métropole',
  meta_description_ter: 'Retrouvez ici les informations utiles de la ligne %{name} du réseau SNCF, passant par Bordeaux Métropole',
  stopPointList: {
    empty: 'Aucun point d\'arrêt disponible sur cette route',
  },
  transport: 'transport',
  types: {
    tramway: 'Tram',
    ferry: 'BAT3',
    bus: 'Bus',
    busNight: 'Bus de nuit',
    specialBus: 'Bus spéciaux',
    train: 'Train (TER)',
    autocar: 'Car régional',
    scodi: 'Transport Scolaire ScoDi',
  },
  direction: 'Direction',
  select_direction: 'Choisir une direction',
  destination: 'Destination',
  destinations: 'Destinations',
  stop_point: 'Arrêt',
  amplitude: 'Amplitude',
  info_line: 'info ligne',
  center_line: 'recentrer',
  every_x_minutes: 'toutes les %{frequency} minutes',
  next_schedules: 'Prochains passages dans',
  next_refresh: 'Mise à jour dans',
  next_schedules_empty: 'Aucun résultat à l\'heure actuelle',
  nearby_stop_points: 'Arrêts à proximité',
  remove_nearby_stop_points: 'Voir tous les arrêts de la ligne',
  schedules_on_x_day: 'Horaires du %{day}',
  schedules_other: 'Voir un autre jour',
  schedules: {
    title: 'Horaires de la ligne',
    description: 'Accédez aux horaires et aux prochains passages de votre ligne',
    link: 'Voir les horaires',
    download: 'Télécharger les horaires en pdf',
    unavailable: 'Fiche horaire non disponible, '
    + 'merci de cliquer sur votre arrêt afin de connaître les horaires de passage à celui-ci',
    choose_stop: 'Choisir un arrêt',
  },
  plans: {
    title: 'Plan de la ligne',
    description: 'Accédez aux plans de votre ligne',
    link: 'Voir la ligne sur le plan',
    download: 'Télécharger le plan en pdf',
  },
  search: {
    line: 'Chercher une ligne',
    stop_point: 'Chercher un arrêt, une ligne',
    loading: 'Chargement en cours...',
    no_result: 'Pas de résultat',
    error: 'Une erreur est survenue',
    title: 'Horaires et prochains passages',
  },
  select_line: 'Afficher toutes les lignes',
  hide_line: 'Masquer toutes les lignes',
  no_datetimes: 'Aucun horaire n\'est disponible pour cette date',
  scodi_not_available: 'Horaires disponibles à partir du 4 Septembre, date de mise en service de ces lignes. Veuillez sélectionner la date recherchée depuis le calendrier “Voir un autre jour”',
  new_network_not_available: "Vous recherchez un horaire à partir du 4 septembre ? <a href='%{link}' target='_blank'>c'est ici</a>",
  a11y: {
    cancel: 'Réinitialiser le champs',
    scheduleSection: 'Block horaires et prochains passages',
    changeDirection: 'Changer la direction',
  },
  line_link: 'Plan & horaires',
  informations: 'Infos pratiques',
  frequency: 'Fréquence',
  amplitude_circulation: 'Amplitude de circulation',
  services: 'Services à bord',
  frequentation: 'Affluence à bord estimée',
  frequentationInfo: 'Afin d\'anticiper vos trajets et vous déplacer dans les meilleures conditions, '
      + 'nous vous proposons de consulter l\'affluence sur votre ligne.',
  frequentationInfoSoon: 'Prochainement, retrouvez ici l’affluence à bord estimée de votre ligne, heure par heure.',
  frequentationInfoHelp: 'Affluence estimée, sur une journée, du lundi au vendredi (hors vacances et jours fériés)',
  frequentation_details_base: 'Affluence sur la ligne',
  frequentation_details_morning: 'Heures de pointe du matin (6h30 - 9h)',
  frequentation_details_evening: 'Heures de pointe du soir (16h - 19h)',
  line_life: 'La vie de la ligne',
  line_life_description: 'Le trajet de cette ligne s\'adapte aux événements de la ville',
  lines: 'Voir les autres lignes',
  fav_item: 'cet arrêt',
};

export default line;
