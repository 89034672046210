const schedules = {
  HOME: {
    name: 'schedule-page',
    title: 'schedules.title',
    patterns: {
      fr: '/horaires',
      en: '/schedules',
      es: '/horarios',
    },
    page: '/schedules/search',
  },
  RESULTS: {
    name: 'schedule-results',
    title: 'schedules.title',
    patterns: {
      fr: '/horaires/recherche',
      en: '/schedules/search',
      es: '/horarios/buscar',
    },
    page: '/schedules/results',
  },
  LINE: {
    name: 'schedule-line',
    title: 'schedules.title',
    patterns: {
      fr: '/horaires/recherche/:line/:route',
      en: '/schedules/search/:line/:route',
      es: '/horarios/buscar/:line/:route',
    },
    page: '/schedules/line',
  },
  STOP_DETAILS: {
    name: 'schedule-stop-details',
    title: 'schedules.title',
    patterns: {
      fr: '/horaires/detail/:line/:route/:stopPoint',
      en: '/schedules/detail/:line/:route/:stopPoint',
      es: '/horarios/detalle/:line/:route/:stopPoint',
    },
    page: '/schedules/stop-details',
  },
};

module.exports = schedules;
