export const ROUTES_TYPES = {
  STOP_AREA: 'stop_area',
  ADDRESS: 'address',
  POI: 'poi',
  PLACE: 'administrative_region',
  PARKS: 'parks',
  VCUBS: 'vcub',
};

/**
 * !! Please do not change the order of keys, use to map with ROUTES_SECTION_MODES !!
 * @see mapNavitiaTransportWithSectionMode()
 */
export const NAVITIA_TRANSPORT_TYPES = {
  ALL: 'all',
  TRAM: 'commercial_mode:Tramway',
  FLEX_AREO: 'flex_aero',
  BUS: 'commercial_mode:Bus',
  BATCUB: 'commercial_mode:Ferry',
  VCUB: 'bss',
  CAR: 'car',
  WALK: 'walking',
  TRAIN_TER: 'commercial_mode:LocalTrain',
  AUTOCAR: 'commercial_mode:Coach',
};

export const TRANSPORT_MODE_DISPLAYABLE = {
  'commercial_mode:Tramway': 'tram',
  'commercial_mode:Bus': 'bus',
  'flex_aero': 'flex_aero',
  'commercial_mode:Ferry': 'batcub',
  'bss': 'vcub',
  'car': 'parking',
  'commercial_mode:Coach': 'autocar',
  'commercial_mode:LocalTrain': 'train',
}

export const TRANSPORT_MODE_SORT_ORDER = [
  'commercial_mode:Tramway',
  'flex_aero',
  'commercial_mode:Bus',
  'commercial_mode:Ferry',
  'bss',
  'car',
  'commercial_mode:Coach',
  'commercial_mode:LocalTrain',
];

export const DEFAULT_TRANSPORT_TYPES = [
  NAVITIA_TRANSPORT_TYPES.TRAM,
  NAVITIA_TRANSPORT_TYPES.FLEX_AREO,
  NAVITIA_TRANSPORT_TYPES.BUS,
  NAVITIA_TRANSPORT_TYPES.BATCUB,
  NAVITIA_TRANSPORT_TYPES.TRAIN_TER,
];

export const NON_ACCESSIBLE_TRANSPORT_TYPES = [
  NAVITIA_TRANSPORT_TYPES.VCUB,
];

/**
 * !! Please do not change the order of keys, use to map with NAVITIA_TRANSPORT_TYPES !!
 * @see mapNavitiaTransportWithSectionMode()
 */
export const SECTION_TRANSPORT_MODES = {
  ALL: 'all',
  TRAM: 'Tramway',
  BUS: 'Bus',
  BATCUB: 'Ferry',
  VCUB: 'bike',
  CAR: 'car',
  WALK: 'walking',
  TRAIN_TER: 'Train régional / TER',
  AUTOCAR: 'Autocar',
  PERSONAL_BIKE: 'personal_bike',
  STAY_IN: 'stay_in',
};

export const TABS_NAME = {
  TOUT: 'Onglet TOUT',
  'icon-tram_bus': 'Onglet Bus et Tram',
  'icon-vcub': 'Onglet LE VÉLO',
  'icon-batcub': 'Onglet BAT3',
  'icon-title-parking': 'Onglet Voiture',
  'icon-train': 'Onglet Train',
  'icon-autocar-circle': 'Onglet Autocar',
};

export const ROUTE_REPRESENT = {
  DEPARTURE: 'Départ',
  ARRIVAL: 'Arrivée',
};

export const ROUTE_TAGGING_ACTION = {
  LOCATED: 'Géolocalisé',
  LAST_SEARCH: 'Dernière recherche',
  FAVORITE: 'Favori',
  INPUT_ADDRESS: 'Adresse saisie',
  ADD_FAVORITE: 'Ajouter à mes favoris',
  EDIT_SEARCH: 'Modifier ma recherche',
};

export const ROADMAP_TAGGING_ACTION = {
  BACK_TO_RESULTS: 'Retour à la liste d’itinéraires',
  EARLIER: 'Plus tôt',
  LATER: 'Plus tard',
  PEDESTRIAN_DETAILS: 'Détail piéton',
  SHOW_ON_MAP: 'Voir sur la carte',
};

export const SECTION_ACTION_MODES = {
  VCUB_PUT_BACK: 'bss_put_back',
  CAR_PARK: 'park',
};

export const SECTION_OTHER_MODES = {
  TRANSFER: 'transfer',
};

export const SECTION_MODES = {
  ...SECTION_TRANSPORT_MODES,
  ...SECTION_ACTION_MODES,
  ...SECTION_OTHER_MODES,
};

export const NAVITIA_DATE_FORMAT = 'YYYYMMDD[T]HHmmss';

export const TAD_ROUTE = 'tad';

export const ISOLATED_ROUTE = 'isolated';

export const ISOLATED_ICONS = {
  walking: 'walking',
  personal_bike: 'personal-bike',
  bss: 'vcub'
};

export const ISOLATED_ICONS_WIDTH = {
  walking: 16,
  personal_bike: 32,
  bss: 32,
};

export const ISOLATED_SORT_ORDER = [
  'bss',
  'personal_bike',
  'walking',
];

export const ISOLATED_I18N = {
  walking: 'route.result.walk',
  personal_bike: 'route.result.personal_bike',
  bss: 'route.result.vcub',
};
