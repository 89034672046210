const custom = {
  TOURISTIC: {
    name: 'plan-touristic',
    title: 'plans.touristic',
    patterns: {
      fr: '/je-visite',
      en: '/je-visite',
      es: '/je-visite',
    },
    page: 'plan/touristic',
  },
};

module.exports = custom;
