const enterprise = {
  title: 'TBM partner companies and organisations',
  text: 'Do you have 10 or more employees aged 28+ working company a TBM partner company? '
  + 'Enjoy discounts of <b>20 to 30%</b> with the Pass Salarié annual season ticket!',
  text_2: 'Find out more about the Pass Salarié',
  search: {
    title: 'Find your company',
    type_to_search: 'Start typing to search',
    placeholder: 'Company name',
    placeholder_indication: '(3 characters min)',
    loading: 'Loading...',
    no_result: 'No results found',
    error: 'An error has occurred',
  },
  summary: {
    name: 'Name',
    address: 'Address',
    discount: 'Discount',
    contact: 'Contact',
  },
};

export default enterprise;
