import React from 'react';
import moment from 'moment';
import {
  ISOLATED_ROUTE,
  NAVITIA_DATE_FORMAT,
  NAVITIA_TRANSPORT_TYPES,
  SECTION_OTHER_MODES,
  SECTION_TRANSPORT_MODES, TAD_ROUTE,
} from '../config/routes';
import { arrayUnique } from './GenericHelper';

const LINES_WITH_PICTO = [
  SECTION_TRANSPORT_MODES.TRAM,
  SECTION_TRANSPORT_MODES.BUS,
  SECTION_TRANSPORT_MODES.BATCUB,
  SECTION_TRANSPORT_MODES.TRAIN_TER,
  SECTION_TRANSPORT_MODES.AUTOCAR,
];

/**
 * Return the transport type for a route section.
 * If the section isn't regarded as transport mode, return null.
 *
 * @param section
 *   Object section from api.
 * @returns {null|string}
 */
export const getSectionTransportMode = (section) => {
  if ('mode_display' in section) {
    return section.mode_display;
  }
  if ('type' in section) {
    if (section.type === 'crow_fly' && section.mode === 'bss') {
      return SECTION_TRANSPORT_MODES.WALK;
    }
  }
  if ('mode' in section) {
    return section.mode;
  }
  if ('display_informations' in section) {
    return section.display_informations.physical_mode;
  }
  return null;
};

/**
 * Return the section mode.
 *
 * @param section
 *   Object section from api.
 * @returns {string}
 */
export const getSectionMode = (section) => {
  let mode = getSectionTransportMode(section);

  if (!mode && 'type' in section) {
    mode = section.type;
  }

  if (section.transfer_type === SECTION_TRANSPORT_MODES.STAY_IN || mode === 'waiting') {
    mode = SECTION_TRANSPORT_MODES.STAY_IN;
  }

  return mode;
};

/**
 * Map NAVITIA_TRANSPORT_TYPES with SECTION_TRANSPORT_MODES.
 *
 * @param transport
 *   String from NAVITIA_TRANSPORT_TYPES or SECTION_TRANSPORT_MODES.
 * @returns {null|string}
 */
export const mapNavitiaTransportWithSectionMode = (transport) => {
  const navitiaTransportTypes = Object.values(NAVITIA_TRANSPORT_TYPES);
  const routesSectionModes = Object.values(SECTION_TRANSPORT_MODES);

  if (navitiaTransportTypes.includes(transport)) {
    const index = navitiaTransportTypes.indexOf(transport);
    return routesSectionModes[index];
  }

  if (routesSectionModes.includes(transport)) {
    const index = routesSectionModes.indexOf(transport);
    return navitiaTransportTypes[index];
  }

  return null;
};

/**
 * Format route params from routes store, for routes urls.
 *
 * @param routesStore
 *   Object from route reducer.
 * @param showTimeBanner
 *   Show time banner.
 * @param postRoadmapData
 * Post Roadmap data to React Native app
 */
export const formatRouteParams = (routesStore, showTimeBanner = false, postRoadmapData = false) => {
  const now = moment();
  let date = moment(routesStore.date);

  // Can't calcul route with a passed date.
  if (now.diff(date) > 0) {
    date = moment();
  }

  const routeObject = {};

  if (showTimeBanner) {
    Object.assign(routeObject, { showTimeBanner: true });
  }

  if (postRoadmapData) {
    Object.assign(routeObject, { postRoadmapData: true });
  }

  Object.assign(routeObject, {
    from_type: routesStore.departure.type,
    from: routesStore.departure.id,
    to_type: routesStore.arrival.type,
    to: routesStore.arrival.id,
    departure: routesStore.isDepartureDate,
    date: date.format(NAVITIA_DATE_FORMAT),
    transports: routesStore.transportModes.join(','),
    wheelchair: routesStore.withAccessibility,
  });

  return routeObject;
};

/**
 * Format seconds to "xx min" or "xxhxx" string.
 *
 * @param durationInSeconds
 * @returns string
 */
export const formatDuration = (durationInSeconds) => {
  const duration = moment.duration(durationInSeconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (hours > 0) {
    if (minutes < 10) {
      return `${hours}h0${minutes}`;
    }
    return `${hours}h${minutes}`;
  }

  if (minutes === 0) {
    return '1 min';
  }

  return `${minutes} min`;
};

/**
 * Get line picto from route section informations.
 *
 * @param section
 * @param isMobile
 */
export const getLinePicto = (section, isMobile = false) => {
  if (!('display_informations' in section)) {
    return null;
  }

  if (section.display_informations.picto) {
    return (
      <div className="tbm-icon tbm-icon-picto">
        <img src={section.display_informations.picto} alt={section.display_informations.code} />
      </div>
    );
  }

  const isTram = getSectionTransportMode(section) === SECTION_TRANSPORT_MODES.TRAM;
  const isTrain = getSectionTransportMode(section) === SECTION_TRANSPORT_MODES.TRAIN_TER;
  const isAutocar = getSectionTransportMode(section) === SECTION_TRANSPORT_MODES.AUTOCAR;

  let pictoSize = 'auto';

  if (section.display_informations.links.length > 0) {
    pictoSize = isMobile ? `${Math.round(25 / 1.25)}px` : '25px';
  }

  return (
    <div
      className={`tbm-icon ${isTram ? 'circle' : ''} ${isTrain ? 'train' : ''} ${isAutocar ? 'autocar' : ''}`}
      style={{
        backgroundColor: `#${section.display_informations.color}`,
        color: `#${section.display_informations.text_color}`,
        padding: `${section.display_informations.links.length > 0 ? '' : '0 4px'}`,
        width: pictoSize,
        height: pictoSize,
        lineHeight: `${isMobile ? Math.round(25 / 1.25) : 25}px`,
      }}
    >
      <span className={isMobile ? 'fs-sm-small' : ''}>{section.display_informations.code}</span>
    </div>
  );
};

/**
 * Get transport mode picto by rute section mode.
 *
 * @param section
 *   The route section.
 * @param withLinePicto
 *   For bus and tram, set the line picto if parameter is true.
 * @returns {boolean|*}
 */
export const renderLogo = (section, withLinePicto = true, isMobile = false, withWheelChair = false) => {
  const mode = getSectionMode(section);
  let transportIcon;
  let w;
  let h = null;

  switch (mode) {
    case SECTION_TRANSPORT_MODES.TRAM:
      transportIcon = 'icon-tram'; w = 18; h = 24; break;
    case SECTION_TRANSPORT_MODES.BUS:
      transportIcon = 'icon-bus'; w = 16; h = 21; break;
    case SECTION_TRANSPORT_MODES.BATCUB:
      transportIcon = 'icon-batcub'; w = 50; h = 21; break;
    case SECTION_TRANSPORT_MODES.VCUB:
      transportIcon = 'icon-vcub'; w = 31; h = 20; break;
    case SECTION_TRANSPORT_MODES.CAR:
      transportIcon = 'icon-car-face'; w = 21; h = 21; break;
    case SECTION_TRANSPORT_MODES.WALK:
    case SECTION_OTHER_MODES.TRANSFER:
      transportIcon = withWheelChair ? 'icon-phmr-move' : 'icon-walking';
      w = withWheelChair ? 22 : 13;
      h = 22; break;
    case SECTION_TRANSPORT_MODES.TRAIN_TER:
      transportIcon = 'icon-train'; w = 21; h = 21; break;
    case SECTION_TRANSPORT_MODES.AUTOCAR:
      transportIcon = 'icon-autocar'; w = 21; h = 21; break;
    case SECTION_TRANSPORT_MODES.PERSONAL_BIKE:
      transportIcon = 'icon-personal-bike'; w = 31; h = 30; break;
    case SECTION_TRANSPORT_MODES.STAY_IN:
      transportIcon = 'icon-horloge'; w = 21; h = 21; break;
    default:
      transportIcon = null; break;
  }

  if (!transportIcon) {
    return false;
  }

  return (
    <>
      {
        <svg
          width={isMobile ? Math.round(w / 1.25) : w}
          height={isMobile ? Math.round(h / 1.25) : h}
          className="f-grey"
        >
          <use xlinkHref={`#${transportIcon}`} />
        </svg>
      }
      { withLinePicto && LINES_WITH_PICTO.includes(mode) && getLinePicto(section, isMobile) }
    </>
  );
};

/**
 * Generate a unique ID for each route.
 *
 * @param index
 * @returns {`route-${string}`}
 */
export const getRouteId = index => (`route-${index}`);

/**
 * Set a 'modes' array property on each route, to resume all section modes.
 *
 * @param routes
 */
export const setRouteModes = routes => (
  routes.map((route) => {
    const modes = route.sections.map(section => getSectionTransportMode(section));

    route.modes = arrayUnique(
      modes.filter(mode => mode !== null),
    );

    if (route.mode && route.mode === 'bss') {
      route.modes = ['bss'];
    }

    if (route.modes.length === 1) {
      if (route.modes[0] !== 'personal_bike') {
        // eslint-disable-next-line prefer-destructuring
        route.mode = route.modes[0];
        route.mode_display = route.modes[0];
      } else {
        // eslint-disable-next-line prefer-destructuring
        route.mode_display = route.modes[0];
      }
    }

    return route;
  })
);

/**
 * Merge all isolated routes on unique array.
 *
 * @param results
 * @param withAccessibility
 *
 * @returns {*[]}
 */
export const flatIsolatedRoutes = (results, withAccessibility = false) => {
  let isolatedRoutes = [];

  if ('isolated' in results) {
    Object.keys(results.isolated).forEach((type) => {
      const journeys = results.isolated[type].map(journey => ({
        ...journey,
        type: ISOLATED_ROUTE,
      }));

      if (withAccessibility && type === 'walking') {
        // No nothing
      } else {
        isolatedRoutes = [
          ...isolatedRoutes,
          ...journeys,
        ];
      }
    });
  }

  return isolatedRoutes;
};

export const filterIsolatedRoutes = routes => routes.filter((route) => {
  const duration = moment.duration(route.duration, 'seconds');
  const minutes = duration.minutes();
  switch (route.mode) {
    case 'walking':
      return minutes <= 30;
    case 'personal_bike':
      return minutes <= 90;
    default:
      return minutes <= 60;
  }
});

export const flatTadRoutes = (results) => {
  let tadRoutes = [];

  if ('tad' in results) {
    const journeys = results.tad.map(journey => ({
      ...journey,
      type: TAD_ROUTE,
    }));

    tadRoutes = [
      ...tadRoutes,
      ...journeys,
    ];
  }

  return tadRoutes;
};
