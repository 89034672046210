import {
  CHANGE_PREDICT_TIME,
  FETCH_ALL_STATIONS,
  FETCH_PREDICTIONS,
  FETCH_STATION_PREDICTIONS,
  SET_STATION,
} from '../actions/VcubActions';

const initialState = {
  stations: [],
  stationPredictions: [],
  predictions: [],
  predictionTime: 0,
  station: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_STATIONS:
      return {
        ...state,
        stations: action.payload,
      };
    case FETCH_STATION_PREDICTIONS:
      return {
        ...state,
        stationPredictions: action.payload,
      };
    case FETCH_PREDICTIONS:
      return {
        ...state,
        // stations: state.stations.map(station => ({
        //   ...station,
        //   predictions: {
        //     ...station.predictions,
        //     [action.payload.time]: action.payload.predictions.find(p => p.sid === station.id),
        //   },
        // })),
        predictionTime: action.payload.time,
        predictions: action.payload.predictions,
      };
    case CHANGE_PREDICT_TIME:
      return {
        ...state,
        predictionTime: action.payload,
      };
    case SET_STATION:
      return {
        ...state,
        station: action.payload,
      };
    default:
      return state;
  }
};
