import React from 'react';

const Loader = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
  }}
  >
    <i className="icon icon-loader" />
  </div>
);

export default Loader;
