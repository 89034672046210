const mode = {
  all: 'Todo',
  bustram: 'Tram & Bus',
  vcub: 'LE VÉLO',
  batcub: 'BAT3',
  parc: 'Parcs-relais',
  plan: 'Plano dinámico',
};

export default mode;
