import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import {
  CITIES_URL, POI_URL, STOP_AREAS_URL,
} from '../config/config';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const SET_SECTION = 'SET_SECTION';
export const SET_CITIES = 'SET_CITIES';
export const SET_POIS = 'SET_POIS';
export const SET_POIS_ERROR = 'SET_POIS_ERROR';
export const SET_STOP_AREAS = 'SET_STOP_AREAS';

export const fetchCities = () => async (dispatch, getState) => {
  try {
    if (getState().plans.cities.length > 0) {
      return false;
    }

    const citiesQuery = await fetchWithTimeout(CITIES_URL);
    const cities = await citiesQuery.json();

    dispatch({ type: SET_CITIES, payload: cities });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`${Translate('error.cities')} : ${e}`);
  }
};

export const fetchStopAreas = () => async (dispatch, getState) => {
  try {
    if (getState().plans.stopareas.length > 0) {
      return false;
    }

    const stopAreasQuery = await fetchWithTimeout(STOP_AREAS_URL);
    const stopAreas = await stopAreasQuery.json();

    dispatch({ type: SET_STOP_AREAS, payload: stopAreas });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des stop areas : ${e}`);
  }
};

export const fetchPois = () => async (dispatch, getState) => {
  try {
    if (getState().plans.pois.length > 0) {
      return true;
    }

    const poisQuery = await fetchWithTimeout(POI_URL);
    const pois = await poisQuery.json();

    dispatch({ type: SET_POIS, payload: pois });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`${Translate('error.pois')} : ${e}`);
    dispatch({ type: SET_POIS_ERROR, payload: Translate('error.pois') });
  }
};
