const parking = {
  title: 'Aparcamientos disuasorios',
  meta_description: 'Encuentre aquí toda la información relacionada con los parques de retransmisión de la red TBM '
  + '(disponibilidad en tiempo real, mapas, información práctica ...).',
  the_parks: 'Los aparcamientos disuasorios',
  description: {
    location: 'Ubicado cerca de las líneas de tranvía y autobús,',
    number: 'los 28 estacionamientos o "parques de relevos" de la red TBM',
    explanation: 'le permite combinar fácilmente el automóvil y el transporte público.',
  },
  search: {
    title: 'Buscar',
    type_to_search: 'Escriba para buscar',
    placeholder: 'Buscar un aparcamiento disuasorio',
    example: '(nombre o ciudad)',
    loading: 'Cargando...',
    no_result: 'No hay resultados',
    find_nearby: 'Trouver un parc-relais près de moi',
  },
  select_line: 'Mostrar todos los aparcamientos disuasorios',
  hide_line: 'Ocultar todos los aparcamientos disuasorios',
  no_found: 'No se han encontrado aparcamientos disuasorios',
  capacity: 'Capacidad',
  address: 'Dirección',
  vlSpace_singular: 'Lugar',
  vlSpace_plural: 'Lugares',
  pmrSpace_singular: 'PMR',
  pmrSpace_plural: 'PMR',
  freeVlec_singular: 'Eléctrico',
  freeVlec_plural: 'Eléctricos',
  last_updated_at: 'Actualizado el',
  updated_today_at: 'Actualizado hoy a las',
  at: 'a',
  now: 'En este momento',
  notAvailable: 'Este "Park and ride" no está disponible en este momento.',
  no_realtime: 'No hay información en tiempo real para este parque de atracciones.',
  plan_link: 'Ver el parque sobre el plan',
  parkSelectedPlanLink: 'Ver el parque sobre el plan',
  show_all: 'Ver todos los parques de relevos',
  tram_connection: 'El relé se estaciona en conexión con el tranvía',
  parks_served_by_tram: 'Los parques de relevos servidos por el tranvía',
  route: 'Encuentra un itinerario',
  go_to: 'Ir',
  go_from: 'De',
  availability: 'Disponibilidad',
  real_time: 'En este momento',
  predict: 'Próximamente',
  predict_soon: 'Información predictiva disponible pronto',
  how_to: 'Aparcamientos disuasorios : cómo funciona? ',
  click_indication: 'Haga clic en un parque de relevos para acceder a su disponibilidad.',
  plan_title: 'Plan de relevos del parque',
  informations: {
    title: 'Informacion practica',
    schedules: {
      title: 'Los horarios',
      description: 'Los parques de retransmisión TBM están abiertos en la misma escala de tiempo que la red :',
      normal: 'Lunes, martes, miércoles, domingos y festivos: de 5 a.m. a 1 a.m.',
      party: 'Jueves, viernes y sábados: de 5 a.m. a 2 a.m.',
    },
    how: {
      title: 'Como funciona ?',
      come_in: 'Ingrese un P + R',
      go_out: 'Sal del estacionamiento',
    },
    prices: {
      title: 'Tarifas de los relés del parque',
      occasional: 'En ocasiones: boleto 24h, boleto 7 días',
      adhere: 'Únase al servicio: al menos, durante todo el año.',
    },
    electric: {
      title_search: 'Aparcamientos de intercambio equipados con estaciones de carga para vehículos eléctricos',
      title_detail: 'Estaciones de caraga eléctrica',
      available: 'plaza disponible',
      available_plural: 'plazas disponibles',
    },
  },
  favorites: 'Mis parques de relevos favoritos',
  fav_item: 'la plaza',
  closed: 'Parque de relevos cerrado.',
};

export default parking;
