const map = {
  HOME: {
    name: 'map-page',
    title: 'plans.title',
    patterns: {
      fr: '/plans/:entity/:entityId?',
      en: '/plans/:entity/:entityId?',
      es: '/planes/:entity/:entityId?',
    },
    page: 'map/fullmap',
  },
};

module.exports = map;
