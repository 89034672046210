import getRouteName from './RouteLangHelper';
import { Router } from '../router';
import * as allRoutes from '../routes';

export const pushRoute = (routeName, lang, params) => {
  const route = getRouteName(routeName, lang);
  Router.pushRoute(route, { ...params, lang });
};

export const pushShallowRoute = (routeName, lang, params) => {
  const route = getRouteName(routeName, lang);
  Router.pushRoute(route, { ...params, lang }, { shallow: true });
};

export const getRouteNameByPage = (page) => {
  let routeName = null;

  Object.values(allRoutes).forEach((routeCategory) => {
    Object.values(routeCategory).forEach((route) => {
      if (route.page === page || `/${route.page}` === page) {
        routeName = route.name;
      }
    });
  });

  return routeName;
};

export const getRouteTitleByPage = (page) => {
  let routeTitle = '';

  Object.values(allRoutes).forEach((routeCategory) => {
    Object.values(routeCategory).forEach((route) => {
      if (route.page === page || `/${route.page}` === page) {
        routeTitle = route.title;
      }
    });
  });

  return routeTitle;
};
