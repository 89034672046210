import {
  ALERT_ARCHIVED,
  ALERT_DETAIL,
  ALERT_ERROR,
  ALERT_LINE,
  BATCUB_TRAFFIC,
  BUS_TRAFFIC,
  FAVORITE_TRAFFIC,
  HIGH_IMPACT_TRAFFIC_IMPACT,
  LAST_SYNC_TRAFFIC_INFO,
  PARKING_TRAFFIC,
  PREVIEW_TRAFFIC_INFO,
  SET_POPIN_ALERTS,
  SET_POPIN_ALERTS_V2,
  TRAIN_TRAFFIC,
  AUTOCAR_TRAFFIC,
  TRAM_TRAFFIC,
  VCUB_TRAFFIC,
  OTHER_TRAFFIC,
  SCODI_TRAFFIC,
} from '../actions/TrafficInfoActions';

const initialState = {
  trams: [],
  bus: [],
  busImpactedLines: [],
  scodi: [],
  scodiImpactedLines: [],
  vcub: [],
  batcub: [],
  parking: [],
  trains: [],
  autocars: [],
  other_traffic: [],
  favorites: [],
  inProgress: [],
  future: [],
  banners: [],
  alert: null,
  alertError: false,
  alertArchived: false,
  line: [],
  lastSync: null,
  popinAlerts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LAST_SYNC_TRAFFIC_INFO:
      return {
        ...state,
        lastSync: action.payload,
      };
    case PREVIEW_TRAFFIC_INFO:
      return {
        ...state,
        trams: action.payload.trams,
        inProgress: action.payload.inProgress,
        future: action.payload.future,
      };
    case FAVORITE_TRAFFIC:
      return {
        ...state,
        favorites: action.payload,
      };
    case HIGH_IMPACT_TRAFFIC_IMPACT:
      return {
        ...state,
        banners: action.payload,
      };
    case TRAM_TRAFFIC:
      return {
        ...state,
        trams: action.payload,
      };
    case BUS_TRAFFIC:
      return {
        ...state,
        bus: action.payload.bus,
        busImpactedLines: action.payload.busImpactedLines,
      };
    case VCUB_TRAFFIC:
      return {
        ...state,
        vcub: action.payload,
      };
    case SCODI_TRAFFIC:
      return {
        ...state,
        scodi: action.payload.scodi,
        scodiImpactedLines: action.payload.scodiImpactedLines,
      };
    case BATCUB_TRAFFIC:
      return {
        ...state,
        batcub: action.payload,
      };
    case PARKING_TRAFFIC:
      return {
        ...state,
        parking: action.payload,
      };
    case TRAIN_TRAFFIC:
      return {
        ...state,
        trains: action.payload,
      };
    case AUTOCAR_TRAFFIC:
      return {
        ...state,
        autocars: action.payload,
      };
    case OTHER_TRAFFIC:
      return {
        ...state,
        other_traffic: action.payload,
      };
    case ALERT_DETAIL:
      return {
        ...state,
        alert: action.payload,
        alertError: false,
        alertArchived: false,
      };
    case ALERT_ERROR:
      return {
        ...state,
        alert: null,
        alertError: true,
      };
    case ALERT_ARCHIVED:
      return {
        ...state,
        alert: null,
        alertArchived: true,
      };
    case ALERT_LINE:
      return {
        ...state,
        line: action.payload,
      };
    case SET_POPIN_ALERTS:
      return {
        ...state,
        popinAlerts: action.payload,
      };
    case SET_POPIN_ALERTS_V2:
      return {
        ...state,
        popinAlerts: action.payload.alerts,
        line: action.payload.line,
      };
    default:
      return state;
  }
};
