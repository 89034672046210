const vcub = {
  title: 'LE VÉLO',
  title_home: 'LE VÉLO',
  title_list: 'LE VÉLO Service',
  meta_description_list: 'Find here all the information related to the LE VÉLO service '
  + '(stations, availability in real time, maps, practical information ...).',
  meta_description: 'The availability of the station %{name} in Bordeaux Métropole: '
  + 'Bikes and places available in real time and predictive of the station %{name}',
  description: 'The self-service bike of Bordeaux: the LE VÉLO.',
  description_number: 'The self-service bike of Bordeaux: the LE VÉLO. '
  + 'The network TBM counts 186 stations and more than 2000 Bicycles of which 1000 electric',
  availability: 'Availability',
  switch: {
    title: 'Availability:',
    bike: 'Bicycles',
    spots: 'Spaces',
  },
  predict: {
    title: 'To come up',
    description: 'Forecast availability in ',
    times: {
      now: 'Now',
      15: 'Forecast in 15 min',
      30: 'Forecast in 30 min',
      60: 'Forecast in 1h',
      120: 'Forecast in 2h',
      180: 'Forecast in 3h',
      360: 'Forecast in 6h',
      720: 'Forecast in 12h',
    },
    in: 'In',
  },
  markerInfo: {
    now: 'Now',
    bike: 'bicycle',
    bikes: 'bicycles',
    elec_bike: 'Electric bike',
    elec_bikes: 'Electric bikes',
    spot: 'space',
    spots: 'spaces',
    notAvailable: 'This station is currently unavailable.',
    updatedAt: 'Updated at ',
  },
  no_realtime: 'Information temporarily unavailable.',
  search: {
    title: 'Search',
    placeholder: 'Find a LE VÉLO station',
    station: 'Find a LE VÉLO station',
    example: '(name or station number)',
    loading: 'Loading...',
    no_found: 'No LE VÉLO stations found',
    no_result: 'No result found',
    find_nearby: 'Find a Vcub Station nearby',
  },
  last_updated_at: 'Last updated on',
  updated_today_at: 'Last updated today at',
  at: 'at',
  bikes_singular: 'Bicycle',
  bikes_plural: 'Bicycles',
  places_singular: 'Space',
  places_plural: 'Spaces',
  maintenance: 'Station currently closed',
  show_all: 'Display all LE VÉLO stations',
  hide_line: 'Hide all  LE VÉLO stations',
  nearby_title: ' LE VÉLO stations',
  station: ' LE VÉLO station',
  plan_link: 'See the map of LE VÉLO stations',
  plan_station_link: 'See the station on the map',
  most_requested: 'most requested stations',
  route: 'Find an itinerary',
  plan_title: 'Map of LE VÉLO stations',
  how_to: 'LE VÉLO: How does it work?',
  subscribe: 'Subscribe to the Le Vélo service',
  informations: {
    title: 'Practical informations',
    how: {
      title: 'How does it works ?',
      get_a_bike: 'Get a bike',
      drop_a_bike: 'Drop a  bike',
      vcub: 'LE VÉLO',
      elec_vcub: 'LE VÉLO electric',
      more: 'Learn more',
    },
    service: {
      title: 'LE VÉLO prices',
      occasional: 'Subscription ticket 24 hours, ticket 7 days, 1 month or 1 year',
    },
    app: {
      title: 'LE VÉLO on your mobile',
      vcub: 'Discover the LE VÉLO apps',
    },
    park: {
      title: 'Bike Parking',
      bikes: 'Bike shelters',
      train_station: 'Saint Jean bike parks',
      pr: 'Park your bike in a park',
    },
  },
  favourites: 'My favorite stations',
  fav_item: 'this station LE VÉLO',
};

export default vcub;
