import { checkIsWebview } from '../../helpers/GenericHelper';

export const taggingEventTraffic = (tbmClickItem) => {
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_click_item: tbmClickItem,
      };
      window.dataLayer.push({
        event: 'tbm_click_home_traffic_info',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  }

  return null;
};

export const taggingTrafficBannerClick = (bannerContent) => {
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_click_item: bannerContent.substring(0, 99),
      };
      window.dataLayer.push({
        event: 'tbm_click_alert_banner',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  }

  return null;
};
