const enterprise = {
  title: 'Empresas y establecimientos asociados de TBM',
  text: '¿Son 10 empleados de una empresa asociada de TBM y tienen 28 años o más? '
  + '¡Disfrute de un <b>descuento del 20 % al 30 %</b> con el abono anual Pass Salarié!',
  text_2: 'Más información sobre el Pass Salarié (empleado)',
  search: {
    title: 'Busque su empresa',
    type_to_search: 'Escriba para buscar',
    placeholder: 'Nombre de la empresa...',
    placeholder_indication: '(3 caracteres min\n)',
    loading: 'Cargando...',
    no_result: 'No hay resultados',
    error: 'Ha ocurrido un error',
  },
  summary: {
    name: 'Nombre',
    address: 'Dirección',
    discount: 'Descuento',
    contact: 'Contacto',
  },
};

export default enterprise;
