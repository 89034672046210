import moment from 'moment';
import {
  CALCULATE_ROUTE,
  REVERSE_ROUTE_DIRECTIONS,
  ROUTE_LOADING,
  SET_ACTIVE_ROUTE,
  SET_ERROR_FETCH,
  SET_ERROR_MESSAGE,
  SET_ERROR_TRANSPORT_MODES,
  SET_IS_DEPARTURE_DATE,
  SET_NAVITIA_DEBUG_URL, SET_POPIN_ACCESSIBILITY,
  SET_ROUTE_ARRIVAL,
  SET_ROUTE_DATE,
  SET_ROUTE_DEPARTURE,
  SET_ROUTE_MARKERS,
  SET_ROUTE_PATHS, SET_SHOW_TIME_BANNER, POST_ROADMAP_DATA,
  SET_TAGGING_ARRIVAL_POINT, SET_TAGGING_PREFERENCES_OPENED,
  SET_TAGGING_STARTING_POINT, SET_WITH_ACCESSIBILITY,
  SWITCH_ROUTE_TRANSPORTS_MODES,
  SYNC_ROUTES_STORE, SET_TICKET_PRICE, SET_GOOD_TO_KNOW_BLOCK,
} from '../actions/RouteActions';
import { DEFAULT_TRANSPORT_TYPES } from '../config/routes';

const initialState = {
  departure: null,
  arrival: null,
  isDepartureDate: true,
  date: moment(),
  transportModes: DEFAULT_TRANSPORT_TYPES,
  withAccessibility: false,
  popinAccessibility: false,

  routes: [],
  paths: [],
  markers: [],
  activeRoute: 0,

  loading: false,
  message: null,
  transportModesError: null,
  fetchError: null,
  ticketPrice: null,
  goodToKnowBlock: null,

  navitiaDebugUrl: null,
  showTimeBanner: false,
  postRoadmapData: false,

  taggingStartingPoint: '',
  taggingArrivalPoint: '',
  taggingPreferencesOpened: 0,
};

const RouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE_DEPARTURE:
      return {
        ...state,
        departure: action.payload,
      };
    case SET_ROUTE_ARRIVAL:
      return {
        ...state,
        arrival: action.payload,
      };
    case REVERSE_ROUTE_DIRECTIONS:
      return {
        ...state,
        departure: action.payload.arrival,
        arrival: action.payload.departure,
      };
    case SET_IS_DEPARTURE_DATE:
      return {
        ...state,
        isDepartureDate: action.payload,
      };
    case SET_ROUTE_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case SWITCH_ROUTE_TRANSPORTS_MODES:
      return {
        ...state,
        transportModes: action.payload,
      };
    case SET_WITH_ACCESSIBILITY:
      return {
        ...state,
        ...action.payload,
      };
    case SET_POPIN_ACCESSIBILITY:
      return {
        ...state,
        popinAccessibility: action.payload,
      };

    case SYNC_ROUTES_STORE:
      return {
        ...state,
        ...action.payload,
      };
    case CALCULATE_ROUTE:
      return {
        ...state,
        routes: action.payload,
      };
    case SET_ROUTE_PATHS:
      return {
        ...state,
        paths: action.payload,
      };
    case SET_ROUTE_MARKERS:
      return {
        ...state,
        markers: action.payload,
      };
    case ROUTE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ACTIVE_ROUTE:
      return {
        ...state,
        activeRoute: action.payload,
      };

    case SET_ERROR_TRANSPORT_MODES:
      return {
        ...state,
        transportModesError: action.payload,
      };
    case SET_ERROR_FETCH:
      return {
        ...state,
        fetchError: action.payload,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case SET_NAVITIA_DEBUG_URL:
      return {
        ...state,
        navitiaDebugUrl: action.payload,
      };
    case SET_TAGGING_STARTING_POINT:
      return {
        ...state,
        taggingStartingPoint: action.payload,
      };
    case SET_TAGGING_ARRIVAL_POINT:
      return {
        ...state,
        taggingArrivalPoint: action.payload,
      };
    case SET_TAGGING_PREFERENCES_OPENED:
      return {
        ...state,
        taggingPreferencesOpened: action.payload,
      };
    case SET_SHOW_TIME_BANNER:
      return {
        ...state,
        showTimeBanner: action.payload,
      };
    case POST_ROADMAP_DATA:
      return {
        ...state,
        postRoadmapData: action.payload,
      };
    case SET_TICKET_PRICE:
      return {
        ...state,
        ticketPrice: action.payload,
      };
    case SET_GOOD_TO_KNOW_BLOCK:
      return {
        ...state,
        goodToKnowBlock: action.payload,
      };
    default:
      return state;
  }
};

export default RouteReducer;
