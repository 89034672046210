const app = {
  home_title: 'Transports Bordeaux Métropole - TBM',
  meta_desc: 'Make your travels easier within Bordeaux Métropole. '
  + 'Find your next passages, routes, price info, LE VÉLO availability and relay parks ...',
  homepage: 'Home',
  errors: {
    button: 'Reload the page.',
    default: 'An error has occurred.',
    nearby_line: 'An error has occurred.',
    route: 'An error has occurred.',
    line: 'An error has occurred.',
    parking: 'An error has occurred.',
    vcub: 'An error has occurred.',
    stop_area: 'An error has occurred.',
    stop_point: 'An error has occurred.',
  },
  a11y: {
    title: 'Accessibility',
    back: 'Back',
  },
  links: {
    transport_ticket: 'Transport ticket',
    mobibus_accessibility: 'Mobibus and accessibility',
  },
  copyright: 'Keolis Bordeaux Métropole - All rights reserved - 2022',
};

export default app;
