const lines = {
  title: 'Lignes TBM',
  title_list: 'Toutes les lignes',
  meta_description: 'Découvrez ici toutes les lignes du réseau TBM. '
  + 'Cliquez sur une ligne pour accéder à ses informations (fiche horaire, plans...).',
  lines: 'Lignes TBM',
  description: 'Cliquez sur la ligne de votre choix pour accéder à toutes ses informations pratiques : '
  + 'fiche horaire, plan, amplitude de circulation, perturbations, etc...',
  plans: 'Voir les lignes sur le plan',
  tips: {
    label: 'Bon à savoir',
    text: 'Vous avez la possibilité d’imprimer votre fiche horaire. '
    + 'Les fiches horaires sont également disponibles dans nos ',
    text_link: '4 agences commerciales',
  },
  informations: {
    title: 'Aide et conseils',
  },
  dynamic_indication: 'Cliquer sur une ligne pour la faire apparaître',
  how_to: 'Tout savoir sur les lignes de Bus TBM',
};

export default lines;
