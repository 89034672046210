import { checkIsWebview } from '../../helpers/GenericHelper';

export const taggingEventAlert = (tbmClickAlertItem) => {
  if (!checkIsWebview()) {
    return null;
  }

  try {
    window.ReactNativeWebView.postMessage(
      JSON.stringify(
        {
          eventName: 'tbm_click_alert_info_traffic',
          eventParams: {
            tbm_click_item: 'Accès alerte',
            tbm_click_alert_item: tbmClickAlertItem,
            is_webview: 0,
          },
        },
      ),
    );
  } catch (e) {
    // We're not in a webview loaded by the app. Do nothing
  }

  return null;
};
