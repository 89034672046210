import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link as RouteLink } from '../../router';
import getRouteName from '../../helpers/RouteLangHelper';

const Link = (props) => {
  const {
    route, params, children, passHref,
  } = props;
  const lang = useSelector(state => state.i18n.locale);
  const routeName = getRouteName(route, lang);

  return (
    <RouteLink
      route={routeName}
      params={{ lang, ...params }}
      passHref={passHref}
    >
      { children }
    </RouteLink>
  );
};

Link.propTypes = {
  route: PropTypes.string.isRequired,
  params: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  children: PropTypes.element.isRequired,
  passHref: PropTypes.bool,
  lang: PropTypes.string,
};

Link.defaultProps = {
  params: {},
  lang: 'fr',
  passHref: false,
};

const mapStateToProps = state => ({
  lang: state.i18n.locale,
});

export default connect(mapStateToProps, null)(Link);
