const cities = {
  title: 'Communes',
  show_all: 'Afficher toutes les communes',
  hide_all: 'Masquer toutes les communes',
  not_found: 'Commune non trouvée',
  transport_title: 'Offres de transport à',
  search: {
    placeholder: 'Chercher une commune',
    no_found: 'Aucune commune trouvée',
  },
};

export default cities;
