const vcub = {
  HOME: {
    name: 'vcub-page',
    title: 'vcub.title',
    patterns: {
      fr: '/v3',
      en: '/v3',
      es: '/v3',
    },
    page: 'vcub/search',
  },
  DETAILS: {
    name: 'vcub-details',
    title: null,
    patterns: {
      fr: '/v3/:station',
      en: '/v3/:station',
      es: '/v3/:station',
    },
    page: 'vcub/details',
  },
};

module.exports = vcub;
