const schedules = {
  title: 'Horaires',
  section_title: 'Horaires',
  title_search: 'Horaires et prochains passages',
  meta_description: 'Retrouvez ici toutes les horaires et prochains passages des lignes du réseau TBM.',
  description: 'Accédez en quelques clics aux horaires et aux prochains passages de vos lignes.',
  search: {
    title: 'Rechercher',
    placeholder: 'Chercher un arrêt, une ligne',
    stop_point_placeholder: 'Chercher un arrêt',
    list: 'Voir toutes les lignes',
    error: 'Une erreur est survenue',
    no_result: 'Pas de résultat',
    select_direction: 'Sélectionnez une direction',
    select_line_stop: 'Sélectionnez une ligne et une direction',
    select_direction_stop: '<strong>Sélectionner</strong> une <strong>direction</strong> '
    + 'et un <strong>arrêt</strong> pour connaître l’<strong>heure de passage</strong> à cet arrêt :',
  },
  schedules_sheets: {
    title: 'Fiches horaires à télécharger',
    description: 'Sélectionnez la ligne de votre choix pour accéder à sa fiche horaire et aux informations pratiques',
  },
  places: {
    title: 'Destinations les plus courantes',
    othertitle: 'Lieux les plus demandés',
    indication: 'Cliquez sur une ligne pour connaître ses prochains passages',
  },
  help: {
    title: 'Aide et conseils',
    plans: 'Plans',
    partial_stop: 'Terminus partiel',
    plans_description: 'Téléchargez les plans des lignes que vous empruntez',
    schedules: 'Découvrez où trouver et télécharger la fiche horaire de votre ligne',
    schedules_description: 'Découvrez où trouver et télécharger la fiche horaire de votre ligne',
  },
  direction: 'Direction',
  destination: 'Destination',
  next_passages: 'Prochains passages',
  near: 'Proche',
  at: 'à',
  see_another_day: 'Voir un autre jour',
  schedule_indication: 'Cliquez sur un horaire pour connaître l\'heure d\'arrivée à votre arrêt de descente',
  find_bus: 'Où est mon bus ?',
  find_batcub: 'Où est mon BAT3 ?',
  find_tram: 'Où est mon tram ?',
  find_train: 'Où est mon train ?',
  find_autocar: 'Où est mon car régional ?',
  favorites: 'Mes arrêts favoris',
  print: 'Imprimer les horaires',
};

export default schedules;
