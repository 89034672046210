import PropTypes from 'prop-types';
import NextDestinationPass from "../elements/schedules/NextDestinationPass";

const Footer = (props) => {
  const { footerContent } = props;
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <footer>
      {/* eslint-disable-next-line react/react-in-jsx-scope,react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: footerContent }} />
    </footer>
  );
};

Footer.propTypes = {
  footerContent: PropTypes.string,
};

Footer.defaultProps = {
  footerContent: '',
};

export default Footer;
