import {
  SET_FAVORITE_BY_TYPE,
  SET_ALL_FAVORITES,
  SET_CONNECTED,
  SET_ERROR,
  SET_LOADING,
  SET_MESSSAGE, SET_TMP_FAVORITE,
} from '../actions/FavoritesActions';

const initialState = {
  tmpFavorite: null,
  routes: [],
  parks: [],
  stops: [],
  places: [],
  vcubs: [],
  alert_lines: [],
  isConnected: false,
  message: '',
  error: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MESSSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_CONNECTED:
      return {
        ...state,
        isConnected: action.payload,
      };
    case SET_ALL_FAVORITES:
      return {
        ...state,
        loading: false,
        routes: action.payload?.routes || state?.routes,
        parks: action.payload?.parks || state?.parks,
        stops: action.payload?.stops || state?.stops,
        places: action.payload?.places || state?.places,
        vcubs: action.payload?.vcubs || state?.vcubs,
        alert_lines: action.payload?.alert_lines || state?.alert_lines,
      };
    case SET_FAVORITE_BY_TYPE:
      return {
        ...state,
        loading: false,
        [action.payload.type]: action.payload.data,
      };
    case SET_TMP_FAVORITE:
      return {
        ...state,
        tmpFavorite: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
