const homepage = {
  trip: {
    schedule: {
      title: 'Horarios',
    },
  },
  discover_app: {
    title: 'Descubra la aplicación TBM',
    subtitle: 'Su compañero de movilidad para simplificar sus desplazamientos diarios',
  },
};

export default homepage;
