const routes = require('./routes');
const schedules = require('./schedules');
const traffic = require('./traffic');
const vcub = require('./vcub');
const parking = require('./parking');
const line = require('./line');
const plan = require('./plan');
const map = require('./map');
const info = require('./info');
const custom = require('./custom');

const index = {
  HOMEPAGE: {
    HOME: {
      name: 'homepage',
      title: 'app.homepage',
      patterns: {
        fr: '',
        en: '',
        es: '',
      },
      page: '/',
    },
  },
  ROUTE: routes,
  SCHEDULE: schedules,
  TRAFFIC: traffic,
  VCUB: vcub,
  PARKING: parking,
  LINE: line,
  PLAN: plan,
  MAP: map,
  INFO: info,
  CUSTOM: custom,
};

module.exports = index;
