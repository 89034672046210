const info = {
  DETAILS: {
    name: 'info-page',
    title: 'info.title',
    patterns: {
      fr: '/information/:id',
      en: '/information/:id',
      es: '/informacion/:id',
    },
    page: 'info/details',
  },
};

module.exports = info;
