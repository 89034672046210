const plan = {
  title: 'El mapa dinámico',
  title_mobile: 'El mapa dinámico :',
  text_1: 'Para visualizar una línea o una parada <br />',
  text_2: 'Para identificar todas las estaciones <br />de bicicleta de autoservicio (LE VÉLO) y aparcamientos disuasorios y las disponibilidades respectivas. <br />',
  text_3: 'Para descubrir todas las líneas de transporte y los servicios disponibles en torno a una dirección, una ciudad o un punto de interés',
  show: 'Ver mapa',
};

export default plan;
