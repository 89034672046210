const traffic = {
  title: 'Información del tráfico',
  title_mobile: 'Condiciones de tráfico en %{time} :',
  meta_description: 'Encuentre toda la información de tráfico de las líneas de tranvía, '
  + 'autobús y BAT3 de la red TBM aquí.',
  detail: 'Detalle',
  home: 'Para viajar con tranquilidad, consulte la información de tráfico de la red y los detalles de su línea.',
  no_disruption_on_this_line: 'No hay interrupción en esta línea en la actualidad',
  labels: {
    in_progress: 'En curso',
    future: 'Para venir',
    favorites: 'Mis líneas en alerta',
    sticky: 'En las noticias',
    all_informations: 'Toda la información de tráfico de la red',
  },
  types: {
    tram: 'Tram',
    bus: 'Bus',
    scodi: 'SCoDi',
    vcub: 'LE VÉLO',
    batcub: 'Bat3',
    parking: 'P+R',
    train: 'Tren',
    autocar: 'Autocar',
  },
  informations: {
    line: 'Disturbios en las siguientes líneas :',
    vcub: 'Información sobre las siguientes estaciones :',
    batcub: 'Disturbios en el BAT3 :',
    parking: 'Disturbios en el siguiente parque de relevo :',
  },
  impacted_line: 'Linea interesada',
  impacted_lines: 'Líneas relevantes',
  impacted_stations: 'Estaciones impactadas',
  impacted_pr: 'P+R impactados',
  link: 'Ver el estado del tráfico',
  line_title: 'Perturbaciones en la línea : ',
  perturbation_line: 'Perturbaciones en la línea',
  pr_title: 'Perturbaciones en esta aparcamiento',
  pr_working: 'Todos los parques de relevos funcionan normalmente',
  vcub_title: 'Informacións de esta estación LE VÉLO',
  working: 'La red funciona normalmente',
  twitter: 'Siga toda la información del tráfico en X (ex-twitter)',
  more: 'más información de tráfico',
  last_sync: 'Última actualización en',
  draft: 'Borrador',
  template: 'Modelo',
  archived: 'Esta alerta ya no está activa.',
  see_all: 'Ver toda la información del tráfico',
  updates: 'Actualizaciones',
  today: 'Hoy',
  at: 'a las',
  reason: 'Patrón : ',
  estimated_recovery_date: 'Fecha estimada de recuperación : ',
};

export default traffic;
