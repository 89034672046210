import { FETCH_PLACES } from '../actions/PlacesActions';

const initialState = {
  places: [],
  latestPlaces: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLACES:
      return {
        ...state,
        places: action.payload.places,
        latestPlaces: action.payload.taxonomy,
      };
    default:
      return state;
  }
};
