const plan = {
  title: 'Le plan dynamique',
  title_mobile: 'Le plan dynamique :',
  text_1: 'Pour visualiser une ligne, ou un arrêt <br />',
  text_2: 'Pour consulter l\'ensemble des stations <br />LE VÉLO et parcs relais et leurs disponibilités <br />',
  text_3: 'Pour découvrir l\'offre de transport autour d\'une adresse, d\'une commune ou d\'un point d\'intérêt',
  show: 'Voir le plan',
};

export default plan;
