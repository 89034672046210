import {
  CLEAR_ACTIVE_SCHEDULE_DETAIL, CLEAR_REALTIME,
  CLEAR_STOP_POINT,
  FETCH_REALTIME_STOP_POINT, FETCH_REALTIME_DISABLED, FETCH_STOP_AREAS,
  FETCH_STOP_POINT,
  FETCH_VEHICLE_SCHEDULES,
  HANDLE_DESTINATIONS, SET_STOP_AREA_LINES,
} from '../actions/StopActions';

const initialState = {
  stopPoint: null,
  stopArea: null,
  stopAreas: [],
  realtime: {},
  destinationsForSchedule: [],
  vehicleSchedules: [],
  activeScheduleDetail: null,
  stopAreaLines: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STOP_POINT:
      return {
        ...state,
        stopPoint: action.payload,
      };
    case FETCH_REALTIME_STOP_POINT:
      return {
        ...state,
        realtime: action.payload,
      };
    case FETCH_REALTIME_DISABLED:
      return {
        ...state,
        realtime_disabled: action.payload,
      };
    case FETCH_STOP_AREAS:
      return {
        ...state,
        stopAreas: action.payload,
      };
    case HANDLE_DESTINATIONS:
      return {
        ...state,
        destinationsForSchedule: action.payload,
      };
    case FETCH_VEHICLE_SCHEDULES:
      return {
        ...state,
        vehicleSchedules: action.payload.schedules,
        activeScheduleDetail: action.payload.vehicleId,
      };
    case CLEAR_ACTIVE_SCHEDULE_DETAIL:
      return {
        ...state,
        activeScheduleDetail: null,
      };
    case CLEAR_STOP_POINT:
      return {
        ...state,
        stopPoint: null,
      };
    case CLEAR_REALTIME:
      return {
        ...state,
        realtime: {},
      };
    case SET_STOP_AREA_LINES:
      return {
        ...state,
        stopAreaLines: action.payload,
      };
    default:
      return state;
  }
};
