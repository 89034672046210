const nearbyMe = {
  title: 'Près de chez moi',
  btn_geoloc: 'Ma position',
  search: {
    type_to_search: 'Commencez à écrire pour rechercher',
    placeholder: 'Adresse, Arrêt, Commune...',
    loading: 'Chargement en cours...',
    no_result: 'Pas de résultat',
    error: 'Une erreur est survenue',
  },
};

export default nearbyMe;
