const userFeedback = {
  userModalIntro: 'Les informations affichées sont incohérentes ? Vous constatez un dysfonctionnement ?\n'
  + 'Vous avez une piste d’amélioration à nous soumettre ?\n'
  + 'Rédigez votre commentaire ci-dessous !\n'
  + 'Pour toute autre demande (infos réseau, abonnements, réclamations, etc.),',
  userModalIntroCta: 'écrivez-nous ici',
  userModalIntroLink: 'https://www.infotbm.com/fr/tbm-votre-ecoute/contacter-tbm-formulaire.html',
  userModalTitle: 'Écrivez votre commentaire',
  userInformationTitle: 'Vos coordonnées si vous souhaitez être contacté (facultatif)',
  userInformationDisclaimer: 'TBM s’engage à assurer la confidentialité des informations que vous nous communiquez.',
  userInformationDisclaimer2: 'Vos données personnelles sont confidentielles et '
  + 'ne seront jamais utilisées à des fins commerciales.',
  confirmation: 'Merci d\'avoir laissé un commentaire. '
  + 'Tous les avis sont étudiés dans le but constant d\'améliorer le site infotbm.com',
  error: 'Une erreur est survenue lors de l\'envoie du formulaire. Veuillez réessayer plus tard.',
  form: {
    comment: 'Commentaire',
    happy: 'Satisfait',
    notHappy: 'Non satisfait',
    lastName: 'Nom',
    firstName: 'Prénom',
    email: 'Adresse mail',
    submit: 'Envoyer',
    clear: 'Réinitialiser',
    cancel: 'Annuler',
  },
  button: {
    info: 'Qu\'avez-vous pensé de cette page ? \nAidez-nous à améliorer ce site',
    cta: 'en laissant votre commentaire',
  },
};

export default userFeedback;
