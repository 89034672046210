import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import Router from 'next/router';
import Head from 'next/head';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import withReduxStore from '../lib/with-redux-store';
import { GTM } from '../parameters';
import { getRouteNameByPage, getRouteTitleByPage } from '../helpers/RouterHelper';
import {
  fetchHtmlLayout,
  getHistory,
  handleLang,
  saveHistory,
  SET_IS_DEBUG,
  SET_IS_SERVER,
  SET_IS_WEBVIEW,
  SET_IS_HOMEPAGE,
  SET_PAGE,
  SET_WEBVIEW_HEADERS,
  setMobile,
  setLang, setSmMobile,
} from '../actions/AppActions';
import { fetchAllLines } from '../actions/LineActions';
import { fetchHighTrafficImpactBanner } from '../actions/TrafficInfoActions';
import { MOBILE_MAX_WIDTH, MOBILE_SM_MAX_WIDTH, TITLE_SUFFIX } from '../config/app';
import HtmlLayout from '../components/layouts/HtmlLayout';
import Loader from '../components/elements/Loader';
// Fix css import
// Some components need styles, but next can't load them if there is not styles on every page.
import '../public/static/css/styles.css';
import { pushGtmEvent } from '../helpers/GTMHelper';
import { SET_SHOW_TIME_BANNER, POST_ROADMAP_DATA } from '../actions/RouteActions';

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    const {
      reduxStore, pathname, query, asPath, req,
    } = ctx;

    // Webviews & homepage detection
    let hostname = '';

    if (req) {
      hostname = req.headers.host;
    } else if (typeof window !== 'undefined') {
      // eslint-disable-next-line prefer-destructuring
      hostname = window.location.hostname;
    }

    const isServer = !process.browser;
    const isWebview = hostname.includes('webviews');
    const language = handleLang(query);

    await reduxStore.dispatch({ type: SET_IS_WEBVIEW, payload: isWebview });

    // #7749 - Show time banner for mobile app
    const availableTimeBannerPages = ['route-results', 'route-roadmap'];
    reduxStore.dispatch({
      type: SET_SHOW_TIME_BANNER,
      payload: isWebview && availableTimeBannerPages.includes(getRouteNameByPage(pathname)) && 'showTimeBanner' in query,
    });
    reduxStore.dispatch({
      type: POST_ROADMAP_DATA,
      payload: isWebview && 'postRoadmapData' in query,
    });

    if (
      isWebview
      && req?.headers
      && 'x-keolis-auth-token' in req.headers
      && 'x-keolis-auth-user' in req.headers
    ) {
      await reduxStore.dispatch({ type: SET_WEBVIEW_HEADERS, payload: req.headers });
    }

    if (
      isWebview
      && req?.headers
      && 'x-keolis-auth-token' in req.headers
      && 'x-keolis-auth-user' in req.headers
      && 'x-keolis-app-version' in req.headers
    ) {
      await reduxStore.dispatch({ type: SET_WEBVIEW_HEADERS, payload: req.headers });
    }

    // Server request
    if (isServer) {
      await reduxStore.dispatch(handleLang(query));
      await reduxStore.dispatch(fetchHtmlLayout());
      await reduxStore.dispatch(fetchAllLines());
      await reduxStore.dispatch(fetchHighTrafficImpactBanner());
    }
    reduxStore.dispatch({ type: SET_IS_SERVER, payload: isServer });

    // Store route name
    const routeName = getRouteNameByPage(pathname);
    const routeTitle = getRouteTitleByPage(pathname);

    // 2022_HP_RECAST
    // For 2022 homepage recast, we have to manage specific cases for the homepage
    const isHomepage = routeName === 'homepage';
    await reduxStore.dispatch({ type: SET_IS_HOMEPAGE, payload: isHomepage });
    // 2022_HP_RECAST

    await reduxStore.dispatch({ type: SET_PAGE, payload: routeName });
    // React history
    reduxStore.dispatch(saveHistory({ pathname, query, asPath }));

    // GTM
    if (!isServer) {
      if (typeof window !== 'undefined') {
        // Get react history
        const history = reduxStore.dispatch(getHistory());
        let routeTitleString = `${TITLE_SUFFIX}`;
        try {
          routeTitleString = `${Translate(routeTitle)} | ${TITLE_SUFFIX}`;
        } catch (e) {
          // Woops, the translation doesn't exists, the title will stay with the prefix only
        }
        pushGtmEvent('pageReactView', {
          pageReact: routeName,
          titleReact: routeTitleString.substring(0, 100),
          urlReact: (asPath).substring(0, 100),
          urlPrecReact: (history[0].asPath).substring(0, 100),
        });
      }
    } else {
      const isUserLogged = function () {
        const cookies = `; ${document.cookie}`;
        const parts = cookies.split('; ec_logged=');
        if (parts.length === 2) {
          const value = parts.pop().split(';').shift();

          if (value.trim().length > 0) {
            const clearCookieValue = JSON.parse(atob(value));

            let userHash = '';
            if (typeof clearCookieValue.userHash !== 'undefined') {
              // eslint-disable-next-line prefer-destructuring
              userHash = clearCookieValue.userHash;
            }
            return userHash;
          }
        }
        return false;
      };

      if (typeof window !== 'undefined') {
        const currentWebsiteLanguage = window.location.pathname.substr(1, 2).toUpperCase();

        const pageViewEventParams = {
          lang: currentWebsiteLanguage,
          user: {
            logged: isUserLogged() !== false ? 1 : 0,
          },
          google_tag_params: {
            ecomm_pagetype: routeName === 'homepage' ? 'home' : 'other',
          },
        };

        pushGtmEvent('tbmPageView', pageViewEventParams);
      }
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    // Debug mode
    if ('debug' in query) {
      reduxStore.dispatch({ type: SET_IS_DEBUG, payload: true });
    }

    return { pageProps, isWebview, language };
  }

  constructor(props) {
    super(props);

    this.state = {
      pageLoading: false,
    };
  }

  componentDidMount() {
    this.props.reduxStore.dispatch(setMobile(window.innerWidth < MOBILE_MAX_WIDTH));
    this.props.reduxStore.dispatch(setSmMobile(window.innerWidth < MOBILE_SM_MAX_WIDTH));
    this.props.reduxStore.dispatch(setLang(window.location.pathname.substr(1, 2).toLowerCase()));

    Router.events.on('routeChangeStart', () => {
      this.setState({ pageLoading: true });
    });
    Router.events.on('routeChangeComplete', () => {
      this.setState({ pageLoading: false });

      // Webviews detection
      let hostname = '';

      if (typeof window !== 'undefined') {
        // eslint-disable-next-line prefer-destructuring
        hostname = window.location.hostname;
      }

      const isWebview = hostname.includes('webviews');

      setTimeout(() => {
        if (isWebview) {
          try {
            window.ReactNativeWebView.postMessage(
              JSON.stringify(
                {
                  eventName: 'webview_view',
                  eventParams: {
                    webview_location: (window.location.href).substring(0, 100),
                    webview_path: (window.location.pathname + window.location.search).substring(0, 100),
                    webview_title: (document.title).substring(0, 100),
                  },
                },
              ),
            );
          } catch (e) {
            // We're not in a webview loaded by the app. Do nothing
          }
        }
      }, 1000);
    });
    // eslint-disable-next-line no-console
    Router.events.on('routeChangeError', e => console.error(`Change error : ${e}`));

    window.addEventListener('resize', () => {
      const dimensions = {
        height: window.innerHeight,
        width: window.innerWidth,
      };
      this.props.reduxStore.dispatch(setMobile(dimensions.width < MOBILE_MAX_WIDTH));
      this.props.reduxStore.dispatch(setSmMobile(dimensions.width < MOBILE_SM_MAX_WIDTH));
    });

    return () => {
      window.removeEventListener('resize', null);
    };
  }

  render() {
    const {
      Component, pageProps, reduxStore, isWebview,
    } = this.props;

    const { pageLoading } = this.state;
    // const { pageLoading } = false;

    return (
      <Provider store={reduxStore}>
        <Head>
          <title>TBM Transports Bordeaux Métropole</title>
          <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no" />
          { isWebview ? <meta name="robots" content="noindex" /> : null }
        </Head>

        {/* eslint-disable-next-line react/no-danger */}
        <noscript dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM}" height="0" width="0" style="display:none;visibility:hidden" ></iframe>`,
        }}
        />

        <HtmlLayout style={{ height: pageLoading ? '100vh' : 'inherit' }}>
          {
            pageLoading
              ? <Loader />
              : <Component {...pageProps} />
          }
        </HtmlLayout>
      </Provider>
    );
  }
}

export default withReduxStore(MyApp);
