import { NETWORK_LINE_URL, NETWORK_LINES_URL } from '../config/config';
import { LINES_TYPES } from '../config/line';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const FETCH_ALL_LINES = 'FETCH_ALL_LINES';
export const FETCH_LINES = 'FETCH_LINES';
export const SET_LINE = 'FETCH_LINE';
export const FETCH_TRAMS = 'FETCH_TRAMS';
export const FETCH_BUS = 'FETCH_BUS';
export const FETCH_BUS_NIGHT = 'FETCH_BUS_NIGHT';
export const FETCH_SCODIS = 'FETCH_SCODIS';
export const FETCH_BATCUB = 'FETCH_BATCUB';
export const FETCH_TRAINS = 'FETCH_TRAINS';
export const FETCH_AUTOCARS = 'FETCH_AUTOCARS';
export const SET_LINE_ROUTE = 'SET_LINE_ROUTE';

export const fetchAllLines = () => async (dispatch, getState) => {
  try {
    if (getState().lines.lines.length > 0) {
      return false;
    }

    const linesQuery = await fetchWithTimeout(`${NETWORK_LINES_URL}`);
    const lines = await linesQuery.json();

    dispatch({
      type: FETCH_ALL_LINES,
      payload: lines,
    });

    const activeLines = lines.filter(line => !line.isHidden);

    dispatch({
      type: FETCH_LINES,
      payload: activeLines,
    });

    dispatch({
      type: FETCH_TRAMS,
      payload: activeLines.filter(line => line.type === LINES_TYPES.TRAM),
    });

    dispatch({
      type: FETCH_BUS,
      payload: activeLines.filter(line => line.type === LINES_TYPES.BUS),
    });

    dispatch({
      type: FETCH_BUS_NIGHT,
      payload: activeLines.filter(line => line.type === LINES_TYPES.BUS_NIGHT),
    });

    dispatch({
      type: FETCH_SCODIS,
      payload: activeLines.filter(line => line.type === LINES_TYPES.SCODI),
    });

    dispatch({
      type: FETCH_BATCUB,
      payload: activeLines.filter(line => line.type === LINES_TYPES.BATCUB),
    });

    dispatch({
      type: FETCH_TRAINS,
      payload: activeLines.filter(line => line.type === LINES_TYPES.TRAIN_TER),
    });

    dispatch({
      type: FETCH_AUTOCARS,
      payload: activeLines.filter(line => line.type === LINES_TYPES.AUTOCAR),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données lignes : ${e}`);
  }
};

export const fetchLine = id => async (dispatch, getState) => {
  try {
    const { line } = getState().lines;

    if (line !== null && (line.id === id || line.externalCode === id)) {
      return false;
    }

    const lineQuery = await fetchWithTimeout(`${NETWORK_LINE_URL}/${id}`);
    const data = await lineQuery.json();

    return dispatch({
      type: SET_LINE,
      payload: data,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des données de la ligne : ${e}`);
  }
};

export const setLine = line => dispatch => (
  dispatch({
    type: SET_LINE,
    payload: line,
  })
);

export const setLineRoute = routeId => (dispatch) => {
  dispatch({
    type: SET_LINE_ROUTE,
    payload: routeId,
  });
};
