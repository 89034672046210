import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from '../../elements/Link';
import routes from '../../../routes';
import { fetchHighTrafficImpactBanner } from '../../../actions/TrafficInfoActions';
import { taggingTrafficBannerClick } from '../../../tagging/homepage/TrafficTaggingActions';

const TrafficBanners = () => {
  const banners = useSelector(state => state.traffic.banners);
  const isWebview = useSelector(state => state.app.isWebview);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHighTrafficImpactBanner());
  }, []);

  if (banners?.length && !isWebview) {
    return (
      <div className="traffic-alerts-wrapper bg-fpink p-l-0-5 p-r-0-5 p-md-l-2 p-md-r-2">
        {
          banners.map(banner => (
            <div className="alert-content display-flex align-items-center" key={banner.id}>
              <Link route={routes.TRAFFIC.DETAILS.name} params={{ id: banner.id }}>
                <a className="text-white text-align-left" onClick={() => taggingTrafficBannerClick(banner.title)}>
                  <span className="fs-normal fs-md-h5 font-bold display-block">{ banner.title }</span>
                </a>
              </Link>
            </div>
          ))
        }
      </div>
    );
  }

  return null;
};

export default TrafficBanners;
