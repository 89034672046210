const pointOfInterest = {
  title: 'Lieux publics',
  show_all: 'Afficher tous les lieux publics',
  hide_all: 'Masquer tous les lieux publics',
  back_to_list: 'Retour à la liste',
  back_to_theme: 'Retour au thème',
  back_to_category: 'Retour à la catégorie',
  empty_category: 'Aucune catégorie trouvée pour ce thème de lieu public',
  empty_poi: 'Aucun lieu public trouvé pour cette catégorie',
  transport_title: 'L\'offre à moins de 10 minutes :',
  search: {
    placeholder: 'Chercher un lieu public',
    no_result: 'Aucun lieu public trouvé',
  },
  summary: {
    name: 'Nom',
    address: 'Adresse',
    category: 'Catégorie',
  },
  fav_item: 'cette adresse',
};

export default pointOfInterest;
