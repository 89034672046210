const plans = {
  title: 'Maps',
  title_index: 'All plans',
  title_vcub: 'LE VÉLO plans',
  title_park: 'Park and ride plans',
  meta_description_index: 'Find here all the plans of the TBM network '
  + '(line plan, day and night network, LE VÉLO stations, relay parks ...).',
  meta_description_vcub: 'Find here the LE VÉLO plan of the TBM network.',
  meta_description_park: 'Find here the TBM network relay park plan.',
  description: 'To accompany you during your journey',
  lines: 'Lines map',
  parks: 'Park and Rides map',
  network: 'Network map',
  town: 'Municipalities maps',
  touristic: 'Tourist map',
  night: 'Evening network\'s map',
  vcub: 'LE VÉLO stations map',
  help: {
    title: 'Good to know',
    save_print: 'You have the possibility to save and print your plans',
    subscriptions: 'To get around, think about ',
    tbm_subscriptions: 'tickets or subscriptions.',
    or: 'or the ',
    night_pass: 'evening pass!',
    infos: 'Discover all the practical information to ',
    move_night: 'move at night with TBM!',
    prepare_moves: 'prepare your trips.',
  },
  dynamic: {
    title: 'Dynamic plan',
    meta_description: 'Visualize on a dynamic level the whole TBM transport offer '
    + '(lines, municipalities, companies and partner establishments ...).',
    around: 'Around me',
    cities: 'Cities',
    public_places: 'Public places',
    enterprises: 'TBM companies and partner institutions',
    lines: 'The lines',
  },
  highlight: {
    title: 'Good to know',
    description: 'Theoretical itineraries given as an indication.\nRemember to check the next passages of your bus/tram in real time.',
  },
};

export default plans;
