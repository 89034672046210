import { ROADMAP_TAGGING_ACTION } from '../../config/routes';
import { checkIsWebview } from '../../helpers/GenericHelper';

export const taggingEventSearchRoute = (props) => {
  if (!checkIsWebview()) {
    try {
      window.dataLayer.push({
        event: 'tbm_click_itineraries_search',
        ...props,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  } else {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify(
          {
            eventName: 'tbm_click_itineraries_search',
            eventParams:
              Object.assign(props, {
                webview_location: (window.location.href).substring(0, 100),
                webview_path: (window.location.pathname + window.location.search).substring(0, 100),
                webview_title: (document.title).substring(0, 100),
                is_webview: 1,
              }),
          },
        ),
      );
    } catch (e) {
      // We're not in a webview loaded by the app. Do nothing
    }
  }
  return null;
};

export const taggingEventPedestrianDetails = () => {
  if (!checkIsWebview()) {
    const params = {
      tbm_click_item: ROADMAP_TAGGING_ACTION.PEDESTRIAN_DETAILS,
    };
    try {
      window.dataLayer.push({
        event: 'tbm_click_itinerary_result',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  } else {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify(
          {
            eventName: 'tbm_click_itinerary_result',
            eventParams: {
              tbm_click_item: ROADMAP_TAGGING_ACTION.PEDESTRIAN_DETAILS,
              webview_location: (window.location.href).substring(0, 100),
              webview_path: (window.location.pathname + window.location.search).substring(0, 100),
              webview_title: (document.title).substring(0, 100),
              is_webview: 1,
            },
          },
        ),
      );
    } catch (e) {
      // We're not in a webview loaded by the app. Do nothing
    }
  }
  return null;
};

export const taggingEventRouteRoadmap = (action) => {
  if (!checkIsWebview()) {
    const params = {
      tbm_click_item: action,
    };
    try {
      window.dataLayer.push({
        event: 'tbm_click_itinerary_result',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  } else {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify(
          {
            eventName: 'tbm_click_itinerary_result',
            eventParams: {
              webview_location: (window.location.href).substring(0, 100),
              webview_path: (window.location.pathname + window.location.search).substring(0, 100),
              webview_title: (document.title).substring(0, 100),
              tbm_click_item: action,
              is_webview: 1,
            },
          },
        ),
      );
    } catch (e) {
      // We're not in a webview loaded by the app. Do nothing
    }
  }

  return null;
};

export const taggingEventRouteRoadmapTimelag = (action) => {
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_itinerary_time_lag: action,
      };
      window.dataLayer.push({
        event: 'tbm_itinerary_result_time_lag_success',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  } else {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify(
          {
            eventName: 'tbm_itinerary_result_time_lag_success',
            eventParams: {
              webview_location: (window.location.href).substring(0, 100),
              webview_path: (window.location.pathname + window.location.search).substring(0, 100),
              webview_title: (document.title).substring(0, 100),
              tbm_itinerary_time_lag: action,
              is_webview: 1,
            },
          },
        ),
      );
    } catch (e) {
      // We're not in a webview loaded by the app. Do nothing
    }
  }
  return null;
};

export const taggingEventRouteRoadmapResultList = (resultsLength) => {
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_itineraries_result: resultsLength,
      };
      window.dataLayer.push({
        event: 'tbm_itineraries_search_success',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  } else {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify(
          {
            eventName: 'tbm_itineraries_search_success',
            eventParams: {
              tbm_itineraries_result: resultsLength,
              webview_location: (window.location.href).substring(0, 100),
              webview_path: (window.location.pathname + window.location.search).substring(0, 100),
              webview_title: (document.title).substring(0, 100),
              is_webview: 1,
            },
          },
        ),
      );
    } catch (e) {
      // We're not in a webview loaded by the app. Do nothing
    }
  }
  return null;
};

/**
 * @param value
 * @param type
 * @returns {null}
 */
export const taggingEventRouteRoadmapResultClick = (value, type) => {
  value = type === 'itinerary' ? `Itinéraire ${value}` : value;
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_click_item: value,
      };
      window.dataLayer.push({
        event: 'tbm_click_itineraries_search_results',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  } else {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify(
          {
            eventName: 'tbm_click_itineraries_search_results',
            eventParams: {
              webview_location: (window.location.href).substring(0, 100),
              webview_path: (window.location.pathname + window.location.search).substring(0, 100),
              webview_title: (document.title).substring(0, 100),
              tbm_click_item: value,
              is_webview: 1,
            },
          },
        ),
      );
    } catch (e) {
      // We're not in a webview loaded by the app. Do nothing
    }
  }
  return null;
};

export const taggingEventForIsolatedRoutes = (value) => {
  if (!checkIsWebview()) {
    try {
      window.dataLayer.push({
        event: 'tbm_click_itineraries_search_results',
        tbm_click_item: value,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  } else {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          eventName: 'tbm_click_itineraries_search_results',
          eventParams: {
            webview_location: (window.location.href).substring(0, 100),
            webview_path: (window.location.pathname + window.location.search).substring(0, 100),
            webview_title: (document.title).substring(0, 100),
            tbm_click_item: value,
            is_webview: 1,
          },
        }),
      );
    } catch (e) {
      // We're not in a webview loaded by the app. Do nothing
    }
  }
  return null;
};

export const taggingSwitchDepartureArrivalEvent = () => {
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_click_item: 'itineraries inverted',
      };
      window.dataLayer.push({
        event: 'tbm_event',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  }
  return null;
};
