const schedules = {
  title: 'Schedules',
  section_title: 'Schedules / live arrivals',
  title_search: 'Schedules and next passages',
  meta_description: 'Find here all the schedules and next passages of the lines of the TBM network.',
  description: 'Access in a few clicks the schedules and the next arrival of your lines.',
  search: {
    title: 'Search',
    placeholder: 'Look for a stop, a line',
    stop_point_placeholder: 'Look for a stop',
    list: 'Show all lines',
    error: 'Error occurred',
    no_result: 'No results',
    select_direction: 'Select a direction',
    select_line_stop: 'Select a line and a direction\n',
    select_direction_stop: 'Please select a direction and then your stop to find out the schedule.',
  },
  schedules_sheets: {
    title: 'Time sheets to download',
    description: 'Select the line of your choice to access its time sheet and practical information\n',
  },
  places: {
    title: 'The most requested places',
    othertitle: 'Most requested places',
    indication: 'Click on a line to know its next passages',
  },
  help: {
    title: 'Help and advice',
    plans: 'Maps',
    partial_stop: 'Partial stop',
    plans_description: 'Download the plans of the lines you use',
    schedules: 'How to find timetables?',
    schedules_description: 'Download the time sheet of your line',
  },
  direction: 'Direction',
  destination: 'Destination',
  next_passages: 'Next passages',
  near: 'Near',
  at: 'at',
  see_another_day: 'See another day',
  schedule_indication: 'Click on a schedule to know the arrival time at your downhill stop',
  find_bus: 'Where is my bus ?',
  find_batcub: 'Where is my BAT3 ?',
  find_tram: 'Where is my tram ?',
  find_train: 'Where is my train ?',
  find_autocar: 'Where is my autocar ?',
  favorites: 'My favorite stops',
  print: 'Print schedules',
};

export default schedules;
