import {
  baseUrl, trafficUrl, customerAreaUrl, helpUrl,
} from '../parameters';

export const BASE_URL = baseUrl;
export const API_URL = '/api';

export const TRAFFIC_ALERT_URL = `${trafficUrl}/alerts`;
export const TRAFFIC_ALERT_LINE_URL = `${trafficUrl}/alerts/by-transport`;
export const INFORMATION_URL = `${trafficUrl}/notification`;

export const NETWORK_LINES_URL = `${trafficUrl}/network/get-lines-informations`;
export const NETWORK_LINE_URL = `${trafficUrl}/network/line-informations`;

export const VCUBS_URL = `${trafficUrl}/vcubs`;
export const VCUBS_PREDICT_URL = `${trafficUrl}/vcubs/predict`;
export const PARKS_URL = `${trafficUrl}/parks`;
export const PARKS_PREDICT_URL = `${trafficUrl}/parks/predict`;

export const STOP_AREAS_URL = `${trafficUrl}/stopareas`;
export const STOP_AREA_URL = `${trafficUrl}/network/stoparea-informations`;
export const STOP_POINT_DETAILS_URL = `${trafficUrl}/stop-points-informations`;
export const STOP_POINT_URL = `${trafficUrl}/stop-points`;
export const SCHEDULES_SEARCH_URL = `${trafficUrl}/get-schedule`;
export const STOP_POINT_REALTIME_URL = `${trafficUrl}/get-realtime-pass-by-id`;
export const VEHICLE_SCHEDULES_URL = `${trafficUrl}/get-vehicle-schedule`;

export const CITIES_URL = `${trafficUrl}/network/cities`;
export const CITY_URL = `${trafficUrl}/network/city`;
export const NEAR_URL = `${trafficUrl}/network/nearby/lines`;
export const ENTERPRISES_SEARCH_URL = `${trafficUrl}/enterprise/autocomplete`;
export const ENTERPRISE_URL = `${trafficUrl}/enterprise`;
export const POI_URL = `${trafficUrl}/network/poi`;
export const STOPS_NEARBY = `${trafficUrl}/network/nearby/lines`;

export const ROUTES_SEARCH_URL = `${trafficUrl}/network/autocomplete/route`;
export const ROUTES_ITEM_URL = `${trafficUrl}/network/fetch/route/items`;
export const ROUTES_CALCUL_URL = `${trafficUrl}/calculate/route`;

export const GEOLOCATE_USER_URL = `${trafficUrl}/network/fetch/route/items/address`;

export const NEWS_URL = '/api/json/news';
export const BG_URL = '/api/json/bg';
export const NODES_URL = '/api/json/nodes';
export const TERMS_URL = '/api/json/terms';
export const PLACES_URL = '/api/json/place';

export const LOGIN_URL = `${customerAreaUrl}/login`;
export const FAVORITES_URL = `${customerAreaUrl}/api/favorites`;
export const EXTERNAL_FAVORITES_VCUB_URL = `${customerAreaUrl}/favorites/new/vcub`;
export const EXTERNAL_FAVORITES_PARK_URL = `${customerAreaUrl}/favorites/new/pr`;
export const EXTERNAL_FAVORITES_ROUTE_URL = `${customerAreaUrl}/favorites/new/route`;
export const EXTERNAL_FAVORITES_STOP_URL = `${customerAreaUrl}/favorites/new/stop`;
export const EXTERNAL_FAVORITES_PLACE_URL = `${customerAreaUrl}/favorites/new/place`;
export const EXTERNAL_FAVORITES_LINE_URL = `${customerAreaUrl}/alerts/new/line`;
export const EXTERNAL_FAVORITES_ALL_URL = `${customerAreaUrl}/my-favorites-and-alerts`;
export const EXTERNAL_FAVORITES_ALERTS_URL = `${customerAreaUrl}/alerts/new/line`;

export const ECOMOBI_WEBSITE_URL = 'https://ecomobi.fr/';
export const HELP_GOOD_TRAVEL_URL = `${helpUrl}/?thematic=regles-bien-voyager`;

export const NAVITIA_PLAYGROUND_URL = 'https://hove-io.github.io/navitia-playground/play.html';
