const others = {
  title: 'Otros servicios',
  title_home: '¡ Prepare su viaje<span style="opacity: 0;">&nbsp;</span>!',
  home: 'Necesita informacion? ¡Encuentra lo esencial para preparar tus viajes en la red TBM!',
  home_report_violence_harassment: 'Denunciar la violencia o el acoso',
  home_accessibility_mobibus: 'Accesibilidad y Mobibus',
  title_mobile: 'La oferta de TBM :',
  air_quality: 'Calidad del aire :',
  air_quality_infos: 'Más información sobre el',
  air_quality_website: 'sitio de Atmo',
};

export default others;
