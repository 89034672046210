
export const LCMAP_ROUTES = {
  VCUB: 'vcub',
  ROUTE: 'route-calculation',
  PARK: 'p+r',
  SCHEDULE: '',
  DYNAMIC: '',
  BIKE: 'velo',
  DEPOSITAIRIES: 'depositaires',
  COMPANY: 'company',
};

export const LCMAP_DISPLAY = {
  BIKES: 'bikes',
  SLOTS: 'slots',
};

export const LCMAP_EVENT = {
  MARKER: 'marker',
  VCUB_MORE: 'vcub-more-infos',
  PARK_MORE: 'p+r-more-infos',
  STOP_POINT: 'stop',
  AUTOCOMPLETE: 'autocomplete-item',
  POI: 'thematic-item',
  BACK: 'back',
  THEMATIC: 'thematic',
};

export const LCMAP_AUTOCOMPLETE_TYPE = {
  VCUB: 'poi_type:vcub',
  PARK: 'poi_type:p+r',
  ENTERPRISE: 'company',
  NEAR: 'address',
  STOP_AREA: 'stop_area',
  LINE: 'line',
  POI: 'poi',
  ADMINISTRATIVE_REGION: 'administrative_region',
  GEOLOCATION: 'geolocation',
};

export const LCMAP_LOADED = {
  LINES: 'lines',
  AROUND: 'around',
  THEMATIC: 'thematic',
  COMPANY: 'company',
};

export const DEFAULT_MAP_CENTER = { lat: 44.85174228760909, lng: -0.5881825000000163 };
export const DEFAULT_MAP_ZOOM = 12;
export const DETAIL_MAP_ZOOM = 15;
