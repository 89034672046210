/**
 * When get favorites, they are sorted by the following types.
 * Same values as in the favorite reducer.
 */
export const FAVORITES_TYPES_GET = {
  VCUBS: 'vcubs',
  PARKS: 'parks',
  STOPS: 'stops',
  PLACES: 'places',
  ROUTES: 'routes',
  LINES: 'alert_lines',
  ALERTS: 'alert_lines',
};

/**
 * When add new favorites, one of the following type is required.
 */
export const FAVORITES_TYPES_POST = {
  VCUB: 'vcub',
  PARK: 'pr',
  STOP: 'stop',
  PLACE: 'place',
  ROUTE: 'route',
};
