const plan = {
  title: 'The dynamic plan',
  title_mobile: 'The dynamic plan :',
  text_1: 'To view a line or a stop <br />',
  text_2: 'To check all the <br />LE VÉLO stations and park-and-ride facilities and their availability <br />',
  text_3: 'To find out what transport services are available around an address, a town or a point of interest',
  show: 'Show plan',
};

export default plan;
