import {
  FETCH_ALL_PARKS, FETCH_PARKS_WITH_PREDICTIONS, SET_PREDICTION_TIME, SET_PARK,
} from '../actions/ParkingActions';

const initialState = {
  parks: [],
  predictionTime: 0,
  park: null,
  predictions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PARKS:
      return {
        ...state,
        parks: action.payload,
      };
    case FETCH_PARKS_WITH_PREDICTIONS:
      return {
        ...state,
        parks: action.payload.parkings,
        predictions: action.payload.predictions,
      };
    case SET_PREDICTION_TIME:
      return {
        ...state,
        predictionTime: action.payload,
      };
    case SET_PARK:
      return {
        ...state,
        park: action.payload,
      };
    default:
      return state;
  }
};
