const nextRoutes = require('next-routes-extended');
const getRouteName = require('./helpers/RouteLangHelper');
const routes = require('./routes');

const nextRouter = nextRoutes();

/**
 * add(
 *  routeName,
 *  url pattern,
 *  page (the page file on the /pages directory)
 * )
 */

function addMultilingualRoutes(route) {
  Object.keys(route.patterns).forEach((lang) => {
    const routeName = getRouteName(route.name, lang);
    const urlPattern = `/:lang(${lang})${route.patterns[lang]}`;

    nextRouter.add(routeName, urlPattern, route.page);
  });
}

addMultilingualRoutes(routes.HOMEPAGE.HOME);
addMultilingualRoutes(routes.ROUTE.HOME);
addMultilingualRoutes(routes.ROUTE.RESULTS);
addMultilingualRoutes(routes.ROUTE.ROADMAP);
addMultilingualRoutes(routes.SCHEDULE.HOME);
addMultilingualRoutes(routes.SCHEDULE.RESULTS);
addMultilingualRoutes(routes.SCHEDULE.LINE);
addMultilingualRoutes(routes.SCHEDULE.STOP_DETAILS);
addMultilingualRoutes(routes.TRAFFIC.HOME);
addMultilingualRoutes(routes.TRAFFIC.DETAILS);
addMultilingualRoutes(routes.TRAFFIC.PRIVATE);
addMultilingualRoutes(routes.VCUB.HOME);
addMultilingualRoutes(routes.VCUB.DETAILS);
addMultilingualRoutes(routes.PARKING.HOME);
addMultilingualRoutes(routes.PARKING.DETAILS);
addMultilingualRoutes(routes.LINE.HOME);
addMultilingualRoutes(routes.LINE.DETAILS);
addMultilingualRoutes(routes.PLAN.HOME);
addMultilingualRoutes(routes.PLAN.DYNAMIC);
addMultilingualRoutes(routes.MAP.HOME);
addMultilingualRoutes(routes.INFO.DETAILS);
addMultilingualRoutes(routes.CUSTOM.TOURISTIC);

module.exports = nextRouter;
