const nearbyMe = {
  title: 'Near me',
  btn_geoloc: 'Locate me',
  search: {
    type_to_search: 'Start typing to search',
    placeholder: 'Address, Stop, Town...',
    loading: 'Loading...',
    no_result: 'No result found',
    error: 'An error has occurred',
  },
};

export default nearbyMe;
