import {
  SET_CITIES, SET_POIS,
  SET_POIS_ERROR, SET_SECTION, SET_STOP_AREAS,
} from '../actions/PlanActions';

const initialState = {
  cities: [],
  pois: [],
  poisError: null,
  stopareas: [],
  section: null,
};

export default function PlanReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SECTION:
      return {
        ...state,
        section: action.payload,
      };
    case SET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case SET_POIS:
      return {
        ...state,
        pois: action.payload,
      };
    case SET_POIS_ERROR:
      return {
        ...state,
        poisError: action.payload,
      };
    case SET_STOP_AREAS:
      return {
        ...state,
        stopareas: action.payload,
      };
    default:
      return state;
  }
}
