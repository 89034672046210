const stopPoint = {
  line: 'Línea',
  destination: 'Destino',
  next_pass: 'Próximas llegadas en',
  theoretical_time: 'este símbolo indica que la hora de llegada a la parada es teórica',
  search: {
    scheduleDetail: 'Buscar una parada',
    empty: 'No se han encontrado paradas',
  },
  a11y: {
    date: 'Seleccionar fecha',
    scheduleDetail: 'Ver el desglose del horario:',
    closeDetail: 'Cerrar el desglose',
  },
  map: {
    hide: 'Ocultar mapa',
    show_bus: 'Ver mi bus en el mapa',
    show_batcub: 'Ver mi Bat3 en el mapa',
  },
};

export default stopPoint;
