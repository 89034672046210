import { API_URL, BASE_URL } from '../config/config';
import { fetchWithTimeout } from '../helpers/RequestHelper';

class Infotbm {
  constructor() {
    this.lang = 'fr';
  }

  setLang = (langCode) => {
    this.lang = langCode;
  };

  getRegion = async (region) => {
    try {
      const response = await fetchWithTimeout(`${BASE_URL}/${this.lang}${API_URL}/${region}`);
      return await response.text();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error : Couldn't get region : ${error}`);
    }
  };

  getSpriteSvg = async () => {
    try {
      const response = await fetchWithTimeout(`${BASE_URL}/${this.lang}${API_URL}/svg`);
      return await response.text();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error : Couldn't get svg sprite : ${error}`);
    }
  };
}

const InfotbmClient = new Infotbm();

export default InfotbmClient;
