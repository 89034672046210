const others = {
  title: 'Autres Services',
  title_home: 'Préparez votre voyage<span style="opacity: 0;">&nbsp;</span>!',
  home: 'Besoin d\'informations ? Retrouvez l\'essentiel pour préparer vos déplacements sur le réseau TBM !',
  home_report_violence_harassment: 'Signalement Violence Harcèlement',
  home_accessibility_mobibus: 'Accessibilité et Mobibus',
  title_mobile: 'L\'offre TBM :',
  air_quality: 'La qualité de l\'air :',
  air_quality_infos: 'Plus d\'infos sur le',
  air_quality_website: 'site de l\'Atmo',
};

export default others;
