import { LINES_TYPES } from '../config/line';

/**
 * Sort stops buy line as follows:
 *  - Trams
 *  - BatCub
 *  - Bus
 *  - Special bus
 *  - Train
 *  All by A->Z, 1->99.
 */
export const sortStopsByLine = (stops) => {
  const trams = stops.filter(f => f.line.type === LINES_TYPES.TRAM);
  const batcub = stops.filter(f => f.line.type === LINES_TYPES.BATCUB);
  const bus = stops.filter(f => f.line.type === LINES_TYPES.BUS && !f.line.isSpecial);
  const busNight = stops.filter(f => f.line.type === LINES_TYPES.BUS_NIGHT);
  const scodi = stops.filter(f => f.line.type === LINES_TYPES.SCODI);
  const specialBus = stops.filter(f => (
    f.line.type === LINES_TYPES.BUS && f.line.isSpecial
  ));
  const train = stops.filter(f => f.line.type === LINES_TYPES.TRAIN_TER);
  const autocar = stops.filter(f => f.line.type === LINES_TYPES.AUTOCAR);

  return [
    ...trams.sort((a, b) => ((a.line.code > b.line.code) ? 1 : -1)),
    ...batcub.sort((a, b) => ((a.line.code > b.line.externalCode) ? 1 : -1)),
    ...bus.sort((a, b) => ((a.line.code > b.line.externalCode) ? 1 : -1)),
    ...busNight.sort((a, b) => ((a.line.code > b.line.externalCode) ? 1 : -1)),
    ...scodi.sort((a, b) => ((a.line.code > b.line.externalCode) ? 1 : -1)),
    ...specialBus.sort((a, b) => ((a.line.code > b.line.code) ? 1 : -1)),
    ...train.sort((a, b) => ((a.line.code > b.line.code) ? 1 : -1)),
    ...autocar.sort((a, b) => ((a.line.code > b.line.code) ? 1 : -1)),
  ];
};
