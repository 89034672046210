const route = {
  title: 'Journey',
  meta_description: 'Calculate your routes here to travel easily on the TBM network.',
  route: 'Route',
  types: {
    all: 'ALL',
    bus_and_tramway: 'Bus & Tram',
    ferry: 'BAT3',
    bike: 'LE VÉLO',
  },
  nearbyLines: {
    closerThanTenMinutes: 'Transport within 10 minutes:',
  },
  search: {
    title: 'Search',
    type_to_search: 'Start typing to search',
    departure: 'Departure',
    from: 'From : ',
    to: 'To : ',
    arrival: 'Arrival',
    example: '(Address, Stop, Town...)',
    loading: 'Loading...',
    no_result: 'No result found',
    error: 'An error has occurred',
    modify: 'Modify the search',
    others: 'List of routes:',
    datetime_represents: {
      departure: 'Departure',
      arrival: 'Arrival',
    },
    accessibility: 'Accessible Journey',
    submit: 'Let\'s go !',
  },
  result: {
    routes_found_plural: 'routes found',
    routes_found_singular: 'route found',
    no_routes_found: 'No routes found.',
    mode_not_selected: 'This mode of transport is not part of your search criteria.',
    add_mode: 'Search for routes with this mode of transport.',
    no_more_routes_for_today: 'No routes found on the dates and times requested.',
    walk: 'Walk',
    personal_bike: 'Bike',
    vcub: 'LE VÉLO',
    transports: 'By transport',
    soft_modes: 'Soft mobility',
    tad: 'Transport on demand',
  },
  empty: 'No solutions found',
  empty_vcub: 'This route is not possible by LE VÉLO',
  empty_batcub: 'This route is not possible by BAT3',
  empty_pr: 'No P+R solutions found',
  empty_isolated: 'No solutions found for this route',
  prefs: {
    title: 'settings',
    for_phmr: 'Route with disabled access',
    less_walk: 'Least amount of walking',
    less_connections: 'Least number of connections',
    leave_now: 'Leave now',
    leave_at: 'Leave at ...',
    arrive_at: 'Arrive at ...',
  },
  go_from: 'leave from here',
  go_to: 'go to here',
  leave_at: 'leave at',
  arrive_at: 'arrive at',
  unit_minutes_abbrev: 'min',
  walk_with: 'with',
  unit_walk_hours_abbrev: 'with %{hours}h %{minutes} min on foot',
  unit_walk_minutes_abbrev: 'with %{minutes} min on foot',
  unit_minute: 'minute',
  unit_minutes: 'minutes',
  vcub_duration: 'Pedal for',
  vcub_section_title_start: 'Take a LE VÉLO at station',
  vcub_section_title_end: 'Leave the LE VÉLO at station',
  parking_section_title: 'Leave the car at ',
  vcub_up: 'Information updated:',
  vcub_unavailable: 'information not available',
  around_meters: 'around %{distance}m',
  departure: 'Departure',
  arrival: 'Arrival',
  see_details: 'See route details',
  filter_by_type: 'Filter by type:',
  walking_speed: 'Walking speed:',
  walking_speed_types: {
    slow: 'Slow',
    medium: 'Medium',
    fast: 'Fast',
  },
  destination: 'Destination',
  stops_singular: 'stop',
  stops_plural: 'stops',
  departure_label: 'Departure',
  arrival_label: 'Arrival',
  summary_dep: 'Departure:',
  summary_arr: 'Arrival:',
  departure_at: 'Departure on:',
  arrival_at: 'Arrival on:',
  the: '',
  at: 'at',
  co2: 'Carbon footprint',
  arena: 'For each show at the Arena hall, shuttles run every 3 to 10 minutes between Porte de Bourgogne, '
  + 'Stalingrad and Arena. '
  + 'First departure 2 hours before the start of the show, last departure 1h after the end of the show',
  arena_link: '>> + infos',
  distance: 'Distance',
  mail_body: 'Your route from %{departure} to %{arrival} with infotbm.com',
  mail_subject: 'Your route with TBM',
  mail_title: 'Send this route by email',
  print_title: 'Print this route',
  next_times: 'Leave later',
  previous_times: 'Leave earlier',
  walking_directions: {
    neutral: 'Take:',
    left: 'Turn left:',
    right: 'Turn right:',
    on: 'for',
    meters: 'metres',
  },
  different_day: 'This route is no longer possible by Tram/Bus at the dates/times indicated.',
  a11y: {
    with_accessibility: 'Wheelchair accessible routes',
    accessible_solutions: 'These routes are the closest solutions to your search with wheelchair accessible stops.',
    modal: {
      title: 'Your route',
      content: 'The proposed route is one of the closest solutions to your search with wheelchair accessible stops.',
      button: 'See the route',
    },
  },
  help: {
    title: 'Help and advice',
    night: {
      title: 'To move at night',
      description: 'Enjoy your evening without the stress of returning home!',
    },
    rules: {
      title: 'The rules',
      description: 'Let\'s travel together, let\'s live better together',
    },
    parking: {
      title: 'Use relay parks (P + R)',
      description: 'Leave your car there and take public transports',
    },
    loyalty: {
      title: 'The loyalty program',
      description: 'The more you validate, the more gifts you earn',
    },
    prices: {
      title: 'The rates',
      description: 'Whatever your need, find the price that suits you',
    },
    handicapped: {
      title: 'Persons with disabilities or reduced mobility',
      description: 'All our solutions to make your trip easier',
    },
    m_ticket: {
      title: 'Buy my m-ticket',
      description: 'The m-ticket application allows you to buy your dematerialized ticket '
      + 'and validate it with your smartphone (Android and iOS) when travelling by bus, tram and bat3.',
    },
  },
  transport_modes: {
    title: 'Preferences',
    title_accessible: 'Accessible modes of transport',
    tram: 'Tram',
    flex_aero: 'Flex Aero',
    bus: 'Bus',
    vcub: 'LE VÉLO',
    batcub: 'BAT3',
    parking: 'Park-and-ride',
    train: 'Train (TER)',
    autocar: 'Regional line',
  },
  edit: 'Edit',
  favorites: 'My favorite routes',
  add_favorites: 'Add to my favorites',
  remove_favorites: 'Remove from my favorites',
  errors: {
    transport_modes: 'At least one mode of transport must be selected.',
    fetch: 'An error occurred while retrieving routes. Try to reload the page.',
  },
  back_to_list: 'Back to the list of routes',
  path_price: 'Price of a TBM trip (tram, bus, bat3)',
  path_price_v3: 'Price conditions and purchase of a LE VÉLO ticket',
  path_price_train: 'Price conditions and purchase of a train ticket',
  path_price_autocar: 'Price conditions and purchase of a regional coach ticket',
  path_cgu_autocar: 'Travel conditions',
  path_cgu_train: 'Travel conditions',
  path_price_link_here: 'here',
  where_to_buy: 'Where to buy my ticket?',
  info_tad: 'You must book your flex’ journey on the « TBM flex’ » application',
  buy_tad: 'Go to TBM flex’',
  offer_ter: 'This TER journey is included in the <strong>"TBM + Trains"</strong> season ticket',
  buy_offer_ter: 'Discover the train offer',
  close: 'Close',
  see_map: 'See on the map',
  see_roadmap: 'See on the roadmap',
  less_than_a_minute: 'Less than a minute',
  fav_item: 'this route',
  places: {
    indication: 'Click on a location to go directly there',
  },
  train_in_route: 'Your TBM subscription is valid on this route',
  co2_of: 'of',
  stay_in: 'Wait',
};

export default route;
