import PropTypes from 'prop-types';
import { PREDICTIONS_TIME } from './vcub';
import { LINES_TYPES } from './line';
import { LCMAP_DISPLAY, LCMAP_ROUTES } from './lcmap';

const shapes = {
  line: {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(LINES_TYPES)).isRequired,
    externalCode: PropTypes.string.isRequired,
    isSpecial: PropTypes.bool,
    displayFirst: PropTypes.bool,
    isChartered: PropTypes.bool,
    isHidden: PropTypes.bool,
    textColor: PropTypes.string.isRequired,
    picto: PropTypes.string,
    lineSchedules: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      begin: PropTypes.string,
      end: PropTypes.string,
      frequency: PropTypes.string,
      navigationAmplitude: PropTypes.string,
      services: PropTypes.string,
      pdf: PropTypes.string,
    })),
    lineMaps: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      thermometer: PropTypes.string,
      thermometerImage: PropTypes.string,
      kml: PropTypes.string,
    })),
    bgColor: PropTypes.string,
  },
  stopPoint: {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
    externalCode: PropTypes.string,
    hasWheelchairBoarding: PropTypes.bool,
  },
  station: {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    address: PropTypes.string,
    insee: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    connexionState: PropTypes.string,
    nbPlaceAvailable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nbBikeAvailable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nbElectricBikeAvailable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    typeVlsPlus: PropTypes.string,
    updatedAt: PropTypes.string,
    city: PropTypes.string,
  },
  realtime: {
    vehicle_lattitude: PropTypes.number,
    vehicle_longitude: PropTypes.number,
    waittime_text: PropTypes.string,
    trip_id: PropTypes.string,
    schedule_id: PropTypes.string,
    destination_id: PropTypes.string,
    destination_name: PropTypes.string,
    departure: PropTypes.string,
    departure_commande: PropTypes.string,
    departure_theorique: PropTypes.string,
    arrival: PropTypes.string,
    arrival_commande: PropTypes.string,
    arrival_theorique: PropTypes.string,
    comment: PropTypes.string,
    realtime: PropTypes.string,
    waittime: PropTypes.string,
    updated_at: PropTypes.string,
    vehicle_id: PropTypes.string,
    vehicle_position_updated_at: PropTypes.string,
    origin: PropTypes.string,
  },
  prediction: {
    sid: PropTypes.number,
    status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    bikes: PropTypes.number,
    trend_at_tau: PropTypes.number,
    trend: PropTypes.number,
    prediction_confidence: PropTypes.string,
    tau: PropTypes.number,
    prediction_time: PropTypes.string,
    free_slots: PropTypes.number,
  },
  predictionTime: [
    0,
    '0',
    ...PREDICTIONS_TIME,
    ...PREDICTIONS_TIME.map(String),
  ],
  park: {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    parkingId: PropTypes.number,
    name: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    phone: PropTypes.string,
    freeSpots: PropTypes.number,
    arceaux2R: PropTypes.string,
    codeCategorieCD: PropTypes.string,
    selectionCD: PropTypes.string,
    searchName: PropTypes.string,
    nameCD: PropTypes.string,
    placesVelosecur: PropTypes.string,
    objectID: PropTypes.string,
    typePark: PropTypes.string,
    openDate: PropTypes.string,
    line: PropTypes.string,
    level: PropTypes.string,
    vlSpace: PropTypes.number,
    spaceNeutralized: PropTypes.number,
    maxSize: PropTypes.string,
    pmrSpace: PropTypes.number,
    vlecSpace: PropTypes.number,
    updatedAt: PropTypes.string,
    parkState: PropTypes.string,
  },
  parkPrediction: {
    tau_in_min: PropTypes.number,
    total_spots: PropTypes.number,
    free_spots: PropTypes.number,
    tms_gmt: PropTypes.string,
  },
  drupalLink: {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    nid: PropTypes.string.isRequired,
  },
  drupalTerm: {
    id: PropTypes.string,
    name: PropTypes.string,
    link: PropTypes.string,
  },
  news: {
    nid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    image_thumb: PropTypes.string.isRequired,
    chapo: PropTypes.string.isRequired,
    date: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
    langcode: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
  },
  place: {
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    stoparea: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    pr: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    station: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    langcode: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    nid: PropTypes.string.isRequired,
  },
  map: {
    kml: {
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      url: PropTypes.string,
    },
    marker: {
      icon: {
        path: PropTypes.string,
        fillColor: PropTypes.string,
        strokeWeight: PropTypes.number,
        fillOpacity: PropTypes.number,
        scale: PropTypes.number,
      },
      label: {
        text: PropTypes.string,
        color: PropTypes.string,
        fontWeight: PropTypes.string,
        fontSize: PropTypes.string,
      },
      position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      key: PropTypes.string,
      type: PropTypes.string,
    },
  },
  destination: {
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    coord: PropTypes.shape({
      lat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      lon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
    name: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },
  route: {
    status: PropTypes.string,
    distances: PropTypes.shape({
      taxi: PropTypes.number,
      car: PropTypes.number,
      walking: PropTypes.number,
      bike: PropTypes.number,
      ridesharing: PropTypes.number,
    }),
    links: PropTypes.arrayOf(PropTypes.shape({
      href: PropTypes.string,
      type: PropTypes.string,
      rel: PropTypes.string,
      templated: PropTypes.bool,
    })),
    tags: PropTypes.arrayOf(PropTypes.string),
    nb_transfers: PropTypes.number,
    durations: PropTypes.shape({
      taxi: PropTypes.number,
      walking: PropTypes.number,
      car: PropTypes.number,
      ridesharing: PropTypes.number,
      bike: PropTypes.number,
      total: PropTypes.number,
    }),
    arrival_date_time: PropTypes.string,
    departure_date_time: PropTypes.string,
    requested_date_time: PropTypes.string,
    co2_emission: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string,
    }),
    type: PropTypes.string,
    duration: PropTypes.number,
    sections: PropTypes.arrayOf(PropTypes.shape(() => this.routeSection)),
    modes: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
  },
  routeSection: {
    from: PropTypes.shape({
      embedded_type: PropTypes.string,
      stop_point: PropTypes.shape({
        name: PropTypes.string,
        coord: PropTypes.shape({
          lat: PropTypes.string,
          lon: PropTypes.string,
        }),
        label: PropTypes.string,
        fare_zone: PropTypes.shape({
          name: PropTypes.string,
        }),
        id: PropTypes.string,
        equipments: PropTypes.arrayOf(PropTypes.string),
      }),
      quality: PropTypes.number,
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    arrival_date_time: PropTypes.string,
    departure_date_time: PropTypes.string,
    to: PropTypes.shape({
      embedded_type: PropTypes.string,
      stop_point: PropTypes.shape({
        name: PropTypes.string,
        coord: PropTypes.shape({
          lat: PropTypes.string,
          lon: PropTypes.string,
        }),
        label: PropTypes.string,
        fare_zone: PropTypes.shape({
          name: PropTypes.string,
        }),
        id: PropTypes.string,
      }),
      quality: PropTypes.number,
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    geojson: PropTypes.shape({
      type: PropTypes.string,
      properties: PropTypes.arrayOf(PropTypes.shape({
        length: PropTypes.number,
      })),
      coordinates: PropTypes.arrayOf(PropTypes.arrayOf(
        PropTypes.number,
      )),
    }),
    duration: PropTypes.number,
    transfer_type: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  },
  routeQuery: {
    from_type: PropTypes.string,
    from: PropTypes.string,
    to_type: PropTypes.string,
    to: PropTypes.string,
    departure: PropTypes.string,
    date: PropTypes.string,
    transports: PropTypes.string,
    walk: PropTypes.string,
    connections: PropTypes.string,
    wheelchair: PropTypes.string,
  },
  city: {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    insee: PropTypes.string.isRequired,
  },
  alertFromLine: {
    alert: PropTypes.shape({
      id: PropTypes.number,
      alertUpdates: PropTypes.array,
      title: PropTypes.string,
      type: PropTypes.number,
      cause: PropTypes.number,
      workingNetwork: PropTypes.bool,
      description: PropTypes.string,
      typeName: PropTypes.string,
      causeName: PropTypes.string,
    }),
    transportMode: PropTypes.number,
  },
  history: {
    pathname: PropTypes.string,
    query: PropTypes.object,
    asPath: PropTypes.string,
  },
  lcmapData: {
    route: PropTypes.string,
    place: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    thematics: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.oneOf(Object.values(LCMAP_ROUTES)).isRequired,
      markers: PropTypes.array.isRequired,
      display: PropTypes.oneOf(Object.values(LCMAP_DISPLAY)),
      predict: PropTypes.bool,
    })),
  },
  lcmapConfig: {
    scrollWheelZoom: PropTypes.bool,
    dragging: PropTypes.bool,
    scaleControl: PropTypes.bool,
    zoomControl: PropTypes.bool,
    heavyLines: PropTypes.bool,
    size: PropTypes.string,
  },
};

export default shapes;
