const error = {
  cities: 'Se produjo un error al recuperar ciudades',
  city: 'Se produjo un error al recuperar la ciudad y su información',
  near: 'Se produjo un error al recuperar las líneas, las estaciones y el estacionamiento de relevos a su alrededor',
  enterprise: 'Se produjo un error al recuperar una empresa.',
  pois: 'Se produjo un error al recuperar puntos de interés',
  lines: 'Se produjo un error al recuperar una línea y sus paradas',
  pageNotFound: 'This page is not online anymore',
  pageNotFoundSubtext: 'It went back to the depot',
  goBackToHome: 'Retourner à l\'accueil',
  errorOnThisPage: 'An error occured on this page',
};

export default error;
