const schedules = {
  title: 'Horarios',
  section_title: 'Horarios',
  title_search: 'Horarios y próximos pasajes',
  meta_description: 'Encuentre aquí todos los horarios y próximos pasajes de las líneas de la red TBM.',
  description: 'Acceda en unos pocos clics a los horarios y los próximos pasajes de sus líneas.',
  search: {
    title: 'Buscar',
    placeholder: 'Busca una parada, una línea',
    stop_point_placeholder: 'Busca una parada',
    list: 'Ver todas las líneas',
    error: 'Error ocurrido',
    no_result: 'No hay resultados',
    select_direction: 'Seleccione una direccion',
    select_line_stop: 'Seleccione una línea y una dirección',
    select_direction_stop: 'Seleccione una dirección y luego su parada para ver '
    + 'la hora de pasaje de la línea a su parada.',
  },
  schedules_sheets: {
    title: 'Hojas de tiempo para descargar',
    description: 'Seleccione la línea de su elección para acceder a su hoja de tiempo e información práctica\n',
  },
  places: {
    title: 'Los lugares màs solicitados',
    othertitle: 'Lugares màs solicitados',
    indication: 'Haga clic en una línea para conocer sus próximos pasajes',
  },
  help: {
    title: 'Ayuda y consejo',
    plans: 'Mapas',
    partial_stop: 'Paradas parcial',
    plans_description: 'Descargue los planos de las líneas',
    schedules: 'Cómo encontrar horarios',
    schedules_description: 'Descarga la hoja de horas para su línea',
  },
  direction: 'Dirección',
  destination: 'Destino',
  next_passages: 'Próximos pasajes',
  near: 'Cerca',
  at: 'a las',
  see_another_day: 'Ver otro dia',
  schedule_indication: 'Haga clic en un horario para conocer la hora de llegada a su parada de descenso',
  find_bus: 'Donde esta mi bus ?',
  find_batcub: 'Donde esta mi BAT3 ?',
  find_tram: 'Donde esta mi tranvia ?',
  find_train: 'Donde esta mi tren ?',
  favorites: 'Mis paradas favoritas',
  print: 'Imprimir estas horarios',
};

export default schedules;
