const parking = {
  title: 'Park-and-rides',
  meta_description: 'Find here all the information related to the TBM network relay parks '
  + '(real-time availability, maps, practical information ...).',
  the_parks: 'The park-and-rides',
  description: {
    location: 'Located near the tram and bus lines,',
    number: 'the 28 parkings or "relay parks" of the TBM network',
    explanation: 'allow you to easily combine car and public transport.',
  },
  search: {
    title: 'Search',
    type_to_search: 'Start typing to search',
    placeholder: 'Find a park-and-ride',
    example: '(name or town)',
    loading: 'Loading...',
    no_result: 'No result found',
    find_nearby: 'Find a parking nearby',
  },
  select_line: 'Display all the park-and-rides',
  hide_line: 'Hide all the park-and-rides',
  capacity: 'Capacity',
  address: 'Address',
  vlSpace_singular: 'Space',
  vlSpace_plural: 'Spaces',
  pmrSpace_singular: 'Accessible',
  pmrSpace_plural: 'Accessible',
  freeVlec_singular: 'Electric',
  freeVlec_plural: 'Electric',
  last_updated_at: 'Last updated on',
  updated_today_at: 'Last updated today at',
  at: 'at',
  now: 'Now',
  notAvailable: 'This park and ride is currently unavailable.',
  no_realtime: 'No real-time information for this park and ride.',
  plan_link: 'See the park-relay on the map',
  parkSelectedPlanLink: 'See the park-relay on the map',
  show_all: 'View all relay parks',
  tram_connection: 'The relay parks in connection with the tram',
  parks_served_by_tram: 'The relay parks served by the tram',
  route: 'Find an itinerary',
  click_indication: 'Click on a relay park to access its availability',
  go_to: 'Go',
  go_from: 'From',
  availability: 'Availability',
  real_time: 'Now',
  predict: 'To come up',
  predict_soon: 'Predictive information available soon',
  how_to: 'Park & Ride : How does it work?',
  plan_title: 'Relay parks plan',
  informations: {
    title: 'Practical information',
    schedules: {
      title: 'Schedules',
      description: 'The TBM relay parks are open on the same time scale as the network :',
      normal: 'Mondays, Tuesdays, Wednesdays, Sundays and holidays: from 5am to 1am',
      party: 'Thursdays, Fridays and Saturdays: from 5am to 2am',
    },
    how: {
      title: 'How it works ?',
      come_in: 'Enter a P + R',
      go_out: 'Leave the car park',
    },
    prices: {
      title: 'Rates of the park-relays',
      occasional: 'Tickets : P+R tickets, 1 day, evening pass',
      adhere: 'TBM subscription : P+R access included',
    },
    electric: {
      title_search: 'Park-and-rides equipped with charging stations for electric vehicles',
      title_detail: 'Charging stations for electric vehicles',
      available: 'space available',
      available_plural: 'spaces available',
    },
  },
  favorites: 'My favorite relay parks',
  fav_item: 'this car park',
  closed: 'Closed park and ride.',
};

export default parking;
