const lines = {
  title: 'Líneas',
  title_list: 'Todas las lineas',
  meta_description: 'Descubra aquí todas las líneas de la red TBM. '
  + 'Haga clic en una línea para acceder a su información (horario, mapas, etc.).',
  lines: 'Líneas',
  description: 'Haga clic en la línea de su elección para acceder a toda su información práctica: '
  + 'hoja de tiempo, mapa, amplitud de circulación, perturbaciones, etc.',
  plans: 'Ver las líneas en el mapa.',
  tips: {
    label: 'Bueno saber',
    text: 'Puede imprimir su tarjeta de tiempo. Las hojas de horarios también están disponibles en nuestras ',
    text_link: '5 oficinas de ventas',
  },
  informations: {
    title: 'Informacion practica',
  },
  dynamic_indication: 'Haga clic en una línea para que aparezca',
  how_to: 'Todo sobre las líneas de autobús TBM',
};

export default lines;
