const pointOfInterest = {
  title: 'Lugares públicos',
  show_all: 'Mostrar todos los lugares públicos',
  hide_all: 'Ocultar todos los lugares públicos',
  back_to_list: 'Volver a la lista',
  back_to_theme: 'Volver al tema',
  back_to_category: 'Volver a la categoría',
  empty_category: 'No se han encontrado categorías para este tema de lugar público',
  empty_poi: 'No se han encontrado lugares públicos para esta categoría',
  transport_title: 'La oferta a menos de 10 minutos:',
  search: {
    placeholder: 'Buscar un lugar público',
    no_result: 'No se han encontrado lugares públicos',
  },
  summary: {
    name: 'Nombre',
    address: 'Dirección',
    category: 'Categoría',
  },
  fav_item: 'el lugar',
};

export default pointOfInterest;
