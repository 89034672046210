import {
  CHANGE_VCUB_DISPLAY, SET_EVENT, SET_GEOLOCATED_POSITION, SET_IS_GEOLOC, SET_IS_LOADED,
} from '../actions/LCMapAction';
import { LCMAP_DISPLAY } from '../config/lcmap';

const initialState = {
  isLoaded: false,
  event: null,
  position: null,
  isGeoloc: false,
  vcubDisplay: LCMAP_DISPLAY.BIKES,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADED:
      return {
        ...state,
        isLoaded: action.payload,
      };
    case SET_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case SET_GEOLOCATED_POSITION:
      return {
        ...state,
        position: action.payload,
      };
    case SET_IS_GEOLOC:
      return {
        ...state,
        isGeoloc: action.payload,
      };
    case CHANGE_VCUB_DISPLAY:
      return {
        ...state,
        vcubDisplay: action.payload,
      };
    default:
      return state;
  }
};
