export const PREDICTIONS_TIME = [
  15, 30, 60, 120, 180, 360,
];

export const VCUB_STATE = {
  CONNECTEE: 'CONNECTEE',
  DECONNECTEE: 'DECONNECTEE',
  MAINTENANCE: 'MAINTENANCE',
};

export const VCUB_MAP_DISPLAY = {
  BIKES: 'VCUB_MAP_DISPLAY_BIKES',
  SPOTS: 'VCUB_MAP_DISPLAY_SPOTS',
};

export const TYPE_VCUB = 'PAS_VLS_PLUS';
export const TYPE_VCUB_PLUS = 'VLS_PLUS';

const vcubImageDir = '/themes/custom/infotbm/images/markers/vcub/';
export const VCUB_MARKERS = {
  VCUB: {
    GREEN: `${vcubImageDir}vcub_green.svg`,
    ORANGE: `${vcubImageDir}vcub_orange.svg`,
    RED: `${vcubImageDir}vcub_red.svg`,
    BLUE: `${vcubImageDir}vcub_blue.svg`,
  },
  VCUB_PLUS: {
    GREEN: `${vcubImageDir}vcub+_green.svg`,
    ORANGE: `${vcubImageDir}vcub+_orange.svg`,
    RED: `${vcubImageDir}vcub+_red.svg`,
    BLUE: `${vcubImageDir}vcub+_blue.svg`,
  },
  PLACES: {
    GREEN: `${vcubImageDir}places_green.svg`,
    ORANGE: `${vcubImageDir}places_orange.svg`,
    RED: `${vcubImageDir}places_red.svg`,
    BLUE: `${vcubImageDir}places_blue.svg`,
  },
  UNAVAILABLE: `${vcubImageDir}unavailable.svg`,
};

export const VCUB_MARKER_COLORS = {
  WHITE: '#d0d0d0',
  RED: '#d70911',
  ORANGE: '#ec6608',
  GREEN: '#86C418',
  BLUE: '#009fe3',
};

export const VCUB_INFOBOX_COLOR = {
  RED: 'bg-red',
  ORANGE: 'bg-orange',
  GREEN: 'bg-green',
  GREY: 'bg-grey',
  BLUE: 'bg-blue',
};

export const VCUB_TAGGING = {
  GEOLOCATED: 'Géolocalisé',
  MANUAL_TYPING: 'Saisie manuelle',
  FAVORITE: 'Favori',
  LIST: 'Liste',
  MOST_REQUESTED: 'Les plus demandées',
};
