import { VCUBS_PREDICT_URL, VCUBS_URL } from '../config/config';
import { PREDICTIONS_TIME } from '../config/vcub';
import { fetchWithTimeout } from '../helpers/RequestHelper';

export const FETCH_ALL_STATIONS = 'FETCH_ALL_STATIONS';
export const FETCH_STATION_PREDICTIONS = 'FETCH_STATION_PREDICTIONS';
export const FETCH_PREDICTIONS = 'FETCH_PREDICTIONS';
export const CHANGE_PREDICT_TIME = 'CHANGE_PREDICT_TIME';
export const SET_STATION = 'SET_STATION';

export const fetchAllStations = (force = false) => async (dispatch, getState) => {
  try {
    if (!force && getState().vcubs.stations.length > 0) {
      return false;
    }

    const stationsQuery = await fetchWithTimeout(`${VCUBS_URL}`);
    const stations = await stationsQuery.json();

    dispatch({
      type: FETCH_ALL_STATIONS,
      payload: stations.lists,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des stations vcubs : ${e}`);
  }
};

export const fetchStationPredictions = id => async (dispatch) => {
  try {
    const predictionTime = PREDICTIONS_TIME.join('-');
    const predictionsQuery = await fetchWithTimeout(`${VCUBS_PREDICT_URL}/${predictionTime}/${id}`);
    const predictions = await predictionsQuery.json();

    dispatch({
      type: FETCH_STATION_PREDICTIONS,
      payload: predictions.predictions.data,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des prédictions de la station ${id} : ${e}`);
  }
};

export const fetchPredictions = time => async (dispatch, getState) => {
  try {
    const { stations } = getState().vcubs;
    if (stations.length > 0 && 'predictions' in stations[0] && time in stations[0].predictions) {
      return dispatch({
        type: CHANGE_PREDICT_TIME,
        payload: time,
      });
    }

    const predictionsQuery = await fetchWithTimeout(`${VCUBS_PREDICT_URL}/${time}`);
    const predictions = await predictionsQuery.json();

    dispatch({
      type: FETCH_PREDICTIONS,
      payload: {
        time,
        predictions: predictions.predictions.data,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Erreur lors de la récupération des prédictions vcubs : ${e}`);
  }
};

export const setStation = id => (dispatch, getState) => {
  const { stations } = getState().vcubs;

  const station = stations.find(s => s.id.toString() === id.toString());

  dispatch({ type: SET_STATION, payload: station });
};

export const resetStation = () => dispatch => (
  dispatch({ type: SET_STATION, payload: null })
);
