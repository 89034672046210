import { REQUEST_BG, FETCHED_BG } from '../actions/HomepageActions';

const initialState = {
  loading: false,
  bg: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_BG:
      return {
        ...state,
        loading: true,
      };

    case FETCHED_BG:
      return {
        ...state,
        loading: false,
        bg: action.payload,
      };

    default:
      return state;
  }
}
