export const fetchWithTimeout = (url, time = 10000, body = {}) => {
  let isServer = true;
  try {
    // eslint-disable-next-line no-unused-vars
    const clientWindow = window;
    // window found ! This is a client request
    isServer = false;
  } catch (e) {
    // No client detected, this is a server request
    // No need to do anything
  }

  if (isServer || typeof clientWindow === 'undefined') {
    // Replace "https://" from url to "http://" for server side request since calls are made with LAN network
    url = url.replace('https://', 'http://');
  }

  return (
    new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Fetch timeout'));
      }, time);

      fetch(url, body).then( // credentials are usefull for environments protected with htaccess
        (res) => {
          clearTimeout(timeout);
          resolve(res);
        }, (err) => {
          clearTimeout(timeout);
          reject(err);
        },
      );
    })
  );
};
