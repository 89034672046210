const parking = {
  HOME: {
    name: 'parking-page',
    title: 'parking.title',
    patterns: {
      fr: '/parc-relais',
      en: '/park-and-ride',
      es: '/park-and-ride',
    },
    page: 'parking/search',
  },
  DETAILS: {
    name: 'parking-details',
    title: null,
    patterns: {
      fr: '/parc-relais/:park',
      en: '/park-and-ride/:park',
      es: '/park-and-ride/:park',
    },
    page: 'parking/details',
  },
};

module.exports = parking;
