const lines = {
  title: 'Lines',
  title_list: 'All lines',
  meta_description: 'Discover here all the lines of the TBM network. '
  + 'Click on a line to access its information (timetable, maps, etc.).',
  lines: 'Lines',
  description: 'Click on the line of your choice to access all its practical information: '
  + 'time sheet, map, amplitude of circulation, disturbances, etc ...',
  plans: 'See the lines on the map',
  tips: {
    label: 'Good to know',
    text: 'You can print your time card. Time sheets are also available in our ',
    text_link: '5 sales offices',
  },
  informations: {
    title: 'Practical information',
  },
  dynamic_indication: 'Click on a line to make it appear',
  how_to: 'All about TBM Bus lines',
};

export default lines;
