import app from './app';
import route from './route';
import vcub from './vcub';
import line from './line';
import parking from './parking';
import cities from './cities';
import stopPoint from './stopPoint';
import userFeedback from './userFeedback';
import traffic from './traffic';
import nearbyMe from './nearbyMe';
import mode from './mode';
import pointOfInterest from './pointOfInterest';
import enterprise from './enterprise';
import map from './map';
import lines from './lines';
import favourites from './favourites';
import schedules from './schedules';
import plans from './plans';
import news from './news';
import batcub from './batcub';
import others from './others';
import global from './global';
import error from './error';
import footer from './footer';
import info from './info';
import homepage from './homepage';
import plan from './plan';

const fr = {
  app,
  batcub,
  cities,
  enterprise,
  error,
  favourites,
  global,
  info,
  line,
  lines,
  map,
  mode,
  nearbyMe,
  news,
  parking,
  plans,
  pointOfInterest,
  route,
  schedules,
  stopPoint,
  traffic,
  userFeedback,
  vcub,
  others,
  footer,
  homepage,
  plan,
};

export default fr;
